import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {SetPassword} from '../../../models/setPassword';
import {CommonDataService} from '../../../services/commonData.service';

@Component({
    selector: 'app-password-modal',
    templateUrl: './app-password-modal.component.html',
    styleUrls: ['./app-password-modal.component.scss'],
    standalone: false
})

export class AppPasswordModalComponent implements OnInit {
    @Input()
    public title = 'Change Password';
    @Input()
    public passwordRegex;
    @Input()
    public passwordRegexText;

    @Output()
    public cancel: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public setPassword: EventEmitter<SetPassword> = new EventEmitter<SetPassword>();

    public passwordForm: UntypedFormGroup;

    constructor(private _commonDataService: CommonDataService,
                private _fb: UntypedFormBuilder,
    ) { }

    @HostListener('document:keydown.escape', ['$event']) clicked(event) {
        event.preventDefault();
        this.cancel.emit();
    }

    public ngOnInit() {
        this.passwordForm = this._fb.group({
            password: new UntypedFormControl(new SetPassword()),
        });
    }

    public changePassword() {
        this.setPassword.emit(this.passwordForm.get('password').value);
    }
}
