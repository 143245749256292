import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import * as XLSX from 'xlsx';

import * as DateConstants from '../../../../constants/datetime.constants';
import * as SearchConstants from '../../../../constants/searchCriteria';
import { DateTimeHelper } from '../../../../helpers/datetime.helper';
import { InvoiceReportSummary } from '../../../../models/invoiceReportSummary';
import { InvoiceSummaryReportRequest } from '../../../../models/invoiceSummaryReportRequest';
import { InvoiceSummaryReportResponse } from '../../../../models/invoiceSummaryReportResponse';
import { ReportDropdownTypeSet } from '../../../../models/reportDropdownTypeSet';
import { SearchCriterion } from '../../../../models/searchCriterion';
import { SearchRequest } from '../../../../models/searchRequest';
import {BreadcrumbService} from "../../../../services/breadcrumb.service";
import { SignalsService } from 'app/services/signals.service';
import {toObservable} from "@angular/core/rxjs-interop";
import { SearchService } from 'app/services/search.service';
import {ReportingService} from "../../../../services/reporting.service";

@Component({
    selector: 'app-reports-invoice-list',
    styleUrls: ['./reports-invoice-list.component.scss'],
    templateUrl: './reports-invoice-list.component.html',
    standalone: false
})
export class ReportsInvoiceListComponent implements OnInit, OnDestroy {
    private reportingStoreSubscription: any;
    private appStateSubscription: any;

    private invoiceSummaryRequestApiDateFormat: string = DateConstants.DATE_FORMAT_yyyymmdd;
    public invoiceSummaryReports: Array<InvoiceReportSummary> = [];
    public invoiceSummaryReportResponse: InvoiceSummaryReportResponse;
    public invoiceSummaryTotals: {
        AmountPaidTotal: number;
        AverageWeightTotal: string;
        CartonsTotal: number;
        CostPerCWTTotal: string;
        CostPerCartonTotal: string;
        CostPerMileTotal: string;
        CostPerShipmentTotal: string;
        FuelCostTotal: number;
        ShipmentTotal: number;
        WeightTotal: number;
    };
    public invoiceSummaryReportRequest: InvoiceSummaryReportRequest;
    public initialInvoiceSummaryReportRequest: InvoiceSummaryReportRequest;
    public isDatePickerShown: boolean = false;
    public selectedDateFormGroup: UntypedFormGroup;
    public reportDisplayDateFormat: string = DateConstants.UNIFORM_DATE_DISPLAY.format;
    public reportDisplayDateValidation: RegExp;
    public invoiceReportGroupTypes: Array<ReportDropdownTypeSet> = [];
    public invoiceReportDateTypes: Array<ReportDropdownTypeSet> = [];
    public invoiceReportForm: UntypedFormGroup;
    private initialReportGroupValue: string = 'gl_code';
    private initialReportDateValue: string = 'invoice';
    public formattedStartDate: string = '';
    public formattedEndDate: string = '';
    public isInvoiceFormDirty: boolean = false;
    public isInvoiceFormValid: boolean = false;
    public firstColName: string = '';
    private shouldDefaultReportParamsBeUsed: boolean;
    private appState$;
    private reportState$;

    constructor(
        private _router: Router,
        private _fb: UntypedFormBuilder,
        private _signalsService: SignalsService,
        private _breadcrumbService: BreadcrumbService,
        private _reportingService: ReportingService,
        private _searchService: SearchService,
        private _breadcrumbsService: BreadcrumbService,
        private _dateHelper: DateTimeHelper,
    ) {
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.appState$ = toObservable(this._signalsService.appStateSignal);
        this.reportState$ = toObservable(this._signalsService.reportingSignal);
    }

    ngOnInit() {
        this.assignComponentProperties();

        const startDateForAPIRequest = this._dateHelper.prepareDate(this._dateHelper.getFormattedStartOfCurrentMonth(), this.invoiceSummaryRequestApiDateFormat);
        const currentDateForAPIRequest = this._dateHelper.getPreparedCurrentDate(this.invoiceSummaryRequestApiDateFormat);
        this.formattedStartDate = this._dateHelper.getFormattedCurrentDate();
        this.formattedEndDate = this._dateHelper.getFormattedStartOfCurrentMonth();

        this.appStateSubscription = this.appState$.subscribe((appState) => {
            this.reportDisplayDateValidation = this._dateHelper.getDisplayDateValidation();
            this.reportDisplayDateFormat = this._dateHelper.getDisplayDateFormat();
            if (appState['invoiceReport.navigateFromHeader']) {
                this.shouldDefaultReportParamsBeUsed = true;
            }

            this.assignComponentProperties();
        });

        this.reportingStoreSubscription = this.reportState$.subscribe((reportingState) => {
            this.invoiceSummaryReportResponse = reportingState && reportingState.invoiceSummaryReports ? reportingState.invoiceSummaryReports : null;
            this.invoiceSummaryReports = reportingState && reportingState.invoiceSummaryReports && reportingState.invoiceSummaryReports.Report ? reportingState.invoiceSummaryReports.Report : [];
            this.invoiceSummaryTotals = reportingState && reportingState.invoiceSummaryReports && reportingState.invoiceSummaryReports.Total && reportingState.invoiceSummaryReports.Total.length ? reportingState.invoiceSummaryReports.Total[0] : null;
            this.invoiceReportDateTypes = reportingState && reportingState.invoiceReportDateTypes && reportingState.invoiceReportDateTypes.length ? reportingState.invoiceReportDateTypes : [];
            this.invoiceReportGroupTypes = reportingState && reportingState.invoiceReportGroupTypes && reportingState.invoiceReportGroupTypes.length ? reportingState.invoiceReportGroupTypes : [];
            this.invoiceSummaryReportRequest = reportingState && reportingState.invoiceSummaryReportRequest ? reportingState.invoiceSummaryReportRequest : null;
            this.initialReportDateValue = this.invoiceReportDateTypes && this.invoiceReportDateTypes.length ? this.invoiceReportDateTypes[0].value : '';
            this.initialReportGroupValue = this.invoiceReportGroupTypes && this.invoiceReportGroupTypes.length ? this.invoiceReportGroupTypes[0].value : '';

            if (this.initialReportDateValue && this.initialReportGroupValue) {
                this.initialInvoiceSummaryReportRequest = {
                    startDate: startDateForAPIRequest,
                    endDate: currentDateForAPIRequest,
                    summaryType: this.initialReportGroupValue,
                    dateType: this.initialReportDateValue,
                    summaryOption: '',
                }
            }

            this.assignComponentProperties();
        });

        if (this.shouldDefaultReportParamsBeUsed) {
            this._reportingService.stashInvoiceSummaryReportParams(this.initialInvoiceSummaryReportRequest);
        }

        if (this.invoiceSummaryReportRequest) {
            this._reportingService.getInvoiceSummaryReports(this.invoiceSummaryReportRequest);
        }

    }

    ngOnDestroy() {
        this.reportingStoreSubscription.unsubscribe();
        this.appStateSubscription.unsubscribe();
    }
    // ================================================================================================
    // ======================================= SETUP METHODS =======================================
    // ================================================================================================
    private assignComponentProperties(): void {
        let endDate = this._dateHelper.getFormattedCurrentDate();
        let startDate = this._dateHelper.getFormattedStartOfCurrentMonth();

        if (this.invoiceSummaryReportRequest) {
            endDate = this._dateHelper.getFormattedDisplayDate(this.invoiceSummaryReportRequest.endDate, this.invoiceSummaryRequestApiDateFormat);
            startDate = this._dateHelper.getFormattedDisplayDate(this.invoiceSummaryReportRequest.startDate, this.invoiceSummaryRequestApiDateFormat);

            this.invoiceReportForm = this._fb.group({
                startDate: new UntypedFormControl(startDate, {
                    updateOn: 'blur',
                }),
                endDate: new UntypedFormControl(endDate, {
                    updateOn: 'blur',
                }),
                dateType: new UntypedFormControl(this.invoiceSummaryReportRequest.dateType, {
                    updateOn: 'blur'
                }),
                summaryType: new UntypedFormControl(this.invoiceSummaryReportRequest.summaryType, {
                    updateOn: 'blur'
                }),
                summaryOption: ''
            });
        } else {
            this.invoiceReportForm = this._fb.group({
                startDate: new UntypedFormControl(startDate, {
                    updateOn: 'blur',
                }),
                endDate: new UntypedFormControl(endDate, {
                    updateOn: 'blur',
                }),
                dateType: new UntypedFormControl(this.initialReportDateValue, {
                    updateOn: 'blur'
                }),
                summaryType: new UntypedFormControl(this.initialReportGroupValue, {
                    updateOn: 'blur'
                }),
                summaryOption: ''
            });
        }

        this.formattedStartDate = this.invoiceReportForm.get('startDate').value;
        this.formattedEndDate = this.invoiceReportForm.get('endDate').value;
        this.setupInvoiceFormChangesSubscription();
        this.setFirstColumnDisplayName();
        this.markDirty();
    }

    private setupInvoiceFormChangesSubscription(): void {
        if (!this.invoiceReportForm) {
            return;
        }

        this.invoiceReportForm.valueChanges.subscribe(val => {
            this.markDirty();
            this.isDateRangeValid();
        });
    }

    private markDirty(): void {
        if (!this.invoiceReportForm) {
            return;
        }

        this.isInvoiceFormDirty = true;
        this.isInvoiceFormValid = this.invoiceReportForm.valid ? true : false;
    }

    public setFirstColumnDisplayName(): void {
        if (!this.invoiceReportForm || !this.invoiceReportForm.get('summaryType').value || !this.invoiceReportGroupTypes) {
            return;
        }

        const summaryTypeFormValue = this.invoiceReportForm.get('summaryType').value;
        const selectedSummaryTypeOption: ReportDropdownTypeSet = this.invoiceReportGroupTypes.find((summaryTypeOption: ReportDropdownTypeSet) => summaryTypeOption.value === summaryTypeFormValue);

        this.firstColName = selectedSummaryTypeOption ? selectedSummaryTypeOption.displayName : '';
    }

    private prepareInvoiceSummaryReportRequest(): InvoiceSummaryReportRequest {
        if (!this.invoiceReportForm || !this.invoiceReportForm.get('startDate') || !this.invoiceReportForm.get('dateType') || !this.invoiceReportForm.get('endDate') || !this.invoiceReportForm.get('summaryType')) {
            return null;
        }

        const endDateFormValue = this.invoiceReportForm.get('endDate').value;
        const preparedEndDate = this._dateHelper.prepareDate(endDateFormValue, this.invoiceSummaryRequestApiDateFormat);
        const startDateFormValue = this.invoiceReportForm.get('startDate').value;
        const preparedStartDate = this._dateHelper.prepareDate(startDateFormValue, this.invoiceSummaryRequestApiDateFormat);

        const invoiceSummaryReportRequest: InvoiceSummaryReportRequest = {
            startDate: preparedStartDate,
            endDate: preparedEndDate,
            dateType: this.invoiceReportForm.get('dateType').value,
            summaryType: this.invoiceReportForm.get('summaryType').value,
            summaryOption: this.invoiceReportForm.get('summaryOption').value
        }

        return invoiceSummaryReportRequest;
    }

    private prepareSearchRequestForInvoiceDetail(selectedInvoiceReportCarrier: string): SearchRequest {
        const endDateFormValue = this.invoiceReportForm.get('endDate').value;
        const startDateFormValue = this.invoiceReportForm.get('startDate').value;
        const preparedStartDate = this._dateHelper.prepareISO_8601Date(startDateFormValue);
        const preparedEndDate = this._dateHelper.prepareISO_8601Date(endDateFormValue);

        const dateTypeFormValue = this.invoiceReportForm.get('dateType').value;
        const summaryTypeFormValue = this.invoiceReportForm.get('summaryType').value;
        const selectedDateTypeOption: ReportDropdownTypeSet = this.invoiceReportDateTypes.find((dateTypeOption: ReportDropdownTypeSet) => dateTypeOption.value === dateTypeFormValue);
        const selectedSummaryTypeOption: ReportDropdownTypeSet = this.invoiceReportGroupTypes.find((summaryTypeOption: ReportDropdownTypeSet) => summaryTypeOption.value === summaryTypeFormValue);

        const invoiceDetailSearchRequest: SearchRequest = {
            searchCriteria: [
                {
                    type: selectedSummaryTypeOption.filter,
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.INVOICES,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    value: selectedInvoiceReportCarrier,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
                },
                {
                    type: selectedDateTypeOption.filter,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.RANGE,
                    end: preparedEndDate,
                    start: preparedStartDate,
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.INVOICES,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    value: `${startDateFormValue} to ${endDateFormValue}`,
                }
            ]
        };

        return invoiceDetailSearchRequest;
    }

    // ================================================================================================
    // ======================================= UI CHANGE METHODS =======================================
    // ================================================================================================

    public isDateRangeValid(): boolean {
        let endDateFormValue;
        endDateFormValue = this.invoiceReportForm.get('endDate').value;
        const startDateFormValue = this.invoiceReportForm.get('startDate').value;
        const indefinitelyDisplay: string = DateConstants.DATE_VALUE_INDEFINITELY_display;
        const displayDateValidation: RegExp = this._dateHelper.getDisplayDateValidation();

        if (!this.invoiceReportForm || !startDateFormValue || !endDateFormValue) {
            return false;
        };

        const startDateMoment: moment.Moment = moment(startDateFormValue, this.reportDisplayDateFormat);
        const endDateMoment: moment.Moment = moment(endDateFormValue, this.reportDisplayDateFormat);

        if (endDateFormValue !== indefinitelyDisplay) {
            if (startDateMoment.isAfter(endDateMoment) || !startDateFormValue.match(displayDateValidation) || !endDateFormValue.match(displayDateValidation)) {
                return false;
            }
        } else if (!startDateFormValue.match(displayDateValidation)) {
            return false;
        }
        return true;
    }

    public showDatePicker(): void {
        this.isDatePickerShown = true;
    }

    public hideDatePicker({ selectedDate, selectedEndDate }: { selectedDate: string, selectedEndDate: string }): void {
        if (selectedDate) {
            this.invoiceReportForm.get('startDate').setValue(selectedDate);
        }

        if (selectedEndDate) {
            this.invoiceReportForm.get('endDate').setValue(selectedEndDate);
        }
        this.isDatePickerShown = false;
    }

    public updateReport(): void {
        if (!this.invoiceReportForm || !this.isInvoiceFormValid) {
            return;
        }

        const newInvoiceSummaryReportRequest = this.prepareInvoiceSummaryReportRequest();
        this._reportingService.stashInvoiceSummaryReportParams(newInvoiceSummaryReportRequest);
        this._reportingService.getInvoiceSummaryReports(newInvoiceSummaryReportRequest);
    }

    public navigateToInvoiceDetail(invoiceSummaryReport: InvoiceReportSummary): void {
        const searchReq: SearchRequest = this.prepareSearchRequestForInvoiceDetail(invoiceSummaryReport.firstColumn);
        const searchCriteria: Array<SearchCriterion> = searchReq && searchReq.searchCriteria && searchReq.searchCriteria.length ? searchReq.searchCriteria : null;
        if (!searchCriteria || !searchCriteria[0].value || !searchCriteria[0].entityType) {
            return;
        }

        const breadcrumbs = this._breadcrumbsService.breadcrumbsForReports(searchCriteria[0].value, searchCriteria[0].entityType);

        this._breadcrumbService.addBreadcrumb(breadcrumbs);
        this._searchService.processGlobalSearch(searchReq);
        this._signalsService.updateAppState({ 'invoiceReport.navigateFromHeader': false });

        this._router.navigate(['search']);
    }

    public exportReport() {
        let ws;
        let wb;
        let invoiceReport: InvoiceReportSummary;
        const report = [];
        const header = [
            this.firstColName, 'Paid $', 'Paid %', 'Weight', 'Weight %', 'Shipments', 'Shipments %', 'Cartons',
            'Carton Cost', 'Fuel Cost', 'CWT Cost', 'Shipment Cost', 'Mile Cost'
        ];
        const totals = [
            'Total Cost', this.invoiceSummaryTotals.AmountPaidTotal, '',
            this.invoiceSummaryTotals.WeightTotal, '', this.invoiceSummaryTotals.ShipmentTotal, '',
            this.invoiceSummaryTotals.CartonsTotal, this.invoiceSummaryTotals.CostPerCartonTotal,
            this.invoiceSummaryTotals.FuelCostTotal, this.invoiceSummaryTotals.CostPerCWTTotal,
            this.invoiceSummaryTotals.CostPerShipmentTotal, this.invoiceSummaryTotals.CostPerMileTotal
        ];
        for (invoiceReport of this.invoiceSummaryReports) {
            report.push([
                invoiceReport.firstColumn,
                invoiceReport.AmountPaid,
                invoiceReport.PaidPercent,
                invoiceReport.Weight,
                invoiceReport.WeightPercent,
                invoiceReport.Shipment,
                invoiceReport.ShipmentPercent,
                invoiceReport.Cartons,
                invoiceReport.CostPerCarton,
                invoiceReport.FuelCost,
                invoiceReport.CostPerCWT,
                invoiceReport.CostPerShipment,
                invoiceReport.CostPerMile
            ]);
        }
        report.unshift(totals);
        report.unshift(header);
        ws = XLSX.utils.aoa_to_sheet(report);
        wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Invoice Summary');
        XLSX.writeFile(wb, 'invoiceSummary.xlsx');
    }
}
