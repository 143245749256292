import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpErrorResponse } from '../../../../../node_modules/@angular/common/http';

import * as MasterDataConstants from '../../../constants/masterData.constants';
import { ContactMasterData } from '../../../models/contact.masterData';
import {NotificationService} from "../../../services/notification.service";
import {ShipmentService} from "../../../services/shipment.service";

@Component({
    selector: 'app-vanguard-contact-search-modal',
    templateUrl: './vanguard-contact-search-modal.component.html',
    styleUrls: ['./vanguard-contact-search-modal.component.scss'],
    standalone: false
})
export class VanguardContactSearchModalComponent implements OnInit {
  @Input() vanguardContactType: string;

  @Output() passVanguardContactToQuotingComp: EventEmitter<ContactMasterData> = new EventEmitter();

  public vanguardContactList: Array<ContactMasterData> = [];
  public filteredVanguardContactList: Array<ContactMasterData> = [];
  public selectedVanguardContact: ContactMasterData;

  public selectedContactField: string = MasterDataConstants.MASTER_DATA_CONTACT_FIELDS.NAME;
  public contactQuery: string;
  public dropdownContactFields: Array<{ displayText: string, contactField: string }> = [
    { displayText: 'Name', contactField: MasterDataConstants.MASTER_DATA_CONTACT_FIELDS.NAME },
    { displayText: 'City', contactField: MasterDataConstants.MASTER_DATA_CONTACT_FIELDS.CITY },
    { displayText: 'Address', contactField: MasterDataConstants.MASTER_DATA_CONTACT_FIELDS.ADDRESS1 },
    { displayText: 'Zipcode', contactField: MasterDataConstants.MASTER_DATA_CONTACT_FIELDS.ZIP },
    { displayText: 'State', contactField: MasterDataConstants.MASTER_DATA_CONTACT_FIELDS.STATE }
  ];

  public isNoResultsPromptDisplayed: boolean;

  constructor(
    private _notificationService: NotificationService,
    private _shipmentService: ShipmentService,
  ) { }

  public ngOnInit() {
    this.getVanguardContacts();
  }

  // ================================================================================================
  // ==================================== SETUP/CHANGE METHODS =======================================
  // ================================================================================================
  private getVanguardContacts(): void {
    if (!this.vanguardContactType) {
      return;
    }

    this._shipmentService.getVanguardContacts(this.vanguardContactType).subscribe(
      (response) => {
        if (response && response.isValid && response.dto) {
          this.vanguardContactList = response.dto;
          this.updateFilteredVanguardContactList();
        } else {
          this.vanguardContactList = [];
          this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'VanguardCFS Contacts' });
        }

        this.setNoResultsPromptDisplay();
      },
      (err: HttpErrorResponse) => {
        this.vanguardContactList = [];
        this.updateFilteredVanguardContactList();
        this.setNoResultsPromptDisplay();

        this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'VanguardCFS Contacts' });
      }
    );
  }

  public updateFilteredVanguardContactList(): void {
    this.filteredVanguardContactList = this.vanguardContactList.filter((vanguardContact: ContactMasterData) => {
      const pattern: RegExp = new RegExp(this.contactQuery, 'i');

      if (vanguardContact[this.selectedContactField].match(pattern)) {
        return vanguardContact;
      }
    });

    this.setNoResultsPromptDisplay();
  }

  private setNoResultsPromptDisplay(): void {
    this.isNoResultsPromptDisplayed = !(this.filteredVanguardContactList && this.filteredVanguardContactList.length);
  }

  public selectVanguardContact(selectedContact: ContactMasterData): void {
    if (!selectedContact) {
      return;
    }

    this.passVanguardContactToQuotingComp.emit(selectedContact);
  }

  // ================================================================================================
  // ======================================= UI METHODS =============================================
  // ================================================================================================
  public cancel(): void {
    this.passVanguardContactToQuotingComp.emit(null);
  }
}
