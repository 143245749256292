import {
    Component,
    Input, OnChanges,
    OnInit
} from '@angular/core';
import * as _ from 'lodash-es';
import * as Constants from '../../constants/constants';
import {ProductDetail} from '../../models/productDetail';
import {UnitOfMeasure} from '../../models/unitOfMeasure';
import {CommonDataService} from '../../services/commonData.service';

@Component({
    selector: 'app-product-summary',
    styleUrls: ['./app-product-summary.component.scss'],
    templateUrl: './app-product-summary.component.html',
    standalone: false
})
export class AppProductSummaryComponent implements OnChanges, OnInit {
    @Input()
    public unitOfMeasure: UnitOfMeasure;
    @Input()
    public lines: Array<ProductDetail> = [];

    public unitOfMeasureList: Array<UnitOfMeasure>;
    public unitsCube = 'ft3';
    public unitsWeight = 'lbs';
    public unitsDimension = 'in';
    public unitsDensity = 'pcf';

    constructor(private _commonDataService: CommonDataService) { }

    public async ngOnInit() {
        this.unitOfMeasureList = await this._commonDataService.loadedPromise(this._commonDataService.unitOfMeasures);
        this.checkUnitOfMeasure();
        this.setUnitLabels();
    }

    public ngOnChanges(): void {
        this.setUnitLabels();
    }

    public calculateDensity(weight, cube) {
        if (!isNaN(weight) && !isNaN(cube) && (cube > 0)) {
            return weight / cube;
        }
    }

    public productLineSum(attrName: string) {
        let sum = 0;
        let line;
        for (line of this.lines) {
            if (!isNaN(+line[attrName])) {
                sum += +line[attrName];
            }
        }
        return sum;
    }

    private findUnitOfMeasure(val) {
        return _.find(this.unitOfMeasureList, (unitOfMeasure) => unitOfMeasure.unitOfMeasureCode === val);
    }

    private checkUnitOfMeasure() {
        if (!this.unitOfMeasure) {
            this.unitOfMeasure = this.findUnitOfMeasure('U');
        }
    }

    private setUnitLabels() {
        if (this.unitOfMeasure) {
            if (this.unitOfMeasure.unitOfMeasureCode === Constants.UNIT_OF_MEASURE_Imperial) {
                this.unitsCube = 'ft' + String.fromCharCode(179);
                this.unitsWeight = 'lbs';
                this.unitsDimension = 'in';
                this.unitsDensity = 'pcf';
            } else {
                this.unitsCube = 'm' + String.fromCharCode(179);
                this.unitsWeight = 'kg';
                this.unitsDimension = 'cm';
                this.unitsDensity = 'kgm';
            }
        }
    }
}
