<div class="chargeRow whiteBack"><div class="chargeLine chargeItem">Line Haul Cost</div><div class="chargeItem">{{lineHaulCost  | currency:currency:'symbol'}}</div></div>
<div class="chargeRow whiteBack"><div class="chargeLine chargeItem">Fuel Surcharge Cost</div><div class="chargeItem">{{fuelCost  | currency:currency:'symbol'}}</div></div>
<div [ngClass]="{chargeContainer: 1, whiteBack: 1, chargeContainerWithDetails: accessorialDetails.length}">
    <div class="detailTotalRow">
        <div class="chargeLine chargeItem">
            Accessorials
        </div>
        <div class="chargeItem">
            {{accesorials  | currency:currency:'symbol'}}
        </div>
    </div>
    <div [ngClass]="{'whiteBack': (i % 2), 'grayBack': !(i % 2), 'detailRow': 1}" *ngFor="let detail of accessorialDetails; let i = index;">
        <div class="chargeLine">
            {{detail.name}}
        </div>
        <div>
            {{detail.value | currency:currency:'symbol'}}
        </div>
    </div>
</div>
<div *ngIf="insuranceCost > 0" class="chargeRow whiteBack"><div class="chargeLine chargeItem">Insurance purchased through Odyssey</div><div class="chargeItem">{{insuranceCost | currency:currency: 'symbol'}}</div></div>

<div class="chargeRow"><div class="chargeLine chargeItem">Total Cost</div><div class="chargeItem">{{total  | currency:currency:'symbol'}}</div></div>
