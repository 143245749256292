import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-products',
    template: `
    <div class="homePanel">
      <span class="header2 padLeft">Products coming soon!</span>
    </div>
`,
    // templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
    standalone: false
})
export class ProductsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
