<div class="overlay">
    <div class="placeShipmentModal" [ngStyle]="{'top': hasInsurance ? '15vh' : '5vh'}">

        <!--============ PLACE SHIPMENT MODAL HEADER ============-->
        <div class="modalHeader">
            <div class="modalHeaderText">
                Complete Your Shipment
            </div>

            <span class="modalHeaderIcon clickable" (click)="cancel()">
                <i class="fa fa-times"></i>
            </span>
        </div>

        <!--============ PLACE SHIPMENT MODAL BODY ============-->
        <form class="modalBody scrollbar" [formGroup]="placeShipmentForm" (ngSubmit)="saveChanges()">
            <div class="modalBodyContent">
                <div class="placeShipmentFormNote">
                <span class="placeShipmentFormNoteTop">
                    NOTE
                </span>
                <span class="placeShipmentFormNoteBottom">
                    Personal information will be used by CTS to inform you of updates regarding your shipment
                </span>
                </div>

                <!--============ PLACE SHIPMENT FORM MAIN ============-->
                <div class="placeShipmentFormMain">
                    <button type="submit" name="formSubmission" class="btn-hidden-IE"></button>
                    <div class="placeShipmentFormRow">
                        <div class="placeShipmentFormRowLeftColumn">
                            <div class="placeShipmentFormRowLabel">
                                <span class="required">*</span>Name
                            </div>
                            <input type="text" class="placeShipmentFormRowValue" formControlName="bolName"
                                   [ngClass]="{ 'placeShipmentFormInputInvalid': (placeShipmentForm['controls'].bolName.dirty || placeShipmentForm['controls'].bolName.touched) && placeShipmentForm['controls'].bolName.invalid }">
                        </div>

                        <div class="placeShipmentFormRowRightColumn">
                            <div class="placeShipmentFormRowLabel">
                                <span class="required">*</span>Phone
                            </div>
                            <input type="text" class="placeShipmentFormRowValue" formControlName="bolPhone" [ngClass]="{ 'placeShipmentFormInputInvalid': (placeShipmentForm['controls'].bolPhone.dirty || placeShipmentForm['controls'].bolPhone.touched)
                                && placeShipmentForm['controls'].bolPhone.invalid }">
                        </div>
                    </div>

                    <div class="placeShipmentFormRow">
                        <div class="placeShipmentFormRowLeftColumn">
                            <div class="placeShipmentFormRowLabel">
                                <span class="required">*</span>Email
                            </div>
                            <input type="text" class="placeShipmentFormRowValue" formControlName="bolEmail" [ngClass]="{ 'placeShipmentFormInputInvalid': (placeShipmentForm['controls'].bolEmail.dirty || placeShipmentForm['controls'].bolEmail.touched)
                                && placeShipmentForm['controls'].bolEmail.invalid }">
                        </div>

                        <div class="placeShipmentFormRowRightColumn"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="!hasInsurance" class="insuranceBodyContent" style="margin-top: 10px">
                <div class="insuranceLeft">
                    <br />
                    <span class="bolded" style="margin-bottom: 1em">Add Optional Shipment Insurance</span>
                    <div class="insuranceLine" style="margin-bottom: 1em">
                        <div class="insuranceLineLeft">
                            <span class="bolded">Shipment Cost</span>
                        </div>
                        <div class="insuranceLineRight">
                            {{shipment.Main.rated_amt | currency}}
                        </div>
                    </div>
                    <div class="insuranceLine">
                        <div class="insuranceLineLeft">
                            Shipment Value
                        </div>
                        <div class="insuranceLineRight">
                            <input type="text" class="placeShipmentFormRowValue" formControlName="shipmentValue" [ngClass]="{ 'placeShipmentFormInputInvalid': (placeShipmentForm['controls'].shipmentValue.dirty || placeShipmentForm['controls'].shipmentValue.touched)
                                && placeShipmentForm['controls'].shipmentValue.invalid }">
                        </div>
                    </div>
                    <div class="insuranceLine">
                        <div class="insuranceLineLeft">
                            Insurance Cost
                        </div>
                        <div class="insuranceLineRight">
                            {{placeShipmentForm['controls'].insuranceCharge.value | currency}}
                        </div>
                    </div>
                    <div class="insuranceLine" style="margin-top: 1em">
                        <div class="insuranceLineLeft">
                            <span class="bolded">Total Cost</span>
                        </div>
                        <div class="insuranceLineRight">
                            {{placeShipmentForm['controls'].insuranceCharge.value + shipment.Main.rated_amt | currency}}
                        </div>
                    </div>
                    <br />
                    @if (placeShipmentForm['controls'].insuranceCharge.value) {
                        <span>By clicking "PURCHASE INSURANCE AND SUBMIT" below you are agreeing to all the terms of agreement to the right.<br /><br /></span>
                    } @else {
                        <br/><br/><br/>
                    }
                </div>
                <div class="insuranceRight">
                    <div class="termsOfAgreementHolder">
                        <div class="termsOfAgreementTitle">
                            <span (click)="window.open('https://www.shipwithcts.com/cts/BOL/insurance.html', '_blank').focus();" class="termsOfAgreementTitleMain clickable">
                                Terms of Agreement
                            </span>
                            <span class="termsOfAgreementTitleNote">
                                Note: Insurance is only valid if purchased before ship date and is billed through CTS.
                            </span>
                        </div>

                        <div class="termsOfAgreementBody scrollbar" [innerHTML]="_commonDataService.insuranceTermsOfAgreement | safeHtml">
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <!--============ PLACE SHIPMENT MODAL FOOTER ============-->
        <div class="modalFooter">
            @if (!hasInsurance) {
                <button class="btn-outline clickable" (click)="(placeShipmentForm['controls'].insuranceCharge.value) ? saveChanges(false) : cancel()">
                    {{(placeShipmentForm['controls'].insuranceCharge.value) ? 'DECLINE INSURANCE AND SUBMIT' : 'CANCEL'}}
                </button>
                <button class="btn-outline clickable" (click)="(placeShipmentForm['controls'].insuranceCharge.value) ? saveChanges() : saveChanges(false)"
                        [ngClass]="{ 'btn-filled': placeShipmentForm.valid, 'placeShipmentFormSubmitValid': placeShipmentForm.valid }">
                    {{(placeShipmentForm['controls'].insuranceCharge.value) ? 'PURCHASE INSURANCE AND SUBMIT' : 'DECLINE INSURANCE AND SUBMIT'}}
                </button>
            } @else {
                <button class="btn-outline clickable" (click)="cancel()">
                    Cancel
                </button>
                <button class="btn-outline clickable" (click)="saveChanges()"
                        [ngClass]="{ 'btn-filled': placeShipmentForm.valid, 'placeShipmentFormSubmitValid': placeShipmentForm.valid }">
                    Submit
                </button>
            }
        </div>
    </div>
</div>
