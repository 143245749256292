import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-unsaved-markup-warning-modal',
    templateUrl: './unsaved-markup-warning-modal.component.html',
    styleUrls: ['./unsaved-markup-warning-modal.component.scss'],
    standalone: false
})

export class UnsavedMarkupWarningModalComponent implements OnInit {
    @Input() public okPayload: any;
    @Input() public iscloneMarkupScenarioActive: boolean;
    @Input() public suppressCancelButton: boolean;
    @Output() public onOk = new EventEmitter();
    @Output() public onCancel = new EventEmitter();
    @Output() public continueMarkupCreation = new EventEmitter();
    @Output() public continueCloneMarkup = new EventEmitter();

    constructor() { }

    ngOnInit() {
        // console.log(`OkCancelModalComponent.Init() okPayload => ${JSON.stringify(this.okPayload)}`);
    }

    public ok() {
        this.onOk.emit(this.okPayload);
    }

    public cancel() {
        this.onCancel.emit('');
    }

    public continueSavingMarkup(shouldSaveMarkup: boolean): void {
        this.continueMarkupCreation.emit(shouldSaveMarkup);
    }

    public continueWithCloningMarkup(shouldSaveMarkup: boolean): void {
        this.continueCloneMarkup.emit(shouldSaveMarkup)
    }
}
