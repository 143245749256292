import {Component, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {map, take} from 'rxjs/operators';
import { filterList } from '../../../helpers/utilities';

import * as AdminConstants from '../../../constants/admin.constants';
import { UserHelper } from '../../../helpers/userHelper';
import { RoleMasterData } from '../../../models/role.masterData';
import {CommonDataService} from '../../../services/commonData.service';
import {RoleService} from '../../../services/role.service';
import {BreadcrumbService} from "../../../services/breadcrumb.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-admin-role-list',
    styleUrls: ['./admin-role-list.component.scss'],
    templateUrl: './admin-role-list.component.html',
    standalone: false
})
export class AdminRoleListComponent implements OnInit {
    public sectionName = AdminConstants.ADMIN_SECTION_PATHS.roles;
    public masterDataRoles: Array<RoleMasterData>;
    public selectedFilterField: string;
    public filteredRoles: Array<RoleMasterData> = [];
    public dropdownFilterFields: Array<{ displayText: string, filterField: string }> = [
        { displayText: 'All', filterField: 'all' },
        { displayText: 'Role', filterField: 'roleName' },
        { displayText: 'Description', filterField: 'roleDescription' },
        { displayText: 'Type', filterField: 'roleType' },
        { displayText: 'Customer', filterField: 'roleCustNo' },
    ];

    public isConfirmDeleteModalVisible: boolean = false;
    public confirmDeleteRoleTitle: string = 'Confirm Role Deletion';
    public confirmDeleteRoleMessage: string = 'Are you certain you\'d like to delete this role?';
    public selectedRole: RoleMasterData;
    public isAdminInternal: boolean;
    public roleListForm: UntypedFormGroup;


    constructor(
        private _notificationService: NotificationService,
        private _router: Router,
        private _userHelper: UserHelper,
        private _fb: UntypedFormBuilder,
        private _breadcrumbService: BreadcrumbService,
        private _roleService: RoleService,
        private _commonDataService: CommonDataService,
    ) {}

    public ngOnInit() {
        this.filteredRoles = this.masterDataRoles = this._roleService.roles;

        this.isAdminInternal = this._userHelper.isAdminInternal();

        this._commonDataService.getContractIDs().subscribe();

        this.selectedFilterField = this.dropdownFilterFields[0].filterField;

        this.roleListForm = this._fb.group({
            filterTerm: ''
        });
        this.roleListForm.valueChanges.subscribe(val => {
            this.filterRoles();
        });

        this._breadcrumbService.setBreadcrumbs([
            {
                label: 'Admin',
                urlValue: 'admin/roles'
            },
            {
                label: 'Roles & Permissions',
                urlValue: 'admin/roles'
            }
        ]);
    }

    public showInProgressNotification(): void {
        this._notificationService.notifyInfo({ title: 'MasterData Roles', message: 'Functionality is still in progress' });
    }

    public navigateToRole(roleId): void {
        this._router.navigate(['admin/roles', roleId]);
    }

    public createRole(): void {
        this._router.navigate(['admin/roles/new']);
    }

    public deleteRole(role: RoleMasterData): void {
        this.hideConfirmDeleteRoleModal();
        this._roleService.deleteRole(role.RoleID).pipe(
            take(1),
            map(() => this._roleService.getRolesList().subscribe())
        ).subscribe();
    }

    public hideConfirmDeleteRoleModal(): void {
        this.isConfirmDeleteModalVisible = false;
    }

    public showConfirmDeleteModal(role): void {
        this.selectedRole = role;
        this.isConfirmDeleteModalVisible = true;
    }

    public filterRoles(): void {
        const filterTerm = this.roleListForm.get('filterTerm').value;

        this.filteredRoles = filterList(this.masterDataRoles, this.dropdownFilterFields, this.selectedFilterField, filterTerm);
    }
}
