<div class="overlay">
    <div class="insuranceQuotingModal">

        <!--=========================== MODAL HEADER ==============================-->
        <div class="modalHeader">
            <div class="modalHeaderText">Add Insurance</div>
            <div class="modalHeaderIcon">
                <i class="fa fa-times clickable" aria-hidden="true" (click)="closeModal()"></i>
            </div>
        </div>

        <!--======================== MODAL BODY ========================-->
        <div class="modalBody">
            <!--======================== TERMS OF AGREEMENT ========================-->
            <div class="termsOfAgreementHolder">
                <div class="termsOfAgreementTitle">
                <span class="termsOfAgreementTitleMain">
                    Terms of Agreement
                </span>
                    <span class="termsOfAgreementTitleNote">
                      Note: Insurance is only valid if purchased before ship date and is billed through CTS.
                    </span>
                </div>

                <div class="termsOfAgreementBody scrollbar" id="termsOfAgreementContainer">
                </div>
            </div>

            <!--======================== ACTUAL INSURANCE PANEL ========================-->
            <div class="shipmentInsurancePanel borderLeft">
                <div class="shipmentInsuranceInfo borderBottom">
                    <div class="shipmentInfoTitle">
                        Enter Shipment Information
                    </div>

                    <form class="shipmentInsuranceInfoSection" [formGroup]="declareShipmentValueForm"
                          (ngSubmit)="requestInsuranceQuotedValue()">
                        <div class="shipmentInsuranceLabel">
                            <span class="required">*</span>Shipment Value
                        </div>

                        <div class="shipmentInsuranceValue">
                            <div style="display: flex; flex: 1;" [ngClass]="getClassFor('declaredShipmentValue')">
                                <span class="shipmentValueInputSymbol">$</span>
                                <input type="text" class="shipmentValueInput" formControlName="declaredShipmentValue">
                            </div>
                            <span class="shipmentValueCurrency">USD</span>
                        </div>
                    </form>

                    <div *ngIf="shipmentCost" class="shipmentInsuranceInfoSection">
                        <div class="shipmentInsuranceLabel">
                            Shipment Cost
                        </div>
                        <div class="shipmentInsuranceValue">
                            {{ shipmentCost | currency }}
                        </div>
                    </div>

                    <div class="shipmentInsuranceInfoSection">
                        <div class="shipmentInsuranceLabel">
                            Insurance Charge
                        </div>
                        <div class="shipmentInsuranceValue">
                            {{ insuranceCharge | currency }}
                        </div>
                    </div>

                </div>
                <!--============ TOTAL SHIPMENT COST PORTION ============-->
                <div class="totalShipmentCostContainer borderBottom">
                    <div *ngIf="shipmentCost" class="totalShipmentCostDisplay">
                        <div class="shipmentInsuranceLabel">
                            Total Shipment Cost
                        </div>
                        <div class="shipmentInsuranceValue">
                        <span>
                            {{ totalShipmentCost | currency }}
                        </span>
                        </div>
                    </div>
                </div>

                <div class="purchaseInsurancePanel">
                    <div class="purchaseInsuranceDisplay">
                        By clicking "Purchase Insurance" below, you are agreeing to all the terms of agreement.
                    </div>
                    <div class="purchaseInsuranceButtonHolder">
                        <app-button [buttonColor]="'red'" (click)="closeModal()">Decline Insurance</app-button>
                        <app-button [inactive]="!insuranceCharge" (click)="purchaseInsurance()">Purchase Insurance</app-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
