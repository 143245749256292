import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {map, take} from 'rxjs/operators';
import { filterList } from '../../../helpers/utilities';

import * as AdminConstants from '../../../constants/admin.constants';
import { UserMasterData } from '../../../models/user.masterData';
import {UserService} from '../../../services/user.service';
import {BreadcrumbService} from "../../../services/breadcrumb.service";

@Component({
    selector: 'app-admin-user-list',
    styleUrls: ['./admin-user-list.component.scss'],
    templateUrl: './admin-user-list.component.html',
    standalone: false
})
export class AdminUserListComponent implements OnInit {
    public sectionName = AdminConstants.ADMIN_SECTION_PATHS.users;
    public masterDataUsers: Array<UserMasterData>;
    public filteredUsers: Array<UserMasterData> = [];
    public userListForm: UntypedFormGroup;
    public selectedFilterField: string;
    public dropdownFilterFields: Array<{ displayText: string, filterField: string }> = [
        { displayText: 'All', filterField: 'all' },
        { displayText: 'User', filterField: 'name' },
        { displayText: 'User Login', filterField: 'userlogin' },
        { displayText: 'User ID', filterField: 'userID' },
        { displayText: 'Customer', filterField: 'customer' },
    ];
    public isConfirmDeleteModalVisible: boolean = false;
    public confirmDeleteUserTitle: string = 'Confirm User Deactivation';
    public confirmDeleteUserMessage: string = 'Are you certain you\'d like to deactivate this user?';
    public selectedUser: UserMasterData;

    constructor(
        private _fb: UntypedFormBuilder,
        private _router: Router,
        private _breadcrumbService: BreadcrumbService,
        private _userService: UserService,
    ) { }

    public ngOnInit() {
        this.filteredUsers = this.masterDataUsers = this._userService.users;

        this.userListForm = this._fb.group({
            filterTerm: ''
        });

        this.userListForm.valueChanges.subscribe(val => {
            this.filterUsers();
        });

        this.selectedFilterField = this.dropdownFilterFields[0].filterField;

        this._breadcrumbService.addBreadcrumb([
            {
                label: 'Users',
                urlValue: 'admin/users'
            }
        ]);
    }

    public filterUsers(): void {
        const filterTerm = this.userListForm.get('filterTerm').value;

        this.filteredUsers = filterList(this.masterDataUsers, this.dropdownFilterFields, this.selectedFilterField, filterTerm);
    }

    public navigateToUser(userId: number): void {
        this._router.navigate(['admin/users', userId]);
    }

    public createNewUser(): void {
        this._router.navigate(['admin/users/new']);
    }

    public deactivateUser(user: UserMasterData): void {
        this.hideConfirmDeleteUserModal();
        this._userService.deleteUser(user.userID).pipe(
            take(1),
            map(() => this._userService.getUsersList().subscribe())
        ).subscribe();
    }

    public hideConfirmDeleteUserModal(): void {
        this.isConfirmDeleteModalVisible = false;
    }

    public showConfirmDeleteModal(user: UserMasterData): void {
        this.selectedUser = user;
        this.isConfirmDeleteModalVisible = true;
    }

    public navigateToInviteUserScreen(): void {
        this._router.navigate(['admin/inviteUser']);
    }
}
