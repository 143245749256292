import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { SearchState } from 'app/models/searchState';
import * as SearchConstants from '../../../constants/searchCriteria';
import {SignalsService} from "../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    standalone: false
})
export class SearchBarComponent implements OnInit, OnDestroy {
    @Output() processSearch = new EventEmitter<{
        searchCriteriaType: string,
        searchQuery: string,
    }>();

    public searchCriteriaTypes: Array<{ fieldName: string, displayName: string }>;
    public selectedSearchCriteriaType: string;
    public searchQuery: string;

    private appDisplayDataSubscription: any;
    public appDisplayData;

    private searchSubscription: any;
    public searchState: SearchState;
    public hasSearchBeenMade: boolean = false;
    public isNoResultsPromptDisplayed: boolean = false;

    public fromSearchConstants: any = SearchConstants;
    private search$;
    private displayState$;

    constructor(
        private _notificationService: NotificationService,
        private _signalsService: SignalsService,
    ) {
        this.search$ = toObservable(this._signalsService.searchStateSignal);
        this.displayState$ = toObservable(this._signalsService.displaySignal);
    }

    public ngOnInit() {
        this.selectedSearchCriteriaType = SearchConstants.SEARCH_CRITERIA_type.ALL;

        this.appDisplayDataSubscription = this.displayState$.subscribe(AppDisplayData => {
            this.appDisplayData = AppDisplayData;
            if (this.appDisplayData.searchCriteriaTypes) {
                this.searchCriteriaTypes = Object.keys(this.appDisplayData.searchCriteriaTypes).map((sCTField: string) => {
                    return { fieldName: sCTField, displayName: this.appDisplayData.searchCriteriaTypes[sCTField] };
                });
            }
        });

        this.searchSubscription = this.search$.subscribe((searchState: SearchState) => {
            this.searchState = searchState;

            if (searchState.searchRequest.searchCriteria.length) {
                this.searchQuery = searchState.searchRequest.searchCriteria[0].value;
            }
            if (searchState.searchResults.length) {
                this.hasSearchBeenMade = true;
            }
            if (!searchState.searchResults.length && this.hasSearchBeenMade) {
                this.isNoResultsPromptDisplayed = true;
            } else {
                this.isNoResultsPromptDisplayed = false;
            }
        });
    }

    public ngOnDestroy() {
        this.appDisplayDataSubscription.unsubscribe();
        this.searchSubscription.unsubscribe();
    }

    public search() {
        document.getElementById('searchQuery').blur();
        this.processSearch.emit({
            searchCriteriaType: this.selectedSearchCriteriaType,
            searchQuery: this.searchQuery,
        });
    }

    public displayInProgressNotification() {
        this._notificationService.notifyWarning({ title: `Advanced Search`, message: `This functionality is still in progress.` });
    }

}
