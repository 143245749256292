<div class="columns leftColumn">
    <div class="insuranceHeading">Insurance</div>
    <ng-container *ngIf="value?.shipmentValue">
        <div>Shipment Value:</div>
        <div>Insurance Charge:</div>
    </ng-container>
</div>
<div class="columns rightColumn">
    <ng-template #addButton>
        <div class="buttonContainer">
            <app-button (click)="addInsurance()" [buttonColor]="'yellow'">Add Insurance</app-button>
        </div>
    </ng-template>
    <div class="buttonContainer" *ngIf="value?.shipmentValue; else addButton">
        <app-button (click)="removeInsurance()" [buttonColor]="'red'">Remove Insurance</app-button>
    </div>
    <div>
        {{value?.shipmentValue | number: '1.2-2' }}
    </div>
    <div>
        {{value?.insuranceCharge | number: '1.2-2' }}
    </div>
</div>

<app-insurance-quoting-modal *ngIf="isInsuranceModalShown" [carrier]="carrier" [shipmentValue]="getShipmentValueForModal()" (updateInsurancePurchase)="closeInsuranceQuotingModal($event)"></app-insurance-quoting-modal>
