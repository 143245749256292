import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import * as XLSX from 'xlsx';

import * as DateConstants from '../../../../constants/datetime.constants';
import * as SearchConstants from '../../../../constants/searchCriteria';
import { DateTimeHelper } from '../../../../helpers/datetime.helper';
import { ReportDropdownTypeSet } from '../../../../models/reportDropdownTypeSet';
import { SearchCriterion } from '../../../../models/searchCriterion';
import { SearchRequest } from '../../../../models/searchRequest';
import { ShipmentReportSummary } from '../../../../models/shipmentReportSummary';
import { ShipmentReportTotalSummary } from '../../../../models/shipmentReportTotalSummary';
import { ShipmentSummaryReportRequest } from '../../../../models/shipmentSummaryReportRequest';
import { ShipmentSummaryReportResponse } from '../../../../models/shipmentSummaryReportResponse';
import {BreadcrumbService} from "../../../../services/breadcrumb.service";
import {SignalsService} from "../../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import { SearchService } from 'app/services/search.service';
import { ReportingService } from 'app/services/reporting.service';

@Component({
    selector: 'app-reports-shipment-list',
    styleUrls: ['./reports-shipment-list.component.scss'],
    templateUrl: './reports-shipment-list.component.html',
    standalone: false
})
export class ReportsShipmentListComponent implements OnInit, OnDestroy {
    private reportingShipmentListSubscription: any;
    private appStateSubscription: any;

    public shipmentSummaryApiDateFormat: string = DateConstants.DATE_FORMAT_yyyymmdd;
    public shipmentReportSummaryList: Array<ShipmentReportSummary>;
    public shipmentReportSummaryTotalList: ShipmentReportTotalSummary;
    public shipmentSummaryReportResponse: ShipmentSummaryReportResponse;

    public shipmentReportForm: UntypedFormGroup;
    private initialReportGroupValue: string;
    private initialReportDateValue: string;
    public isDatePickerShown: boolean = false;
    public selectedDateFormGroup: UntypedFormGroup;
    public reportDisplayDateFormat: string = DateConstants.UNIFORM_DATE_DISPLAY.format;
    public reportDisplayDateValidation: RegExp;
    public shipmentReportGroupTypes: Array<ReportDropdownTypeSet> = [];
    public shipmentReportDateTypes: Array<ReportDropdownTypeSet> = [];
    public formattedStartDate: string = '';
    public formattedEndDate: string = '';
    public initialShipmentSummaryReportRequest: ShipmentSummaryReportRequest;
    public shipmentReportRequest: ShipmentSummaryReportRequest;
    public isShipmentFormDirty: boolean = false;
    public isShipmentFormValid: boolean = false;
    public firstColName: string = '';
    private shouldDefaultReportParamsBeUsed: boolean;
    private appState$;
    private reportingState$;

    constructor(
        private _router: Router,
        private _fb: UntypedFormBuilder,
        private _signalsService: SignalsService,
        private _breadcrumbService: BreadcrumbService,
        private _reportingService: ReportingService,
        private _searchService: SearchService,
        private _dateHelper: DateTimeHelper,
    ) {
        this.appState$ = toObservable(this._signalsService.appStateSignal);
        this.reportingState$ = toObservable(this._signalsService.reportingSignal);
    }

    ngOnInit() {
        this.assignComponentProperties();

        this.formattedEndDate = this._dateHelper.getFormattedCurrentDate();
        this.formattedStartDate = this._dateHelper.getFormattedStartOfCurrentMonth();

        this.appStateSubscription = this.appState$.subscribe((appState) => {
            this.reportDisplayDateValidation = this._dateHelper.getDisplayDateValidation();
            this.reportDisplayDateFormat = this._dateHelper.getDisplayDateFormat();
            if (appState['shipmentReport.navigateFromHeader']) {
                this.shouldDefaultReportParamsBeUsed = true;
            }
            this.assignComponentProperties();
        });

        this.reportingShipmentListSubscription = this.reportingState$.subscribe((reportingState) => {
            this.shipmentReportSummaryList = reportingState && reportingState.shipmentSummaryReports ? reportingState.shipmentSummaryReports.Report : [];
            this.shipmentReportSummaryTotalList = reportingState && reportingState.shipmentSummaryReports && reportingState.shipmentSummaryReports.Total && reportingState.shipmentSummaryReports.Total.length ? reportingState.shipmentSummaryReports.Total[0] : null;
            this.shipmentReportDateTypes = reportingState && reportingState.shipmentReportDateTypes && reportingState.shipmentReportDateTypes.length ? reportingState.shipmentReportDateTypes : [];
            this.shipmentReportGroupTypes = reportingState && reportingState.shipmentReportGroupTypes && reportingState.shipmentReportGroupTypes.length ? reportingState.shipmentReportGroupTypes : [];
            this.shipmentReportRequest = reportingState && reportingState.shipmentSummaryReportRequest ? reportingState.shipmentSummaryReportRequest : null;
            this.initialReportDateValue = this.shipmentReportDateTypes && this.shipmentReportDateTypes.length ? this.shipmentReportDateTypes[0].value : '';
            this.initialReportGroupValue = this.shipmentReportGroupTypes && this.shipmentReportGroupTypes.length ? this.shipmentReportGroupTypes[0].value : '';

            if (this.initialReportDateValue && this.initialReportGroupValue) {
                this.initialShipmentSummaryReportRequest = {
                    startDate: this.formattedStartDate,
                    endDate: this.formattedEndDate,
                    dateType: this.initialReportDateValue,
                    summaryType: this.initialReportGroupValue,
                    summaryOption: '',    // should always be passing in an empty string until told otherwise - no corresponding UI element tied to value per Ta-Hajj
                }
            }

            this.assignComponentProperties();
        });

        if (this.shouldDefaultReportParamsBeUsed) {
            this._reportingService.stashShipmentSummaryReportParams(this.initialShipmentSummaryReportRequest);
        }

        if (this.shipmentReportRequest) {
            this._reportingService.getShipmentSummaryReport(this.shipmentReportRequest);
        }

    }

    ngOnDestroy() {
        this.reportingShipmentListSubscription.unsubscribe();
        this.appStateSubscription.unsubscribe();
    }

    // ================================================================================================
    // ======================================= SETUP METHODS =======================================
    // ================================================================================================
    private assignComponentProperties(): void {
        let endDate = this._dateHelper.getFormattedCurrentDate();
        let startDate = this._dateHelper.getFormattedStartOfCurrentMonth();

        const displayDateFormat = this._dateHelper.getDisplayDateFormat();
        if (this.shipmentReportRequest) {
            endDate = this._dateHelper.getFormattedDisplayDate(this.shipmentReportRequest.endDate, displayDateFormat);
            startDate = this._dateHelper.getFormattedDisplayDate(this.shipmentReportRequest.startDate, displayDateFormat);
            this.shipmentReportForm = this._fb.group({
                startDate: new UntypedFormControl(startDate, {
                    updateOn: 'blur',
                }),
                endDate: new UntypedFormControl(endDate, {
                    updateOn: 'blur',
                }),
                dateType: new UntypedFormControl(this.shipmentReportRequest.dateType, {
                    updateOn: 'blur'
                }),
                summaryType: new UntypedFormControl(this.shipmentReportRequest.summaryType, {
                    updateOn: 'blur'
                }),
                summaryOption: ''
            });
        } else {
            this.shipmentReportForm = this._fb.group({
                startDate: new UntypedFormControl(startDate, {
                    updateOn: 'blur',
                }),
                endDate: new UntypedFormControl(endDate, {
                    updateOn: 'blur',
                }),
                dateType: new UntypedFormControl(this.initialReportDateValue, {
                    updateOn: 'blur'
                }),
                summaryType: new UntypedFormControl(this.initialReportGroupValue, {
                    updateOn: 'blur'
                }),
                summaryOption: ''
            });

        }

        this.formattedStartDate = this.shipmentReportForm.get('startDate').value;
        this.formattedEndDate = this.shipmentReportForm.get('endDate').value;
        this.setupShipmentFormChangesSubscription();
        this.setFirstColumnDisplayName();
        this.markDirty();
    }

    private setupShipmentFormChangesSubscription(): void {
        if (!this.shipmentReportForm) {
            return;
        }

        this.shipmentReportForm.valueChanges.subscribe(val => {
            this.markDirty();
            this.isDateRangeValid();
        });
    }

    private markDirty(): void {
        if (!this.shipmentReportForm) {
            return;
        }

        this.isShipmentFormDirty = true;
        this.isShipmentFormValid = this.shipmentReportForm.valid ? true : false;
    }

    public setFirstColumnDisplayName(): void {
        if (!this.shipmentReportForm || !this.shipmentReportForm.get('summaryType').value || !this.shipmentReportGroupTypes) {
            return;
        }

        const summaryTypeFormValue = this.shipmentReportForm.get('summaryType').value;
        const selectedSummaryTypeOption: ReportDropdownTypeSet = this.shipmentReportGroupTypes.find((summaryTypeOption: ReportDropdownTypeSet) => summaryTypeOption.value === summaryTypeFormValue);

        this.firstColName = selectedSummaryTypeOption.displayName;
    }

    private prepareShipmentSummaryReportRequest(): ShipmentSummaryReportRequest {
        if (!this.shipmentReportForm || !this.shipmentReportForm.get('dateType') || !this.shipmentReportForm.get('startDate') || !this.shipmentReportForm.get('endDate') || !this.shipmentReportForm.get('summaryType')) {
            return null;
        }
        let preparedEndDate;
        const endDateFormValue = this.shipmentReportForm.get('endDate').value;
        if (endDateFormValue === DateConstants.DATE_VALUE_INDEFINITELY_display) {
            preparedEndDate = this._dateHelper.prepareDateIfEndDateIndefinitely(endDateFormValue, this.shipmentSummaryApiDateFormat);
        } else {
            preparedEndDate = endDateFormValue;
        }
        const startDateFormValue = this.shipmentReportForm.get('startDate').value;

        const shipmentSummaryReportRequest: ShipmentSummaryReportRequest = {
            endDate: preparedEndDate,
            startDate: startDateFormValue,
            dateType: this.shipmentReportForm.get('dateType').value,
            summaryType: this.shipmentReportForm.get('summaryType').value,
            summaryOption: this.shipmentReportForm.get('summaryOption').value,
        };

        return shipmentSummaryReportRequest;
    }

    private prepareSearchRequestForShipmentDetail(selectedShipmentReportCarrier: string): SearchRequest {
        const endDateFormValue = this.shipmentReportForm.get('endDate').value;
        const startDateFormValue = this.shipmentReportForm.get('startDate').value;
        const preparedStartDate: string = this._dateHelper.prepareISO_8601Date(startDateFormValue);
        const preparedEndDate: string = this._dateHelper.prepareISO_8601Date(endDateFormValue);

        const dateTypeFormValue = this.shipmentReportForm.get('dateType').value;
        const summaryTypeFormValue = this.shipmentReportForm.get('summaryType').value;
        const selectedDateTypeOption: ReportDropdownTypeSet = this.shipmentReportDateTypes.find((dateTypeOption: ReportDropdownTypeSet) => dateTypeOption.value === dateTypeFormValue);
        const selectedSummaryTypeOption: ReportDropdownTypeSet = this.shipmentReportGroupTypes.find((summaryTypeOption: ReportDropdownTypeSet) => summaryTypeOption.value === summaryTypeFormValue);

        const shipmentDetailSearchRequest: SearchRequest = {
            searchCriteria: [
                {
                    type: selectedSummaryTypeOption.filter,
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.SHIPMENTS,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    value: selectedShipmentReportCarrier,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
                },
                {
                    type: selectedDateTypeOption.filter,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.RANGE,
                    end: preparedEndDate,
                    start: preparedStartDate,
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.SHIPMENTS,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    value: `${startDateFormValue} to ${endDateFormValue}`,
                }
            ]
        };

        return shipmentDetailSearchRequest;
    }

    // ================================================================================================
    // ======================================= UI CHANGE METHODS =======================================
    // ================================================================================================
    public isDateRangeValid(): boolean {
        const endDateFormValue = this.shipmentReportForm.get('endDate').value;
        const startDateFormValue = this.shipmentReportForm.get('startDate').value;
        const indefinitelyDisplay: string = DateConstants.DATE_VALUE_INDEFINITELY_display;
        const displayDateValidation: RegExp = this._dateHelper.getDisplayDateValidation();

        if (!this.shipmentReportForm || !startDateFormValue || !endDateFormValue) {
            return false;
        };

        const startDateMoment: moment.Moment = moment(startDateFormValue, this.reportDisplayDateFormat);
        const endDateMoment: moment.Moment = moment(endDateFormValue, this.reportDisplayDateFormat);

        if (endDateFormValue !== indefinitelyDisplay) {
            if (startDateMoment.isAfter(endDateMoment) || !startDateFormValue.match(displayDateValidation) || !endDateFormValue.match(displayDateValidation)) {
                return false;
            }
        } else if (!startDateFormValue.match(displayDateValidation)) {
            return false;
        }
        return true;
    }

    public showDatePicker(): void {
        this.isDatePickerShown = true;
    }

    public hideDatePicker({ selectedDate, selectedEndDate }: { selectedDate: string, selectedEndDate: string }): void {
        if (selectedDate) {
            this.shipmentReportForm.get('startDate').setValue(selectedDate);
        }

        if (selectedEndDate) {
            this.shipmentReportForm.get('endDate').setValue(selectedEndDate);
        }

        this.isDatePickerShown = false;
    }

    public navigateToShipmentDetail(shipmentReport: ShipmentReportSummary): void {
        const searchReq: SearchRequest = this.prepareSearchRequestForShipmentDetail(shipmentReport.firstColumn);
        const searchCriteria: Array<SearchCriterion> = searchReq && searchReq.searchCriteria && searchReq.searchCriteria.length ? searchReq.searchCriteria : null;
        if (!searchCriteria || !searchCriteria[0].value || !searchCriteria[0].entityType) {
            return;
        }

        const breadcrumbs = this._breadcrumbService.breadcrumbsForReports(searchCriteria[0].value, searchCriteria[0].entityType);

        this._breadcrumbService.addBreadcrumb(breadcrumbs)
        this._searchService.processGlobalSearch(searchReq);
        this._signalsService.updateAppState({ 'shipmentReport.navigateFromHeader': false });

        this._router.navigate(['search']);
    }

    public updateReport(): void {
        if (!this.shipmentReportForm || !this.isShipmentFormValid) {
            return;
        }

        const newShipmentSummaryReportRequest = this.prepareShipmentSummaryReportRequest();
        this._reportingService.stashShipmentSummaryReportParams(newShipmentSummaryReportRequest);
        this._reportingService.getShipmentSummaryReport(newShipmentSummaryReportRequest);
    }

    public exportReport() {
        let ws;
        let wb;
        let shipReport: ShipmentReportSummary;
        const report = [];
        const header = [
            this.firstColName, 'Paid $', 'Paid %', 'Weight', 'Weight %', 'Shipments', 'Shipments %', 'Cartons',
            'Carton Cost', 'Fuel Cost', 'CWT Cost', 'Shipment Cost', 'Mile Cost'
        ];
        const totals = [
            'Total Cost', this.shipmentReportSummaryTotalList.AmountPaidTotal, '',
            this.shipmentReportSummaryTotalList.WeightTotal, '', this.shipmentReportSummaryTotalList.ShipmentTotal, '',
            this.shipmentReportSummaryTotalList.CartonsTotal, this.shipmentReportSummaryTotalList.CostPerCartonTotal,
            this.shipmentReportSummaryTotalList.FuelCostTotal, this.shipmentReportSummaryTotalList.CostPerCWTTotal,
            this.shipmentReportSummaryTotalList.CostPerShipmentTotal, this.shipmentReportSummaryTotalList.CostPerMileTotal
        ];
        for (shipReport of this.shipmentReportSummaryList) {
            report.push([
                shipReport.firstColumn,
                shipReport.AmountPaid,
                shipReport.PaidPercent,
                shipReport.Weight,
                shipReport.WeightPercent,
                shipReport.Shipment,
                shipReport.ShipmentPercent,
                shipReport.Cartons,
                shipReport.CostPerCarton,
                shipReport.FuelCost,
                shipReport.CostPerCWT,
                shipReport.CostPerShipment,
                shipReport.CostPerMile
            ]);
        }
        report.unshift(totals);
        report.unshift(header);
        ws = XLSX.utils.aoa_to_sheet(report);
        wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Shipment Summary');
        XLSX.writeFile(wb, 'shipmentSummary.xlsx');
    }
}
