import {Component} from '@angular/core';
import {AdminButtonPanelConfig} from '../../../models/adminButtonPanelConfig';
import {AdminButtonPanelService} from "../../../services/adminButtonPanel.service";

@Component({
    selector: 'app-admin-button-panel',
    templateUrl: './admin-button-panel.component.html',
    styleUrls: ['./admin-button-panel.component.scss'],
    standalone: false
})

export class AdminButtonPanelComponent {
    public buttonPanelState: AdminButtonPanelConfig = this._adminButtonPanelService.adminButtonPanelConfig;

    constructor(
        private _adminButtonPanelService: AdminButtonPanelService
    ) { }

    save() {
        this._adminButtonPanelService.saveEvent();
    }

    cancel() {
        this._adminButtonPanelService.cancelEvent();
    }

    custom() {
        this._adminButtonPanelService.customEvent();
    }

    custom2() {
        this._adminButtonPanelService.customEvent2();
    }
}
