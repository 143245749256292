import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-quote-rerate-warning-modal',
    styleUrls: ['./quote-rerate-warning-modal.component.scss'],
    templateUrl: './quote-rerate-warning-modal.component.html',
    standalone: false
})
export class QuoteReRateWarningModalComponent implements OnInit {
    @Output() public acceptWarning = new EventEmitter();
    @Output() public declineWarning = new EventEmitter();

    constructor(
    ) { }

    ngOnInit() {

    }

    public confirmReRate() {
        this.acceptWarning.emit('');
    }

    public cancelReRate() {
        this.declineWarning.emit('');
    }
}
