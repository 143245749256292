import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output, QueryList, ViewChildren
} from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormControl,
    NgModel, NG_VALIDATORS, NG_VALUE_ACCESSOR
} from '@angular/forms';
import * as _ from 'lodash-es';
import * as Constants from '../../constants/constants';
import {ProductDetail} from '../../models/productDetail';
import {UnitOfMeasure} from '../../models/unitOfMeasure';
import {CommonDataService} from '../../services/commonData.service';

@Component({
    selector: 'app-product-lines',
    styleUrls: ['./app-product-lines.component.scss'],
    templateUrl: './app-product-lines.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppProductLinesComponent,
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: AppProductLinesComponent,
            multi: true
        }
    ],
    standalone: false
})
export class AppProductLinesComponent implements ControlValueAccessor, OnChanges, OnInit {
    @Input()
    public highlightInvalids = false;
    @Input()
    public unitOfMeasure: UnitOfMeasure;
    @Input()
    public quickQuoteDisplay = true;
    @Input()
    public readOnly = false;
    @Output()
    public unitOfMeasureChanged = new EventEmitter();
    @Output()
    public dialogShown = new EventEmitter();
    @ViewChildren('lineModels')
    lineModels: QueryList<NgModel>;

    public unitOfMeasureList: Array<UnitOfMeasure>;
    public unitsCube = 'ft3';
    public unitsWeight = 'lbs';
    public unitsDimension = 'in';
    public unitsDensity = 'pcf';
    public value: Array<ProductDetail> = [];

    // eslint-disable-next-line @typescript-eslint/ban-types
    private validateFn: Function;
    constructor(private _commonDataService: CommonDataService,
               ) { }

    public async ngOnInit() {
        this.unitOfMeasureList = await this._commonDataService.loadedPromise(this._commonDataService.unitOfMeasures);
        this.checkUnitOfMeasure();
        this.setUnitLabels();
    }

    public ngOnChanges(changes) {
        if (changes) {
            this.validateFn = () => this.validateLines();
        }
        this.setUnitLabels();
    }

    public validate(c: UntypedFormControl) {
        return this.validateFn(c);
    }

    public writeValue(value) {
        const newLine = new ProductDetail();
        if (!this.quickQuoteDisplay) {
            delete newLine.Qty;
        }
        this.value = value;
        if (!this.readOnly) {
            this.value.push(newLine);
        }
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public updateProductLine($event: ProductDetail, i: number) {
        setTimeout(() => {
            const newLine = new ProductDetail();
            if (!this.quickQuoteDisplay) {
                delete newLine.Qty;
            }
            this.value[i] = $event;
            if (!this.readOnly && (i === this.value.length - 1) && (Object.keys(this.value[i]).length !== 0)) {
                this.value.push(newLine);
            }
            setTimeout(() => {
                this.valueChanged(this.value);
            }, 0);
        }, 0);
    }

    public deleteProductDetailLine(i: number) {
        this.value.splice(i, 1);
        setTimeout(() => {
            this.valueChanged(this.value);
        }, 0);
    }

    public toggleUnits() {
        let i;
        if (this.unitOfMeasure.unitOfMeasureCode === Constants.UNIT_OF_MEASURE_Imperial) {
            this.unitOfMeasure = this.findUnitOfMeasure(Constants.UNIT_OF_MEASURE_Metric);
        } else {
            this.unitOfMeasure = this.findUnitOfMeasure(Constants.UNIT_OF_MEASURE_Imperial);
        }
        this.setUnitLabels();
        for (i = 0; i < this.value.length; i++) {
            this.convertUnits(this.unitOfMeasure.unitOfMeasureCode, this.value[i])
        }
        this.unitOfMeasureChanged.emit(this.unitOfMeasure);
        this.valueChanged(this.value);
    }

    public updateUnitOfMeasure($event, idx) {
        let i;
        this.unitOfMeasure = $event;
        this.setUnitLabels();
        for (i = 0; i < this.value.length; i++) {
            if (i !== idx) {
                this.convertUnits(this.unitOfMeasure.unitOfMeasureCode, this.value[i])
            }
        }
        this.unitOfMeasureChanged.emit(this.unitOfMeasure);
        this.valueChanged(this.value);
    }

    public productLineSum(attrName: string) {
        let sum = 0;
        let line;
        for (line of this.value) {
            if (!isNaN(+line[attrName])) {
                sum += +line[attrName];
            }
        }
        return sum;
    }

    private valueChanged = (__: any) => {};

    private findUnitOfMeasure(val) {
        return _.find(this.unitOfMeasureList, (unitOfMeasure) => unitOfMeasure.unitOfMeasureCode === val);
    }

    private checkUnitOfMeasure() {
        if (!this.unitOfMeasure) {
            this.unitOfMeasure = this.findUnitOfMeasure('U');
        }
    }

    private setUnitLabels() {
        if (this.unitOfMeasure) {
            if (this.unitOfMeasure.unitOfMeasureCode === Constants.UNIT_OF_MEASURE_Imperial) {
                this.unitsCube = 'ft' + String.fromCharCode(179);
                this.unitsWeight = 'lbs';
                this.unitsDimension = 'in';
                this.unitsDensity = 'pcf';
            } else {
                this.unitsCube = 'm' + String.fromCharCode(179);
                this.unitsWeight = 'kg';
                this.unitsDimension = 'cm';
                this.unitsDensity = 'kgm';
            }
        }
    }

    private validateLines() {
        let productLineControl: NgModel;
        let lineModelArray;
        let i;

        if (this.lineModels) {
            lineModelArray = this.lineModels.toArray();
            for (i = 0; i < lineModelArray.length - 1; i++) {
                productLineControl = lineModelArray[i];
                if (productLineControl.invalid) {
                    return {invalidProductLine: true};
                }
            }
        }
        return null;
    }

    private isEmpty(val) {
        return (val === undefined) || (val === null) || (val === '');
    }

    private isValidNumeric(val) {
        return !this.isEmpty(val) || (!isNaN(val) && (+val > 0));
    }

    private convertIfValid(val, factor) {
        if (this.isValidNumeric(val)) {
            return (val * factor ).toFixed(Constants.RECORD_DISPLAY_STYLE_DECIMAL_PLACES)
        }
        return val;
    }

    private convertUnits(unitsCode: string, productDetail: ProductDetail) {
        // https://www.convertunits.com/
        if (unitsCode === Constants.UNIT_OF_MEASURE_Metric) {
            productDetail.Length = this.convertIfValid(productDetail.Length, Constants.CONVERSION_FACTOR_in2cm);
            productDetail.Width = this.convertIfValid(productDetail.Width, Constants.CONVERSION_FACTOR_in2cm);
            productDetail.Height = this.convertIfValid(productDetail.Height, Constants.CONVERSION_FACTOR_in2cm);
            productDetail.Cube = this.convertIfValid(productDetail.Cube, Constants.CONVERSION_FACTOR_cubicFt2cubicM);
            productDetail.Weight = this.convertIfValid(productDetail.Weight, Constants.CONVERSION_FACTOR_lb2kg);
        } else if (unitsCode === Constants.UNIT_OF_MEASURE_Imperial) {
            productDetail.Length = this.convertIfValid(productDetail.Length, 1 / Constants.CONVERSION_FACTOR_in2cm);
            productDetail.Width = this.convertIfValid(productDetail.Width, 1 / Constants.CONVERSION_FACTOR_in2cm);
            productDetail.Height = this.convertIfValid(productDetail.Height, 1 / Constants.CONVERSION_FACTOR_in2cm);
            productDetail.Cube = this.convertIfValid(productDetail.Cube, 1 / Constants.CONVERSION_FACTOR_cubicFt2cubicM);
            productDetail.Weight = this.convertIfValid(productDetail.Weight, 1 / Constants.CONVERSION_FACTOR_lb2kg);
        }
    }
}
