import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import * as MasterdataConstants from '../../../constants/masterData.constants';
import {SignalsService} from "../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-master-data-tabs',
    styleUrls: ['./masterdata-tabs.component.scss'],
    templateUrl: './masterdata-tabs.component.html',
    standalone: false
})
export class MasterDataTabsComponent implements OnInit, OnDestroy {
    @Input() public activeMasterdataSection: string;
    @Output() public selectMasterdataTab = new EventEmitter();

    public showLevel1Header: boolean;
    private appStateSubscription: any;

    public masterDataTabSections: {
        contacts: string,
        products: string,
        carriers: string,
    } = MasterdataConstants.MASTER_DATA_SECTIONS;
    public masterDataSectionPaths: {
        contacts: string,
        products: string,
        carriers: string,
    } = MasterdataConstants.MASTER_DATA_SECTION_PATHS;

    private appState$;

    constructor(
        private _notificationService: NotificationService,
        private _signalsService: SignalsService,
    ) {
        this.appState$ = toObservable(this._signalsService.appStateSignal);
    }

    public ngOnInit() {
        this.appStateSubscription = this.appState$.subscribe((appState) => {
            this.showLevel1Header = appState['admin.showLevel1Header'];
        });
    }

    public ngOnDestroy() {
        this.appStateSubscription.unsubscribe();
    }

    public setActiveMasterDataSection(selectedSection: string): void {
        if (selectedSection === this.masterDataTabSections.contacts) {
            this.selectMasterdataTab.emit(this.masterDataTabSections.contacts);
        } else if (selectedSection === this.masterDataTabSections.products) {
            this.selectMasterdataTab.emit(this.masterDataTabSections.products);
        } else if (selectedSection === this.masterDataTabSections.carriers) {
            this.selectMasterdataTab.emit(this.masterDataTabSections.carriers)
        } else {
            this._notificationService.notifyError({ title: `Master Data Managemnt Error`, message: `Invalid Master Data tab selected` });
        }
    }

}
