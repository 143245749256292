import {Component, OnInit} from '@angular/core';
import {CommonDataService} from '../../services/commonData.service';

@Component({
    selector: 'app-be-advised',
    styleUrls: ['./app-be-advised.component.scss'],
    templateUrl: './app-be-advised.component.html',
    standalone: false
})
export class AppBeAdvisedComponent implements OnInit {
    public beAdvisedMessage;
    public beAdvisedAbbreviation;
    public collapsePanel = false;
    public beAdvisedAbbreviationMaxValue: number = 4;

    constructor( public _commonDataService: CommonDataService) {}

    public ngOnInit(): void {
        let beAdvisedMessageAbbrevValues: Array<string> = [];
        this.beAdvisedMessage = this._commonDataService.beAdvisedMessage;
        const setUnformattedBeAdvisedMessageHeaderValue = this.beAdvisedMessage.replace(/(<([^>]+)>)/ig, '');
        beAdvisedMessageAbbrevValues = setUnformattedBeAdvisedMessageHeaderValue.split(' ').slice(0, this.beAdvisedAbbreviationMaxValue);
        this.beAdvisedAbbreviation = beAdvisedMessageAbbrevValues.join(' ') + '...';
    }

    public togglePanel() {
        this.collapsePanel = !this.collapsePanel;
    }
}
