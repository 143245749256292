import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import {Document} from '../../../models/document';
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-document-properties-modal',
    templateUrl: './document-properties-modal.component.html',
    styleUrls: ['./document-properties-modal.component.scss'],
    standalone: false
})
export class DocumentPropertiesModalComponent implements OnInit {
    @Input() public selectedDocumentId: number;
    @Input() public document: Document;
    @Output() public onClose = new EventEmitter();

    private recordSubscription: any;
    private documents: Array<Document> = [];
    public selectedDocument: Document;
    public documentPropertiesForm: UntypedFormGroup

    constructor(
        private _fb: UntypedFormBuilder,
        private _notificationService: NotificationService,
    ) {
    }

    ngOnInit() {
        this.selectedDocument = this.document;
        this.assignComponentProperties();
    }

    private assignComponentProperties(): void {
        if (!this.selectedDocument) {
            this.documentPropertiesForm = this._fb.group({
                description: ''
            });
        } else {
            this.documentPropertiesForm = this._fb.group({
                description: this.selectedDocument.description
            });
        }
    }

    public closeModal(updatedReq?): void {
        this.onClose.emit(updatedReq);
    }

    public updateFile(): void {
        if (this.documentPropertiesForm.invalid) {
            this._notificationService.notifyError({
                title: 'Update Document Properties',
                message: 'A file name is required'
            });
            return;
        }

        const updateFileDescriptionReq = {
            docId: this.selectedDocument.id,
            description: this.documentPropertiesForm.get('description').value
        };

        this.closeModal(updateFileDescriptionReq);
    }
}
