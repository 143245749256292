import {Component, ContentChild, Input, OnChanges, OnInit, TemplateRef} from '@angular/core';

@Component({
    selector: 'app-stepper',
    styleUrls: ['./app-stepper.component.scss'],
    templateUrl: './app-stepper.component.html',
    standalone: false
})
export class AppStepperComponent implements OnInit, OnChanges {
    @Input()
    public steps = [];
    @Input()
    public labels;
    @Input()
    public circleText;
    @Input()
    public showCircleText = true;
    @Input()
    public showLabels = true;
    @Input()
    public value;
    @Input()
    public completed = false;
    @Input()
    public completeSteps = false;
    @Input()
    public labelsTop = false;
    @ContentChild('stepLabelRenderer', {static: false})
    stepLabelRenderer: TemplateRef<any>;
    @ContentChild('circleTextRenderer', {static: false})
    circleTextRenderer: TemplateRef<any>;

    private currentIdx;

    constructor() {}

    public ngOnInit() {
        this.updateCurrentIdx()
    }

    public ngOnChanges() {
        this.updateCurrentIdx()
    }

    public getCircleText(i) {
        if (this.circleText) {
            return this.circleText[i];
        } else {
            return i+1;
        }
    }

    public getClass(i) {
        const stepperClasses: any = {
            'stepper-item': 1,
        }

        if (this.labelsTop) {
            stepperClasses.labelTop = 1;
        }

        if (this.completed || (i < this.currentIdx)) {
            stepperClasses.completed = 1;
        } else if (i === this.currentIdx) {
            stepperClasses.active = 1;
            if (this.completeSteps) {
                stepperClasses['completed-no-after'] = 1;
            }
        }

        return stepperClasses;
    }

    public getStepLabel(i) {
        if (this.labels) {
            return this.labels[i];
        }
        return this.steps[i];
    }

    private updateCurrentIdx() {
        let idx;
        if (this.steps) {
            for (idx = 0; idx < this.steps.length; idx++) {
                if (this.value === this.steps[idx]) {
                    this.currentIdx = idx;
                }
            }
        }
    }
}
