import { Component, EventEmitter, OnInit, Output, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { UserMasterData } from '../../../models/user.masterData';
import { AuthService } from '../../../services/auth.service';

import {UserService} from "../../../services/user.service";

@Component({
    selector: 'app-impersonate-user-modal',
    templateUrl: './impersonate-user-modal.component.html',
    styleUrls: ['./impersonate-user-modal.component.scss'],
    standalone: false
})
export class ImpersonateUserModalComponent implements OnInit {
    @Output() closeModal: EventEmitter<any> = new EventEmitter();

    public userList: Array<UserMasterData> = [];
    public filteredUsers: Array<UserMasterData>;
    public userListForm: UntypedFormGroup;

    constructor
        (
        private _fb: UntypedFormBuilder,
        private _authService: AuthService,
        private _userService: UserService,
    ) { }

    public ngOnInit() {
        this.userList = this._userService.users;
        this.userListForm = this._fb.group({
            userSearchTerm: '',
        })
        this.filteredUsers = this.userList;

        this.userListForm.valueChanges.subscribe(values => {
            this.filterUsers();
        })
    }

    public cancel(): void {
        this.closeModal.emit(null);
    }

    public selectUserForImpersonation(user: UserMasterData): void {
        this._authService.impersonate(user.userID);
        this.cancel();
    }

    public filterUsers(): void {
        const searchTerm = this.userListForm.get('userSearchTerm').value;
        if (searchTerm) {
            const searchPattern = new RegExp(searchTerm, 'i');
            this.filteredUsers = this.userList.filter((user: UserMasterData) => user.userlogin.match(searchPattern) || user.customer.match(searchPattern)
                || user.name.match(searchPattern));
        } else {
            this.filteredUsers = this.userList;
        }
    }
}
