import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {map, switchMap} from 'rxjs/operators';

import { ProductMasterData } from '../../../models/product.masterData';
import {ProductsService} from '../../../services/products.service';

@Component({
    selector: 'app-masterdata-products-list',
    styleUrls: ['./masterdata-products-list.component.scss'],
    templateUrl: './masterdata-products-list.component.html',
    standalone: false
})
export class MasterDataProductsListComponent implements OnInit, OnDestroy {
    public masterDataProducts: Array<ProductMasterData>;
    public filteredProducts: Array<ProductMasterData> = [];
    public productListForm: UntypedFormGroup;
    public selectedFilterField: string;
    public dropdownFilterFields: Array<{ displayText: string, filterField: string }> = [
        { displayText: 'All', filterField: 'all' },
        { displayText: 'Name', filterField: 'Name' },
        { displayText: 'Product ID', filterField: 'produtsId' },
        { displayText: 'Class', filterField: 'class' },
        { displayText: 'NMFC', filterField: 'NMFC' },
        { displayText: 'Description', filterField: 'Description' },
    ];

    public isConfirmDeleteModalVisible: boolean = false;
    public confirmDeleteProductTitle: string = 'Confirm Product Deletion';
    public confirmDeleteProductMessage: string = 'Are you certain you\'d like to delete this product?';
    public selectedProduct: ProductMasterData;

    private productListValueChangesSubscription: any;

    constructor(
        private _fb: UntypedFormBuilder,
        private _router: Router,
        private _productsService: ProductsService,
    ) { }

    public ngOnInit() {
        this.filteredProducts = this.masterDataProducts = this._productsService.products;

        this.productListForm = this._fb.group({
            filterTerm: ''
        });

        this.productListValueChangesSubscription = this.productListForm.valueChanges.subscribe(val => {
            this.filterProducts();
        });

        this.selectedFilterField = this.dropdownFilterFields[0].filterField;
    }

    public ngOnDestroy() {
        this.productListValueChangesSubscription.unsubscribe();
    }

    public filterProducts(): void {
        const filteredResults = [];
        const filterTerm = this.productListForm.get('filterTerm').value;
        const searchPattern = new RegExp(filterTerm, 'i');

        this.filteredProducts = this.masterDataProducts.filter((mdProduct: ProductMasterData) => {
            if (this.selectedFilterField === 'all') {
                if (mdProduct.Name.match(searchPattern) || mdProduct.produtsId.toString().match(searchPattern) || mdProduct.class.toString().match(searchPattern) || mdProduct.NMFC.toString().match(searchPattern) || mdProduct.Description.match(searchPattern)) {
                    return mdProduct;
                }
            } else if (mdProduct[this.selectedFilterField].toString().match(searchPattern)) {
                return mdProduct;
            }
        });

    }
    public deleteProduct(product: ProductMasterData): void {
        this.hideConfirmDeleteProductModal();
        if (!product || !product.produtsId) {
            return;
        }
        this._productsService.deleteProduct(product.produtsId).pipe(
            switchMap(() => this._productsService.getProductsList()),
            map(() => this.filterProducts())
        ).subscribe();
    }

    public hideConfirmDeleteProductModal(): void {
        this.isConfirmDeleteModalVisible = false;
    }

    public showConfirmDeleteModal(event: Event, product: ProductMasterData): void {
        event.stopPropagation();

        this.selectedProduct = product;
        this.isConfirmDeleteModalVisible = true;
    }

    public navigateToProductShowPage(product: ProductMasterData): void {
        if (!product || !product.produtsId) {
            return;
        }
        this._router.navigate(['masterData/products', product.produtsId]);
    }

    public navigateToCreateProductPage(): void {
        this._router.navigate(['masterData/products/new']);
    }
}
