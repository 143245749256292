import {
    Component, OnInit,
} from '@angular/core';
import {map} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {ShipmentService} from "../../services/shipment.service";

@Component({
    selector: 'app-quote-redirect',
    styleUrls: ['./app-quote-redirect.component.scss'],
    templateUrl: './app-quote-redirect.component.html',
    standalone: false
})
export class AppQuoteRedirectComponent implements OnInit {

    constructor(private _route: ActivatedRoute,
                public _shipmentService: ShipmentService,
    ) {
    }

    public ngOnInit(): void {
        this._route.params.pipe(
            map(params => {
                this._shipmentService.navigateViaQuote(params['quoteId']);
            })
        ).subscribe();
    }
}
