import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {map, take} from 'rxjs/operators';
import { unsubscribe } from '../../../helpers/utilities';

import * as AdminConstants from '../../../constants/admin.constants';
import * as Constants from '../../../constants/constants';
import * as DateConstants from '../../../constants/datetime.constants';
import {SRODAssignedUser} from '../../../models/SRODAssignedUser';
import {SRODOpsGroup} from '../../../models/SRODOpsGroup';
import {CustomerDetailMasterData} from '../../../models/customerDetail.masterData';
import {NmfcClass} from '../../../models/nmfcClass';
import {QuoteUID} from '../../../models/quoteUID';
import {ShipmentUID} from '../../../models/shipmentUID';
import {TmaOption} from '../../../models/tmaOption';
import {CommonDataService} from '../../../services/commonData.service';
import {CustomerService} from '../../../services/customer.service';
import {RadioValue} from "../../../models/radioValue";
import {BreadcrumbService} from "../../../services/breadcrumb.service";
import {AdminButtonPanelService} from "../../../services/adminButtonPanel.service";
import { NotificationService } from 'app/services/notification.service';
import {TabInfo} from "../../../models/tabInfo";

@Component({
    selector: 'app-admin-customer',
    styleUrls: ['./admin-customer.component.scss'],
    templateUrl: './admin-customer.component.html',
    standalone: false
})
export class AdminCustomerComponent implements OnInit, OnDestroy {
    public nmfcClasses: Array<NmfcClass> = [];
    public quoteUIDOptions: Array<QuoteUID> = [];
    public shipmentUIDOptions: Array<ShipmentUID> = [];
    public SRODOpsGroupOptions: Array<SRODOpsGroup> = [];
    public SRODAssignedUsersOptions: Array<SRODAssignedUser> = [];
    public tmaOptions: Array<TmaOption> = [];
    public dateConstants = DateConstants;

    public customerId: string;

    public customerTabSections: {
        general: string,
        api_edi: string,
        olt_credentials: string
    } = AdminConstants.CUSTOMER_TAB_SECTIONS;
    public activeCustomerTabSection: string = this.customerTabSections.general;

    public customerForm: UntypedFormGroup;

    public dropdownResultLimit: number = Constants.DROPDOWN_RESULT_LIMIT;
    public isCustomerOverflowModalVisible: boolean = false;
    public selectedOverflowModalOption: { fieldName: string, displayName: string, resultSet: Array<any> };
    public selectedOverflowModalFormControl: UntypedFormControl = null;
    public overflowModalTitle: string;
    public passwordInputType = 'password';
    public tmaAccepted;
    public APIEDI;
    public isDatePickerModalShown = false;
    public defaultDatePickerDate;
    public nameCodeMap;
    public yesNoRadioValues: Array<RadioValue> = [{value: false, label: 'No'},{value: true, label: 'Yes'}];
    private routeSubscription: Subscription;
    private customerFormChangeSubscription: any;

    public activeTab = 0;
    public tabs: Array<TabInfo> = [
        {
            tabIcons: [
                {
                    outerIcon: 'fa-circle',
                    outerIconClass: 'customerGeneralOuterIcon',
                    innerIcon: 'fa-info',
                    innerIconClass: 'customerGeneralInnerIcon'
                }
            ],
            text: 'General'
        },
        {
            tabIcons: [
                {
                    outerIcon: 'fa-circle',
                    outerIconClass: 'customerApiOuterIcon',
                    innerIcon: 'fa-code-fork',
                    innerIconClass: 'customerApiInnerIcon'
                }
            ],
            text: 'API/EDI'
        },
        {
            tabIcons: [
                {
                    outerIcon: 'fa-circle',
                    outerIconClass: 'customerOltOuterIcon',
                    innerIcon: 'fa-stack-exchange',
                    innerIconClass: 'customerOltInnerIcon'
                }
            ],
            text: 'OLT Credentials'
        }
    ];

    get custEDIRef() {
        return this.customerForm.get('custEDIRef');
    }
    get custName() {
        return this.customerForm.get('custName');
    }
    get custNo() {
        return this.customerForm.get('custNo');
    }
    get tmaID() {
        return this.customerForm.get('tmaID');
    }
    get SROD_srodName() {
        return this.customerForm.get('SROD_srodName');
    }

    constructor(
        private _route: ActivatedRoute,
        private _notificationService: NotificationService,
        private _breadcrumbService: BreadcrumbService,
        private _adminButtonPanelService: AdminButtonPanelService,
        private _customerService: CustomerService,
        private _commonDataService: CommonDataService,
    ) {
    }

    public ngOnInit() {
        this.setupButtonPanel();

        this.nameCodeMap = this._commonDataService.nameCodeMap;
        this.SRODAssignedUsersOptions = this._commonDataService.srodAssignedUsersOptions;
        this.SRODOpsGroupOptions = this._commonDataService.srodOpsGroupOptions;
        this.nmfcClasses = this._commonDataService.nmfcClasses;
        this.tmaOptions = this._commonDataService.tmaList;

        this.routeSubscription = this._route.params.pipe(
            map((params) => {
                this.customerId = params['customerId'];
                this._customerService.getCustomerByPath(this.customerId).pipe(
                    take(1),
                    map(async (customer: CustomerDetailMasterData) => {
                        this.tmaAccepted = customer.tmaAccepted;
                        this.APIEDI = customer.APIEDI;
                        this.customerForm = this._customerService.getCustomerFormFromLastCustomer();

                        if (this.customerId) {
                            this.loadQuoteUIDOptions(this.customerId);
                            this.loadShipmentUIDOptions(this.customerId);
                        }

                        this.setBreadcrumb(this.customerId);
                        if (this.customerFormChangeSubscription) {
                            this.customerFormChangeSubscription.unsubscribe();
                        }
                        this.activateButtons();
                        this.customerFormChangeSubscription = this.customerForm.valueChanges.subscribe(() => {
                            this.activateButtons();
                        })
                    })
                ).subscribe();
            })
        ).subscribe();
    }

    public ngOnDestroy() {
        unsubscribe(this.routeSubscription);
        unsubscribe(this.customerFormChangeSubscription);
        this._adminButtonPanelService.showSaveCancelButtons(false);
    }

    public isNewCustomer() {
        return !this.customerId || (this.customerId === 'new');
    }

    public saveChanges(): void {
        if (!this.customerForm || !this.customerForm.valid) {
            this._notificationService.notifyError({ title: 'Save Customer Changes', message: `Please fill out required fields properly before updating` });
            return;
        }

        const customerDetail: CustomerDetailMasterData = this._customerService.getCustomerFromForm(this.customerForm);

        if (this.isNewCustomer()) {
            this._customerService.createCustomer(customerDetail).pipe(
                map((customer: CustomerDetailMasterData) => {
                    this._breadcrumbService.popBreadcrumb();
                    this._customerService.getCustomerList().subscribe();
                })
            ).subscribe();
        } else {
            this._customerService.updateCustomer(customerDetail).pipe(
                map((customer: CustomerDetailMasterData) => {
                    this._customerService.getCustomerList().subscribe();
                    this._customerService.updateLastCustomer(customer);
                    this._customerService.updateForm(this.customerForm);
                })
            ).subscribe();
        }
    }

    // ================================================================================================
    // ======================================= UI CHANGE METHODS =======================================
    // ================================================================================================
    public setActiveCustomerTabSection(section: string): void {
        this.activeCustomerTabSection = section;
    }

    public setTma(value: boolean): void {
        const isTMAAcceptedFormControl: UntypedFormControl = this.customerForm.get('tmaAccepted') as UntypedFormControl;

        if (!isTMAAcceptedFormControl) {
            return;
        }

        isTMAAcceptedFormControl.setValue(value);
    }

    // NOTE Hassan (07/05/18) - can't type event parameter to Event as event target doesn't have 'files' property
    public onFileChange(event): void {
        if (!this.customerForm || !this.customerForm.get('logo_logo')) {
            return;
        }

        const reader = new FileReader();

        if (event.target.files && event.target.files.length > 0) {
            const logo = event.target.files[0];

            reader.readAsDataURL(logo);
            reader.onload = () => {
                const image = new Image();
                const fileType: string = logo.type;

                image.src = `${reader.result}`; // NOTE Hassan (8/20/18) - using string interpolation to satisfy type error from String | ArrayBuffer

                image.onload = () => {
                    if (this.checkFileUploadRestrictions(image, fileType)) {
                        this.setFileUploadFormData(logo.name, `${reader.result}`, image); // NOTE Hassan (8/20/18) - using string interpolation to satisfy type error from String | ArrayBuffer
                    }
                }
            }
        }
    }

    public clearOverflowFormControlDisplayName(event: Event, selectedFormControl: AbstractControl): void {
        event.stopPropagation();

        if (!selectedFormControl) {
            return;
        }

        selectedFormControl.setValue('');
    }

    public showAdminCustomerOverflowModal(fieldName: string, displayName: string, resultSet, selectedFormControl: AbstractControl, modalTitle: string): void {
        if (!selectedFormControl) {
            return;
        }

        this.selectedOverflowModalOption = {
            fieldName,
            displayName,
            resultSet
        }
        this.selectedOverflowModalFormControl = selectedFormControl as UntypedFormControl;
        this.overflowModalTitle = modalTitle;

        this.isCustomerOverflowModalVisible = true;
    }

    public closeAdminCustomerOverflowModal(selectedResult?: { displayName: string, value: string }): void {
        if (selectedResult && this.selectedOverflowModalFormControl) {
            this.selectedOverflowModalFormControl.setValue(selectedResult.value);
        }

        this.selectedOverflowModalFormControl = null;
        this.isCustomerOverflowModalVisible = false;
    }

    public findDisplayObject(selected, optionsList) {
        let option = optionsList.find((user) => user.userid === selected);
        if (!option) {
            option = {};
        }
        return option;
    }

    public showDatePickerModal() {
        this.defaultDatePickerDate = this.customerForm.get('custStartDate').value;
        this.isDatePickerModalShown = true;
    }

    public hideDatePickerModal({selectedDate}: { selectedDate: string }) {
        this.isDatePickerModalShown = false;

        if (!selectedDate) {
            return;
        }

        const custStartDateControl = this.customerForm.get('custStartDate');

        custStartDateControl.markAsDirty();
        custStartDateControl.setValue(selectedDate);
    }

    private activateButtons() {
        if (this.customerForm.controls.custNo.value && this._customerService.formEqualsLastCustomer(this.customerForm)) {
            this.customerForm.markAsPristine();
        } else {
            this.customerForm.markAsDirty();
        }

        this._adminButtonPanelService.activateSaveButton(this.customerForm.dirty && this.customerForm.valid);
    }

    private setBreadcrumb(id) {
        let breadcrumbLabel;
        const breadcrumbUrl = 'admin/customers/' + id;

        switch (id) {
            case 'new':
                breadcrumbLabel = 'Create Customer';
                break;
            default:
                breadcrumbLabel = this.customerForm.controls.custName.value;
        }
        this._breadcrumbService.addOrUpdateBreadcrumb({label: breadcrumbLabel, urlValue: breadcrumbUrl});
    }

    private setupButtonPanel() {
        this._adminButtonPanelService.showSaveCancelButtons(true);
        this._adminButtonPanelService.activateSaveButton(false);

        this._adminButtonPanelService.register({
            saveEvent: () => {
                this.saveChanges();
            },
            cancelEvent: () => {
                this.lastCustomerToForm();
            }
        });
    }

    private lastCustomerToForm() {
        this._customerService.updateForm(this.customerForm);
        this.customerForm.markAsPristine();
    }

    private checkFileUploadRestrictions(logo: HTMLImageElement, fileType: string): boolean {
        const isImageSizeInvalid: boolean = logo.height > AdminConstants.ADMIN_CUSTOMER_LOGO_SIZE_RESTRICTIONS.maximum_height || logo.width > AdminConstants.ADMIN_CUSTOMER_LOGO_SIZE_RESTRICTIONS.maximum_width;
        const isFileTypeInvalid: boolean = fileType !== AdminConstants.ADMIN_CUSTOMER_LOGO_FILETYPES.jpg && fileType !== AdminConstants.ADMIN_CUSTOMER_LOGO_FILETYPES.png;

        if (isImageSizeInvalid) {
            this._notificationService.notifyError({ title: 'Logo Upload', message: `Please upload a file whose dimensions are within 666px x 108px.` });
        }

        if (isFileTypeInvalid) {
            this._notificationService.notifyError({ title: 'Logo Upload', message: `Logo must be of .jpg or .png filetypes.` });
        }

        return !(isImageSizeInvalid || isFileTypeInvalid);
    }

    private setFileUploadFormData(filename: string, base64Logo: string, logoImage: HTMLImageElement): void {
        if (!this.customerForm || !this.customerForm.get('logo_logo') || !this.customerForm.get('logo_filename')) {
            return;
        }

        const logoFilenameFormControl: UntypedFormControl = this.customerForm.get('logo_filename') as UntypedFormControl;
        const logoFormControl: UntypedFormControl = this.customerForm.get('logo_logo') as UntypedFormControl;
        const logoHeightFormControl: UntypedFormControl = this.customerForm.get('logo_height') as UntypedFormControl;
        const logoWidthFormControl: UntypedFormControl = this.customerForm.get('logo_width') as UntypedFormControl;

        logoFilenameFormControl.setValue(filename);
        logoFormControl.setValue(base64Logo);
        logoHeightFormControl.setValue(logoImage.height);
        logoWidthFormControl.setValue(logoImage.width);
    }

    private loadQuoteUIDOptions(customerId: string) {
        this._commonDataService.loadQuoteUIDOptions(customerId).pipe(
            take(1),
            map(options => {
                if (Array.isArray(options)) {
                    this.quoteUIDOptions = options;
                }
            })
        ).subscribe();
    }

    private loadShipmentUIDOptions(customerId: string) {
        this._commonDataService.loadShipmentUIDOptions(customerId).pipe(
            take(1),
            map(options => {
                if (Array.isArray(options)) {
                    this.shipmentUIDOptions = options;
                }
            }),
        ).subscribe();
    }

    public getTabSection(index) {
        switch (index) {
            case 0:
                this.activeCustomerTabSection = this.customerTabSections.general;
            case 1:
                this.activeCustomerTabSection = this.customerTabSections.api_edi;
            case 2:
                this.activeCustomerTabSection = this.customerTabSections.olt_credentials;
            default:
                this.activeCustomerTabSection = this.customerTabSections.general;
        }
        return index;
    }

    public getTmaName(tmaOption) {
        return tmaOption.tmaName;
    }

    public getTmaID(tmaOption) {
        return tmaOption.tmaID;
    }

    public getTmaList() {
        let theList = [];
        theList = [{tmaName: '', tmaID: '', tmaDescription: ''}, ...this.tmaOptions];
        return theList;
    }
}
