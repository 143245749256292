import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {TourService} from '../../services/tour.service';

@Component({
    selector: 'app-tour',
    styleUrls: ['./app-tour.component.scss'],
    templateUrl: './app-tour.component.html',
    standalone: false
})
export class AppTourComponent implements OnInit, OnDestroy {
    public tourPdfName;
    public currentTourPage = 1;
    public cancelText = 'Cancel';
    public pdfData: any;
    public fileNames = [];
    public showingFiles = true;

    private tourSubscription: Subscription;

    constructor(public tourService: TourService) {
    }

    public ngOnDestroy(): void {
        this.tourSubscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.tourSubscription = this.tourService.tourPdfNameSubject.pipe(
            map((fileName) => {
                if (Array.isArray(fileName)) {
                    this.fileNames.length = 0;
                    this.fileNames.push(...fileName);
                    this.currentTourPage = 1;
                    this.showingFiles = true;
                } else if (fileName) {
                    this.currentTourPage = 1;
                    this.tourPdfName = fileName;
                    this.showingFiles = false;
                }
            })
        ).subscribe();
    }

    public nextInactive() {
        if (this.showingFiles) {
            return (this.fileNames.length <= this.currentTourPage);
        } else if (this.pdfData) {
            return (this.numPages() <= this.currentTourPage);
        }
        return false;
    }

    public prevInactive() {
        return (this.currentTourPage <= 1);
    }

    public pdfInfo($event) {
        this.pdfData = $event;
    }

    public numPages() {
        if (this.showingFiles) {
            return this.fileNames.length;
        } else {
            return this.pdfData.numPages;
        }
    }
    public nextPage() {
        if (this.currentTourPage < this.numPages()) {
            this.currentTourPage++;
            if (this.currentTourPage === this.numPages()) {
                this.cancelText = 'Done';
            }
        }
    }

    public prevPage() {
        if (this.currentTourPage > 1) {
            this.cancelText = 'Cancel';
            this.currentTourPage--;
        }
    }

    public pdfError($event) {
        console.error($event);
    }

    public cancel() {
        this.tourService.stopTour();
    }
}
