import {
    Component,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {User} from '../../../models/user';
import {SignalsService} from "../../../services/signals.service";
import {Insurance} from "../../../models/insurance";
import {ShipmentUnified} from "../../../models/shipmentUnified";
import {Subscription} from "rxjs";
import {InsuranceService} from "../../../services/insurance.service";
import {debounceTime, distinctUntilChanged, map, tap} from "rxjs/operators";
import {CommonDataService} from "../../../services/commonData.service";

@Component({
    selector: 'app-place-shipment-modal',
    templateUrl: './place-shipment-modal.component.html',
    styleUrls: ['./place-shipment-modal.component.scss'],
    standalone: false
})
export class PlaceShipmentModalComponent implements OnInit, OnDestroy {
    @Input() public shipment: ShipmentUnified = null;
    @Input() public insurance: Insurance;
    @Output() public closePlaceShipmentModal = new EventEmitter();

    public placeShipmentForm: UntypedFormGroup;
    private currentUser: User;
    public hasInsurance;
    private subs = new Subscription();

    private numRegex = /^-?\d*[.,]?\d{0,2}$/;

    constructor(
        private _fb: UntypedFormBuilder,
        private _signalsService: SignalsService,
        private _insuranceService: InsuranceService,
        public _commonDataService: CommonDataService,
    ) {
    }

    public async ngOnInit() {
        this.hasInsurance = this.insurance?.insuranceCharge;

        this.currentUser = this._signalsService.userSignal();

        this.assignComponentPropertiesFromCurrentUser();

        this.subs.add(this.placeShipmentForm.controls.shipmentValue.valueChanges.pipe(
            tap(() => this.placeShipmentForm.get('insuranceCharge').setValue('')),
            debounceTime(500),
            distinctUntilChanged(),
            map(() => {
                this.updateInsuranceCharge();
            })
        ).subscribe());

        if (!this.hasInsurance) {
            this.updateInsuranceCharge();
        }
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private assignComponentPropertiesFromCurrentUser(): void {
        this.placeShipmentForm = this._fb.group({
            bolName: new UntypedFormControl(this.currentUser?.name ?? '', [
                Validators.required
            ]),
            bolEmail: new UntypedFormControl(this.currentUser?.email ?? '', [
                Validators.required
            ]),
            bolPhone: new UntypedFormControl(this.currentUser?.phone ?? '', [
                Validators.required
            ]),
            shipmentValue: new UntypedFormControl(this.insurance?.shipmentValue ?? '', [Validators.pattern(this.numRegex)]),
            insuranceCharge: new UntypedFormControl('')
        });
    }

    private async updateInsuranceCharge() {
        let charge;

        if (this.placeShipmentForm.controls.shipmentValue.value && this.placeShipmentForm.controls.shipmentValue.valid) {
            charge = await this._insuranceService.getInsuranceCharge(this.placeShipmentForm.controls.shipmentValue.value).toPromise();
            if (charge) {
                this.placeShipmentForm.get('insuranceCharge').setValue(charge);
            }
        }
    }

    public saveChanges(purchasedInsurance = true): void {
        const isPlaceShipmentFormValid = this.placeShipmentForm.valid;
        let placeShipmentValue;

        if (!isPlaceShipmentFormValid) {
            const requiredFormControls: Array<AbstractControl> = [
                this.placeShipmentForm.get('bolName'),
                this.placeShipmentForm.get('bolEmail'),
                this.placeShipmentForm.get('bolPhone'),
            ];

            requiredFormControls.forEach(placeShipmentFormControl => {
                if (placeShipmentFormControl.invalid) {
                    placeShipmentFormControl.markAsDirty();
                }
            });
        }

        if (!isPlaceShipmentFormValid) {
            return;
        }

        placeShipmentValue = this.placeShipmentForm.value;
        if (!purchasedInsurance) {
            placeShipmentValue.insuranceCharge = '';
        }
        this.closePlaceShipmentModal.emit(placeShipmentValue);
    }

    public cancel(): void {
        this.closePlaceShipmentModal.emit(null);
    }

    protected readonly window = window;
}
