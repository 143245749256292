// **RTG** This component is not really used in the app, and does not work with the API either as is
//         The decision seems to have been made earlier on that global search does what this would have
//         done, so the only way into it was via the routing which I will remove.  Best to consider
//         removing when a larger refactoring of the reports is done, but testing will be needed to
//         be sure there is no dependency I am unaware of here.
// Edit:   This does seem to be accessible via global search and clicking on an invoice that shows up
//         so while I still do not think it is used by anyone, I have left the route and component in.

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Constants from '../../../../constants/constants';
import * as DateConstants from '../../../../constants/datetime.constants';
import { DateTimeHelper } from '../../../../helpers/datetime.helper';
import { InvoiceDetailReportRequest } from '../../../../models/invoiceDetailReportRequest';
import { InvoiceDetailReportResponse } from '../../../../models/invoiceDetailReportResponse';
import { InvoiceReportDetail } from '../../../../models/invoiceReportDetail';
import {SignalsService} from "../../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {ReportingService} from "../../../../services/reporting.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
    selector: 'app-reports-invoice-detail',
    styleUrls: ['./reports-invoice-detail.component.scss'],
    templateUrl: './reports-invoice-detail.component.html',
    standalone: false
})
export class ReportsInvoiceDetailComponent implements OnInit, OnDestroy {

    private reportingStoreSubscription: any;

    private invoiceDetailReportApiDateFormat: string = DateConstants.DATE_FORMAT_yyyymmdd;
    public invoiceDetailReportResponse: InvoiceDetailReportResponse;
    public invoiceDetailReports: Array<InvoiceReportDetail> = [];

    private reportState$;

    constructor(
        private _router: Router,
        private _signalsService: SignalsService,
        private _reportingService: ReportingService,
        private _notificationService: NotificationService,
        private _dateHelper: DateTimeHelper,
    ) {
        this.reportState$ = toObservable(this._signalsService.reportingSignal);
    }

    ngOnInit() {
        this._reportingService.getInvoiceDetailReport(this.prepareInvoiceDetailReportRequest());

        this.reportingStoreSubscription = this.reportState$.subscribe((reportingState) => {
            this.invoiceDetailReportResponse = reportingState && reportingState.invoiceDetailReports ? reportingState.invoiceDetailReports : null;
            this.invoiceDetailReports = reportingState && reportingState.invoiceDetailReports && reportingState.invoiceDetailReports.Report ? reportingState.invoiceDetailReports.Report : [];
        });
    }

    ngOnDestroy() {
        this.reportingStoreSubscription.unsubscribe();
    }

    private prepareInvoiceDetailReportRequest(): InvoiceDetailReportRequest {
        const urlSegments: Array<string> = this._router.url.split('/');
        const urlScac: string = urlSegments[urlSegments.length - 1];

        const rawEndDate = this._dateHelper.getFormattedCurrentDate();
        const formattedEndDate = this._dateHelper.prepareDate(rawEndDate, this.invoiceDetailReportApiDateFormat);

        const invoiceDetailReportRequest: InvoiceDetailReportRequest = {
            startDate: '2018/06/06',    // hardcoded per Ta-Hajj and Josh
            endDate: formattedEndDate,
            datetype: 'delivery', // hardcoded now per Josh, will be dynamically set by user with dropdowns in future
            detailType: 'carrier', // hardcoded now per Josh, will be dynamically set by user with dropdowns in future
            detailVariable: urlScac,
        }

        return invoiceDetailReportRequest;
    }

    public navigateToInvoice(invoiceDetailReport: InvoiceReportDetail): void {
        if (!invoiceDetailReport || !invoiceDetailReport.invoiceID) {
            this._notificationService.notifyError({ title: `Invoice Reporting`, message: `Missing information required for invoice navigation` });    // we should never get here
            return;
        }

        const invoiceId = invoiceDetailReport.invoiceID;

        this._signalsService.updateAppState({ 'invoice.navigationOrigin': Constants.INVOICE_NAVIGATION_SOURCE_reporting });
        this._router.navigate(['invoice', invoiceId]);
    }

    public navigateToInvoiceSummary(): void {
        this._router.navigate(['reports/invoices']);
    }
}
