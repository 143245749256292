import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { makeClone } from '../../../helpers/utilities';

@Component({
    selector: 'app-admin-customer-overflow-modal',
    templateUrl: './admin-customer-overflow-modal.component.html',
    styleUrls: ['./admin-customer-overflow-modal.component.scss'],
    standalone: false
})
export class AdminCustomerOverflowModalComponent implements OnInit {
  @Input() selectedOverflowModalOption: { fieldName: string, displayName: string, resultSet: Array<any> };
  @Input() modalTitle: string;

  @Output() closeModal: EventEmitter<{ displayName: string, value: any }> = new EventEmitter();

  public filteredResultSet: Array<any>;
  public overflowQuery: string = '';

  constructor(

  ) { }

  public ngOnInit() {
    this.resetResultSet();
  }

  // ================================================================================================
  // ======================================= CALC METHODS ==========================================
  // ================================================================================================
  public resetResultSet(): void {
    this.filteredResultSet = makeClone(this.selectedOverflowModalOption.resultSet);
  }

  public updateFilteredResultSet(searchQuery: string): void {
    this.resetResultSet();

    const searchPattern = new RegExp(searchQuery, 'i');
    const displayNameField: string = this.selectedOverflowModalOption.displayName;

    this.filteredResultSet = this.filteredResultSet.filter(result => {
      if (result[displayNameField] && result[displayNameField].match(searchPattern)) {
        return result;
      }
    })
  }

  // ================================================================================================
  // ======================================= UI METHODS =============================================
  // ================================================================================================
  public cancel(): void {
    this.closeModal.emit(null);
  }

  public selectResult(selectedResult: any): void {
    if (!selectedResult) {
      this.cancel();
    }

    this.closeModal.emit({
      displayName: selectedResult[this.selectedOverflowModalOption.displayName],
      value: selectedResult[this.selectedOverflowModalOption.fieldName],
    });
  }
}
