import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuotedRate} from '../../models/quotedRate';
import {BookingOption, MonetaryValue} from '../../models/unifiedShipmentObject';
import {MonetaryValueImpl} from '../../models/usoImpl';

@Component({
    selector: 'app-best-carrier',
    styleUrls: ['./app-best-carrier-option.component.scss'],
    templateUrl: './app-best-carrier-option.component.html',
    standalone: false
})
export class AppBestCarrierOptionComponent implements OnInit {
    @Input()
    public buttonText = 'Best Option';
    @Input()
    public bestCarrierOption: BookingOption;
    @Input()
    public rate: QuotedRate;
    @Input()
    public isCarrierKPIShown;
    @Output()
    public selected = new EventEmitter<BookingOption|QuotedRate>();
    @Output()
    public imageClick = new EventEmitter<null>();
    @Output()
    public infoClick = new EventEmitter<null>();

    public guaranteed = false;

    constructor() {}

    public ngOnInit(): void {
        if (this.bestCarrierOption) {
            this.guaranteed = this.bestCarrierOption.guaranteedDelivery;
        } else {
            this.guaranteed = this.rate.guarantee;
        }
    }

    public selectCarrier() {
        if (this.bestCarrierOption) {
            this.selected.emit(this.bestCarrierOption);
        } else {
            this.selected.emit(this.rate);
        }
    }

    public doInfoClick() {
        this.infoClick.emit();
    }

    public doImageClick() {
        this.imageClick.emit();
    }
}
