import {
    Component, Input,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export class LabelValue {
    label: string = '';
    value: string = '';
}

@Component({
    selector: 'app-drop-value',
    styleUrls: ['./app-drop-value.component.scss'],
    templateUrl: './app-drop-value.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppDropValueComponent,
            multi: true
        }
    ],
    standalone: false
})
export class AppDropValueComponent implements ControlValueAccessor {
    @Input()
    public listRestyle = {};
    @Input()
    public inputRestyle = {};
    @Input()
    public labels: Array<string> = new Array<string>();
    @Input()
    public allowDuplicateLabels = false;

    public value: Array<LabelValue> = new Array<LabelValue>();

    constructor() {
        this.value.push(new LabelValue());
    }

    public writeValue(value: Array<LabelValue>) {
        this.value = value;
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public inputUpdated(i: number, $event) {
        const labelsLeft = this.getLabelList(-1);
        this.value[i].value = $event;
        if ((this.value[i].value) && ((i+1) === this.value.length) && (labelsLeft.length > 0)) {
            this.value.push({label: labelsLeft[0], value: ''});
        }
        this.valueChanged(this.value);
    }

    public getLabelList(i: number) {
        let label;
        const unusedLabels = new Array<string>();
        if (this.allowDuplicateLabels) {
            return this.labels;
        } else {
            if (i >= 0) {
                unusedLabels.push(this.value[i].label);
            }
            for (label of this.labels) {
                if (!this.labelUsed(label)) {
                    unusedLabels.push(label);
                }
            }
            return unusedLabels;
        }
    }

    public selectLabel($event, idx) {
        this.value[idx].label = $event.target.value;
        this.valueChanged(this.value);
    }

    private labelUsed(label) {
        let tuple: LabelValue;

        for (tuple of this.value) {
            if (tuple.label === label) {
                return true;
            }
        }

        return false;
    }

    private valueChanged = (_: any) => {
    };

}
