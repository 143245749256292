import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { TmaRequest } from '../../../models/tmaRequest';
import {LogoutService} from "../../../services/logout.service";
import {CommonDataService} from "../../../services/commonData.service";
import {NotificationService} from "../../../services/notification.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {SignalsService} from "../../../services/signals.service";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-tma-acceptance-modal',
    templateUrl: './tma-acceptance-modal.component.html',
    // template:,
    styleUrls: ['./tma-acceptance-modal.component.scss'],
    standalone: false
})
export class TmaAcceptanceModalComponent implements OnInit {
    @Output() public closeTmaAcceptanceModal = new EventEmitter();

    public approverFirstName: string = '';
    public approverLastName: string = '';
    public approverTitle: string = '';
    private tmaText$;
    private tmaSubscription;

    constructor(
        private _logoutService: LogoutService,
        private _notificationService: NotificationService,
        private _commonDataService: CommonDataService,
        private _signalsService: SignalsService,
    ) {
        this.tmaText$ = toObservable(this._signalsService.tmaSignal);
    }

    public async ngOnInit() {
        const tmaContainer: HTMLElement = document.getElementById('tmaContainer');
        this.tmaSubscription = this.tmaText$.pipe(
            map((tmaText: string) => {
                if (tmaContainer) {
                    tmaContainer.innerHTML = tmaText;
                }
            })
        ).subscribe();
    }

    public ngOnDestroy() {
        this.tmaSubscription.unsubscribe();
    }

    public closeModal() {
        this.closeTmaAcceptanceModal.emit('');
    }

    public logout(): void {
        this._logoutService.logout();
        this.closeModal();
    }

    public submitApproval(): void {
        const approvalReq: TmaRequest = {
            tmaAccepted: true,
            firstName: this.approverFirstName,
            lastName: this.approverLastName,
            title: this.approverTitle
        }

        if (!approvalReq.firstName) {
            this._notificationService.notifyError({ title: `Approve TMA Error`, message: `Please enter your first name` });
        } else if (!approvalReq.lastName) {
            this._notificationService.notifyError({ title: `Approve TMA Error`, message: `Please enter your last name` });
        } else if (!approvalReq.title) {
            this._notificationService.notifyError({ title: `Approve TMA Error`, message: `Please enter your title` });
        } else {
            this._commonDataService.submitTMAApproval(approvalReq);
            this.closeModal();
        }
    }

}
