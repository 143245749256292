import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

import * as SearchConstants from '../constants/searchCriteria';
import {DateTimeHelper} from '../helpers/datetime.helper';

@Pipe({
    name: 'checkDate',
    standalone: false
})
export class CheckDatePipe implements PipeTransform {
    constructor(
        private _dateHelper: DateTimeHelper,
    ) {
    }

    transform(fieldValue: any): string {
        if ((fieldValue === SearchConstants.SEARCH_CRITERIA_value.FALSE) || (fieldValue === SearchConstants.SEARCH_CRITERIA_value.TRUE)) {
            return fieldValue;
        }

        if (typeof fieldValue === 'string' && fieldValue.match('dddd-dd-dd')) {
            return this._dateHelper.getFormattedDisplayDate(fieldValue, moment.ISO_8601);
        }

        return fieldValue;
    }
}
