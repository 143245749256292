import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EMPTY, Subscription } from 'rxjs';
import {catchError, map, take, timeout } from 'rxjs/operators';

import { LoginSettings } from '../../models/loginSettings';
import {AuthService} from '../../services/auth.service';
import {TourService} from '../../services/tour.service';
import {CommonDataService} from "../../services/commonData.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {SignalsService} from "../../services/signals.service";
import {ReportingService} from "../../services/reporting.service";
import {AppState} from "../../models/appState";

@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {

    public isUserSessionActive: boolean;
    public loader;
    public createViewBySearch: boolean = false;

    public userSettings: LoginSettings;
    public hasTMABeenAccepted: boolean;
    public isUserAuthorizedToAcceptTMA: boolean;
    public isTMAAcceptanceVisible: boolean = false;
    public isSiteInaccessibleModalVisible: boolean = false;
    public isCubeCalculatorModalVisible: boolean = false;
    public isFloaterActive: boolean = false;

    public isClipboardPinned: boolean;
    public isRecordViewStylePromptShown: boolean;

    private appStateSubscription: any;
    private userSettings$;
    private appState$;
    private userSettingsSubscription: any;

    private queryParamsSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _authService: AuthService,
        private _reportingService: ReportingService,
        private _commonDataService: CommonDataService,
        private _signalsService: SignalsService,
        public tourService: TourService,
    ) {
        this.userSettings$ = toObservable(this._signalsService.loginSettingsSignal);
        this.appState$ = toObservable(this._signalsService.appStateSignal);
    }

    public ngOnInit() {
        this.queryParamsSub = this._route.queryParams.pipe(
            take(2),
            map((params) => {
                if (params.fso && !this._authService.fso) {
                    this._authService.fso = params.fso;
                    this.queryParamsSub.unsubscribe();
                }
            }),
            timeout(100),
            catchError(() => {
                this.queryParamsSub.unsubscribe();
                return EMPTY;
            })
        ).subscribe();

        this.appStateSubscription = this.appState$.subscribe((appState: AppState) => {
            this.isUserSessionActive = appState['global.isUserSessionActive'];
            this.createViewBySearch = appState['dashboard.createViewBySearch'];
            this.isCubeCalculatorModalVisible = appState['modal.isCubeCalculatorModalShown'];
            this.isClipboardPinned = appState['floater.clipboardPinned'];
            this.isFloaterActive = !appState['floater.clipboardCollapsed'];
            this.isRecordViewStylePromptShown = appState['record.isRecordViewStylePromptShown'];
        });

        this.userSettingsSubscription = this.userSettings$.subscribe((us: LoginSettings) => {
            this.userSettings = us;
            if (this.userSettings && this.userSettings.TMA) {
                this.hasTMABeenAccepted = this.userSettings.TMA.tmaAccepted;
                this.isUserAuthorizedToAcceptTMA = this.userSettings.TMA.tmaAuthorized;

                if (!this.hasTMABeenAccepted && this.isUserAuthorizedToAcceptTMA) {
                    this.isTMAAcceptanceVisible = true;
                } else if (!this.hasTMABeenAccepted && !this.isUserAuthorizedToAcceptTMA) {
                    this.isSiteInaccessibleModalVisible = true;
                }
            }
        });

        // let angular initialization finish before calling any backend APIs
        window.setTimeout(async () => {
            await this._authService.refreshLoginAsync();
            this._commonDataService.getCountries().subscribe();
            this._commonDataService.getHandlingUnits().subscribe();
            this._commonDataService.getNmfcClasses().subscribe();
            this._commonDataService.getNmfcGroups().subscribe();
            this._commonDataService.getPackageTypes().subscribe();
            this._commonDataService.getStates().subscribe();
            this._commonDataService.getUnitOfMeasures().subscribe();
            this._commonDataService.getInsuranceTermsOfAgreement().subscribe();
            this._reportingService.getInvoiceReportGroupTypes();
            this._reportingService.getShipmentReportGroupTypes();
            this._reportingService.getShipmentReportDateTypes();
            this._reportingService.getInvoiceReportDateTypes();
        }, 100);
    }

    public ngOnDestroy() {
        this.appStateSubscription.unsubscribe();
        this.userSettingsSubscription.unsubscribe();
    }
    public hideAcceptTMA(): void {
        this.isTMAAcceptanceVisible = false;
    }

    public hideSiteInaccessibleModal(): void {
        this.isSiteInaccessibleModalVisible = false;
    }

    public closeShortcut(): void {

        if (!this.isFloaterActive) {
            return;
        }
        if (!this.isClipboardPinned) {
            this._signalsService.updateAppState({ 'floater.clipboardCollapsed': true });
        }
    }
}
