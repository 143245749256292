import {
    AfterViewInit,
    Component, ContentChild,
    ElementRef,
    EventEmitter, HostListener,
    Input,
    Output,
    QueryList,
    Renderer2, TemplateRef,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {TabInfo} from '../../models/tabInfo';

@Component({
    selector: 'app-tab-row',
    styleUrls: ['./app-tab-row.component.scss'],
    templateUrl: './app-tab-row.component.html',
    standalone: false
})
export class AppTabRowComponent implements AfterViewInit {
    @ViewChildren('tabElements')
    public tabElements: QueryList<ElementRef>;
    @ViewChild('downPointer')
    public downPointerElement: ElementRef;
    @ContentChild('contentRenderer', {static: false})
    contentRenderer: TemplateRef<any>;

    @Input()
    public grayTabs = false;
    @Input()
    public borderRadius = '15px';
    @Input()
    public useDownArrow = false;
    @Input()
    public useArrows = false;
    @Input()
    public roundAllEdges = false;
    @Input()
    get activeTab() {
        return this._activeTab;
    }
    set activeTab(val) {
        this._activeTab = val;
        this.setPointerPosition(val);
    }
    @Input()
    public tabs: Array<TabInfo> = new Array<TabInfo>();

    @Output()
    public activatedTab = new EventEmitter<any>();

    public pointerLeftPos;

    private _activeTab: number = 0;

    constructor(private renderer: Renderer2) {
    }

    @HostListener('keydown.arrowright', ['$event']) rightArrow(e) {
        this.advanceTab(e);
    }

    @HostListener('keydown.arrowleft', ['$event']) leftArrow(e) {
        this.reverseTab(e);
    }

    @HostListener('keydown.space', ['$event']) advanceTab(e) {
        e.preventDefault();
        if ((this.activeTab + 1) >= this.tabs.length) {
            this.setActiveTab(0);
        } else {
            this.setActiveTab(this.activeTab + 1);
        }
    }

    @HostListener('keydown.backspace', ['$event']) reverseTab(e) {
        e.preventDefault();
        if ((this.activeTab - 1) < 0) {
            this.setActiveTab(this.tabs.length - 1);
        } else {
            this.setActiveTab(this.activeTab - 1);
        }
    }

    public ngAfterViewInit() {
        this.setPointerSize();
        setTimeout(() => this.setPointerPosition(this.activeTab), 1);
    }

    public setActiveTab(i: number) {
        this.setPointerPosition(i);

        if (this.activeTab === i) {
            return;
        }
        this.activeTab = i;
        this.activatedTab.emit({index: i, tab: this.tabs[i]});
    }

    public getIconClass(icon: string, iconClass: string, outerIcon: boolean) {
        const classObj: any = {
            fa: true,
        };

        if (outerIcon) {
            classObj['fa-stack-2x'] = true;
            classObj['currentOuterIcon'] = true;
        } else {
            classObj['fa-stack-1x'] = true;
        }

        if (icon) {
            classObj[icon] = true;
        }

        if (iconClass) {
            classObj[iconClass] = true;
        }

        return classObj;
    }

    public getContentClass(tab, i) {
        const classObj: any = {
            current: this.activeTab === i,
            disabled: tab.inactive
        }
        if (this.useArrows) {
            classObj.arrowContent = true;
        } else {
            if (this.grayTabs) {
                classObj.normalContentGray = true;
            } else {
                classObj.normalContent = true;
            }
        }

        return classObj;
    }

    private setPointerPosition(idx) {
        if (this.useDownArrow && this.tabElements) {
            const firstElemPos = this.tabElements.get(0).nativeElement.offsetLeft;
            const tabElem = this.tabElements.get(idx);
            const pos = tabElem.nativeElement.offsetLeft;
            const width = tabElem.nativeElement.offsetWidth;
            const pointerWidth = this.downPointerElement.nativeElement.offsetWidth;

            this.pointerLeftPos = pos - pointerWidth/2 + width/2 - firstElemPos;
        }
    }

    private setPointerSize() {
        if (this.useDownArrow) {
            const tabElem = this.tabElements.get(0);
            const height = tabElem.nativeElement.offsetHeight;
            const downPointerElem = this.downPointerElement;
            this.renderer.setStyle(downPointerElem.nativeElement, 'border-left-width', height/2 + 'px');
            this.renderer.setStyle(downPointerElem.nativeElement, 'border-right-width', height/2 + 'px');
            this.renderer.setStyle(downPointerElem.nativeElement, 'border-top-width', height/2 + 'px');
        }
    }
}
