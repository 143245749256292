import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import {LogoutService} from "../../../services/logout.service";

@Component({
    selector: 'app-site-inaccessible-modal',
    styleUrls: ['./site-inaccessible-modal.component.scss'],
    templateUrl: './site-inaccessible-modal.component.html',
    standalone: false
})
export class SiteInaccessibleModalComponent implements OnInit {
    @Output() public closeSiteInaccessibleModal = new EventEmitter();

    constructor(
        private _logoutService: LogoutService,
    ) { }

    ngOnInit() {

    }

    public closeModal() {
        this.closeSiteInaccessibleModal.emit('');
    }

    public logout(): void {

        this._logoutService.logout();
        this.closeModal();
    }
}
