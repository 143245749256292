import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as Constants from '../../constants/constants';
import { Breadcrumb } from '../../models/breadCrumb';
import {BreadcrumbService} from "../../services/breadcrumb.service";

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    standalone: false
})

export class AppBreadcrumbsComponent implements OnInit, OnDestroy {
    public breadcrumbValues: Array<Breadcrumb> = [];
    public navigateHome: string = Constants.NAVIGATE_home;

    constructor(
        private _router: Router,
        private _breadcrumbService: BreadcrumbService
    ) { }

    public ngOnInit() {
        this.breadcrumbValues = this._breadcrumbService.breadcrumbs;
    }

    public ngOnDestroy() {
        this._breadcrumbService.clearBreadcrumbs();
    }

    public navigateToBreadcrumb(indexValue: number): void {
        if (!this.breadcrumbValues[indexValue].urlValue) {
            return;
        }

        let urlValue = this.breadcrumbValues[indexValue].urlValue;

        if (urlValue === this.navigateHome) {
            urlValue = '';
        }

        this._breadcrumbService.setBreadcrumbs(this.breadcrumbValues.slice(0,
            this.findFirstNonEqualUrlIdx(this.breadcrumbValues, indexValue)));

        this._router.navigate([`${urlValue}`]);
    }

    private findFirstNonEqualUrlIdx(breadcrumbs: Array<Breadcrumb>, index: number) {
        let i;

        for (i = index; i < breadcrumbs.length; i++) {
            if (breadcrumbs[i].urlValue !== breadcrumbs[index].urlValue) {
                break;
            }
        }

        return i;
    }
}
