import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-ok-cancel-modal',
    templateUrl: './ok-cancel-modal.component.html',
    styleUrls: ['./ok-cancel-modal.component.scss'],
    standalone: false
})

export class OkCancelModalComponent implements OnInit {
    @Input() public title: string;
    @Input() public message: string;
    @Input() public additionalMessage: string;
    @Input() public okPayload: any;
    @Input() public okayBtnText: string = 'Okay';
    @Input() public cancelBtnText: string = 'Cancel';
    @Input() public suppressCancelButton: boolean;
    @Output() public onOk = new EventEmitter();
    @Output() public onCancel = new EventEmitter();

    constructor() { }

    ngOnInit() {
        // console.log(`OkCancelModalComponent.Init() okPayload => ${JSON.stringify(this.okPayload)}`);
    }

    public ok() {
        this.onOk.emit(this.okPayload);
    }

    public cancel() {
        this.onCancel.emit('');
    }
}
