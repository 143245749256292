import {Component, Input} from '@angular/core';
import {Shipment} from '../../models/shipment';
import {PhoneNumber, Terminal} from '../../models/unifiedShipmentObject';
import {ShipmentAddressSelection} from './shipment-address.selection';

@Component({
    selector: 'app-address',
    styleUrls: ['./app-address.component.scss'],
    templateUrl: './app-address.component.html',
    standalone: false
})
export class AppAddressComponent {
    @Input()
    public terminal: Terminal;
    @Input()
    public rateTerminal: any;
    @Input()
    public shipment: Shipment;
    @Input()
    public shipmentSelection;
    @Input()
    public showEmail = false;
    @Input()
    public showPhones = true;
    @Input()
    public showName = true;

    constructor() {}

    public getName() {
        if (!this.showName) {
            return;
        }

        if (this.terminal) {
            return this.terminal.name;
        }
        if (this.rateTerminal) {
            return this.rateTerminal.name;
        }

        switch (this.shipmentSelection) {
            case (ShipmentAddressSelection.shipper):
                return this.shipment.Shipper_Name;
            case (ShipmentAddressSelection.consignee):
                return this.shipment.Consignee_Name;
            case (ShipmentAddressSelection.billTo):
                return this.shipment.billtoName;
            case (ShipmentAddressSelection.originTerminal):
                return this.shipment.otermName;
            case (ShipmentAddressSelection.destinationTerminal):
                return this.shipment.dtermName;
        }
    }

    public getAddressLines() {
        if (this.terminal) {
            return this.terminal.address.addressLines;
        }

        if (this.rateTerminal) {
            return [this.rateTerminal.address, this.rateTerminal.address1];
        }

        switch (this.shipmentSelection) {
            case (ShipmentAddressSelection.shipper):
                return [this.shipment.shipper_address, this.shipment.shipper_address2];
            case (ShipmentAddressSelection.consignee):
                return [this.shipment.consignee_address, this.shipment.consignee_address2];
            case (ShipmentAddressSelection.billTo):
                return [this.shipment.billtoAddress1, this.shipment.billtoAddress2];
            case (ShipmentAddressSelection.originTerminal):
                return [this.shipment.otermAddress, this.shipment.otermAddress2];
            case (ShipmentAddressSelection.destinationTerminal):
                return [this.shipment.dtermAddress, this.shipment.dtermAddress2];
        }
    }

    public getCity() {
        if (this.terminal) {
            return this.terminal.address.city;
        }

        if (this.rateTerminal) {
            return this.rateTerminal.city;
        }

        switch (this.shipmentSelection) {
            case (ShipmentAddressSelection.shipper):
                return this.shipment.shipper_city;
            case (ShipmentAddressSelection.consignee):
                return this.shipment.consignee_city;
            case (ShipmentAddressSelection.billTo):
                return this.shipment.billtoCity;
            case (ShipmentAddressSelection.originTerminal):
                return this.shipment.otermCity;
            case (ShipmentAddressSelection.destinationTerminal):
                return this.shipment.dtermCity;
        }
    }

    public getState() {
        if (this.terminal) {
            return this.terminal.address.state;
        }

        if (this.rateTerminal) {
            return this.rateTerminal.state;
        }

        switch (this.shipmentSelection) {
            case (ShipmentAddressSelection.shipper):
                return this.shipment.shipper_state;
            case (ShipmentAddressSelection.consignee):
                return this.shipment.consignee_state;
            case (ShipmentAddressSelection.billTo):
                return this.shipment.billtoState;
            case (ShipmentAddressSelection.originTerminal):
                return this.shipment.otermState;
            case (ShipmentAddressSelection.destinationTerminal):
                return this.shipment.dtermState;
        }
    }

    public getPostalCode() {
        if (this.terminal) {
            return this.terminal.address.postalCode;
        }

        if (this.rateTerminal) {
            return this.rateTerminal.zip;
        }

        switch (this.shipmentSelection) {
            case (ShipmentAddressSelection.shipper):
                return this.shipment.shipper_zip;
            case (ShipmentAddressSelection.consignee):
                return this.shipment.consignee_zip;
            case (ShipmentAddressSelection.billTo):
                return this.shipment.billtoZip;
            case (ShipmentAddressSelection.originTerminal):
                return this.shipment.otermZip;
            case (ShipmentAddressSelection.destinationTerminal):
                return this.shipment.dtermZip;
        }
    }

    public getPhoneWork() {
        if (!this.showPhones) {
            return;
        }

        if (this.terminal) {
            return this.getPhoneText(this.terminal.contact.phoneWork);
        }

        if (this.rateTerminal) {
            return this.rateTerminal.phone;
        }

        switch (this.shipmentSelection) {
            case (ShipmentAddressSelection.shipper):
                return this.shipment.shipper_contact_phone;
            case (ShipmentAddressSelection.consignee):
                return this.shipment.consignee_contact_phone;
            case (ShipmentAddressSelection.originTerminal):
                return this.shipment.otermPhone;
            case (ShipmentAddressSelection.destinationTerminal):
                return this.shipment.dtermPhone;
        }
    }

    public getPhoneMobile() {
        if (!this.showPhones) {
            return;
        }

        if (this.terminal) {
            return this.getPhoneText(this.terminal.contact.phoneMobile);
        }
    }

    public getPhoneFax() {
        if (!this.showPhones) {
            return;
        }

        if (this.terminal) {
            return this.getPhoneText(this.terminal.contact.fax);
        }

        if (this.rateTerminal) {
            return this.rateTerminal.fax;
        }

        switch (this.shipmentSelection) {
            case (ShipmentAddressSelection.shipper):
                return this.shipment.shipper_contact_fax;
            case (ShipmentAddressSelection.consignee):
                return this.shipment.consignee_contact_fax;
            case (ShipmentAddressSelection.originTerminal):
                return this.shipment.otermFax;
            case (ShipmentAddressSelection.destinationTerminal):
                return this.shipment.dtermFax;
        }
    }

    public getPhone800() {
        if (!this.showPhones) {
            return;
        }

        if (this.rateTerminal) {
            return this.rateTerminal.phone800
        }

        switch (this.shipmentSelection) {
            case (ShipmentAddressSelection.originTerminal):
                return this.shipment.oterm800;
            case (ShipmentAddressSelection.destinationTerminal):
                return this.shipment.dterm800;
        }
    }

    public getEmail() {
        if (!this.showEmail) {
            return;
        }

        if (this.terminal) {
            return this.terminal.contact.email;
        }

        switch (this.shipmentSelection) {
            case (ShipmentAddressSelection.shipper):
                return this.shipment.shipper_contact_email;
            case (ShipmentAddressSelection.consignee):
                return this.shipment.consignee_contact_email;
        }
    }

    private getPhoneText(phone: PhoneNumber) {
        let phoneText = '';

        if (!phone) {
            return phoneText;
        }
        if (phone.countryCode) {
            phoneText += `+${phone.countryCode} `;
        }
        if (phone.areaCode) {
            phoneText += `(${phone.areaCode}) `;
        }
        if (phone.phoneNumber) {
            phoneText += `${phone.phoneNumber}`;
        }
        if (phone.extension) {
            phoneText += ` ext: ${phone.extension}`;
        }
        return phoneText;
    }
}
