import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import * as MasterDataConstants from '../../../../constants/masterData.constants';
import { UserHelper } from '../../../../helpers/userHelper';

@Component({
    selector: 'app-admin-role-tabs',
    templateUrl: './admin-role-tabs.component.html',
    styleUrls: ['./admin-role-tabs.component.scss'],
    standalone: false
})
export class AdminRoleTabsComponent implements OnInit {
    @Output() public selectedAminRoleTab = new EventEmitter();

    public roleTabSections: {
        general: string,
        permissions: string,
        quoting: string,
        shipments: string
        defaultsAndDisclaimer: string
    } = MasterDataConstants.ROLE_TAB_SECTIONS;
    public activeRoleTabSection: string = this.roleTabSections.general;
    public isAdminInternal: boolean;
    public isAdminExternal: boolean;

    constructor(
        private _userHelper: UserHelper,
    ) {
    }

    ngOnInit(): void {
        this.isAdminInternal = this._userHelper.isAdminInternal();
        this.isAdminExternal = this._userHelper.isAdminExternal();
    }

    public setActiveRoleTabSection(section: string): void {
        this.activeRoleTabSection = section;
        this.selectedAminRoleTab.emit(this.activeRoleTabSection);
    }
}
