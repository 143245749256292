import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { filterList } from '../../../helpers/utilities';
import { unsubscribe } from '../../../helpers/utilities';

import * as AdminConstants from '../../../constants/admin.constants';
import { MarkupMasterData } from '../../../models/markup.masterData';
import {MarkupsService} from '../../../services/markups.service';
import {BreadcrumbService} from "../../../services/breadcrumb.service";

@Component({
    selector: 'app-admin-markup-list',
    styleUrls: ['./admin-markup-list.component.scss'],
    templateUrl: './admin-markup-list.component.html',
    standalone: false
})
export class AdminMarkupListComponent implements OnInit, OnDestroy {
    public sectionName = AdminConstants.ADMIN_SECTION_PATHS.markups;

    public masterDataMarkups: Array<MarkupMasterData>;
    public filteredMarkups: Array<MarkupMasterData> = [];
    public markupListForm: UntypedFormGroup;
    public selectedFilterField: string;
    public dropdownFilterFields: Array<{ displayText: string, filterField: string }> = [
        { displayText: 'All', filterField: 'all' },
        { displayText: 'Name', filterField: 'markupName' },
        { displayText: 'ID', filterField: 'markupID' },
        { displayText: 'Description', filterField: 'markupDescription' },
    ];

    private markupListValueChangesSubscription: any;

    constructor(
        private _fb: UntypedFormBuilder,
        private _router: Router,
        private _breadcrumbService: BreadcrumbService,
        private _markupsService: MarkupsService,
    ) { }

    public ngOnInit() {
        this.filteredMarkups = this.masterDataMarkups = this._markupsService.markups;

        this.markupListForm = this._fb.group({
            filterTerm: ''
        });

        this.markupListValueChangesSubscription = this.markupListForm.valueChanges.subscribe(val => {
            this.filterMarkups();
        });

        this.selectedFilterField = this.dropdownFilterFields[0].filterField;

        this._breadcrumbService.addBreadcrumb([
            {
                label: 'Markups',
                urlValue: 'admin/markups'
            }
        ]);
    }

    public ngOnDestroy() {
        unsubscribe(this.markupListValueChangesSubscription);
    }

    public filterMarkups(): void {
        const filterTerm = this.markupListForm.get('filterTerm').value;

        this.filteredMarkups = filterList(this.masterDataMarkups, this.dropdownFilterFields, this.selectedFilterField, filterTerm);
    }

    public navigateToMarkupShowPage(markup: MarkupMasterData): void {
        this._router.navigate(['admin/markups', markup.markupID]);
    }

    public navigateToCreateMarkupPage(): void {
        this._router.navigate(['admin/markups/new']);
    }
}
