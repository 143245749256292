import {Component, Input, OnChanges} from '@angular/core';
import * as _ from 'lodash-es';
import {AccessorialForShipment} from '../../models/accessorialForShipment';
import {QuotedRate} from '../../models/quotedRate';
import {ShipmentUnified} from '../../models/shipmentUnified';
import {Accessorial, RateBreakdown, USO} from '../../models/unifiedShipmentObject';
import {QuickQuoteService} from '../../services/quickQuote.service';
import {clearArray} from '../../helpers/utilities';

class AccessorialDetailInfo {
    name: string;
    value: number;
}

@Component({
    selector: 'app-shipping-costs',
    styleUrls: ['./app-shipping-costs.component.scss'],
    templateUrl: './app-shipping-costs.component.html',
    standalone: false
})
export class AppShippingCostsComponent implements OnChanges {
    @Input()
    public uso: USO;
    @Input()
    public shipmentUnified: ShipmentUnified;
    @Input()
    public selectedRate: QuotedRate;
    @Input()
    public currency = '';
    @Input()
    public showDetails = false;
    @Input()
    public insuranceCost = 0;
    public lineHaulCost = 0;
    public fuelCost = 0;
    public accesorials = 0;
    public total = 0;
    public accessorialDetails = [];

    constructor(private _quickQuoteService: QuickQuoteService) {}

    public ngOnChanges() {
        let rate: QuotedRate;

        if (this.uso) {
            const result: any = this._quickQuoteService.loadCosts(this.uso.bookingSelection);

            this.total = result.total + this.insuranceCost;
            this.currency = result.currency;
            this.accesorials = result.extra;
            this.fuelCost = result.fuel;
            this.lineHaulCost = result.lineHaul;
            if (this.showDetails) {
                this.loadUSOAccessorialCosts();
            }
        } else if (this.shipmentUnified) {
            this.total = this.shipmentUnified.Main.rated_amt;
            this.fuelCost = this.shipmentUnified.Main.FSC;
            this.accesorials = this.loadShipmentUnifiedAccessorialCosts(this.shipmentUnified, this.showDetails);
            this.lineHaulCost = this.total - this.fuelCost - this.accesorials - this.insuranceCost;
            this.currency = this.shipmentUnified.Main.currency;
        } else if (this.selectedRate) {
            rate = this.selectedRate;
            this.total = rate.finalcharge + this.insuranceCost;
            this.fuelCost = rate.fsc;
            this.accesorials = rate.asc.total;
            this.lineHaulCost = this.total - this.fuelCost - this.accesorials - this.insuranceCost;
            if (this.showDetails) {
                this.loadRateAccessorialCosts(rate.asc.charges);
            }
        }
    }

    private loadUSOAccessorialCosts() {
        let acc: Accessorial;
        let rateBreakdown: RateBreakdown;
        clearArray(this.accessorialDetails);
        if (this.uso?.bookingSelection?.rateDetails[0]?.rates[0]?.rateBreakdown) {
            for (acc of this.uso.accessorials) {
                rateBreakdown = _.find(this.uso.bookingSelection.rateDetails[0].rates[0].rateBreakdown, (o: RateBreakdown) => o.code === acc.code);
                if (rateBreakdown) {
                    this.accessorialDetails.push({
                        name: rateBreakdown.name,
                        value: rateBreakdown.rate.amount
                    });
                }
            }
        }
    }

    private loadRateAccessorialCosts(accessorials) {
        let acc;
        clearArray(this.accessorialDetails);
        if (accessorials) {
            for (acc of accessorials) {
                this.accessorialDetails.push({
                    name: acc.name,
                    value: acc.amount
                });
            }
        }
    }

    private loadShipmentUnifiedAccessorialCosts(shipmentUnified: ShipmentUnified, showDetails) {
        const accessorials = shipmentUnified.Accessorial;
        let acc: AccessorialForShipment;
        let total = 0;

        if (showDetails) {
            clearArray(this.accessorialDetails);
        }

        if (accessorials) {
            for (acc of accessorials) {
                total += acc.amount;
                if (showDetails) {
                    this.accessorialDetails.push({
                        name: acc.displayName,
                        value: acc.amount
                    });
                }
            }
        }

        return total;
    }
}
