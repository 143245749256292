// **RTG** This component is not really used in the app, best to consider removing when a larger refactoring of the
//         reports is done.  Component does nothing and has no routing so should be safe to remove.
// Edit:   This does seem to be accessible via global search and clicking on an invoice that shows up
//         so while I still do not think it is used by anyone, I have left the route and component in.

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-reports',
    styleUrls: ['./reports.component.scss'],
    templateUrl: './reports.component.html',
    standalone: false
})
export class ReportsComponent {

  constructor() { }
}
