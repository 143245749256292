import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import * as _ from 'lodash-es';
import {makeClone} from '../../../helpers/utilities';
import {AccessorialForQuote} from '../../../models/accessorialForQuote';
import {SignalsService} from "../../../services/signals.service";
import {CommonDataService} from "../../../services/commonData.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-accessorial-selector-modal',
    styleUrls: ['./accessorial-selector-modal.component.scss'],
    templateUrl: './accessorial-selector-modal.component.html',
    standalone: false
})
export class AccessorialSelectorModalComponent implements OnInit, AfterViewInit {
    @Output()
    public addAccessorialsToRecord = new EventEmitter();
    @Output()
    public canceled = new EventEmitter();
    @Input()
    public currentAccesorials: Array<AccessorialForQuote> = [];
    @Input()
    public okayButton = 'Add to Record';
    @Input()
    public allowSameAccessorialsGiven = false;
    @Input()
    public shownAccessorials: Array<AccessorialForQuote>;

    @ViewChild('queryField', { static: true }) queryField;

    public accessorials: Array<AccessorialForQuote>;
    public checkedAccessorials: Array<AccessorialForQuote> = [];
    public checkAll: boolean = false;
    public filteredAccessorials: Array<AccessorialForQuote> = [];
    public accessorialSelectorForm: UntypedFormGroup;
    private requiredValuesPresent: boolean = false;

    constructor(
        private _signalsService: SignalsService,
        private _notificationService: NotificationService,
        private _fb: UntypedFormBuilder,
        private _commonDataService: CommonDataService,
    ) {
    }

    public ngAfterViewInit() {
        this.queryField.nativeElement.focus();
    }

    public async ngOnInit() {
        this.accessorialSelectorForm = this._fb.group({
            searchTerm: ''
        });
        this.accessorialSelectorForm.valueChanges.subscribe(values => {
            this.updateFilteredValues();
        });

        if (this.shownAccessorials) {
            this.accessorials = makeClone(this.shownAccessorials);
        } else {
            this.accessorials = makeClone(await this._commonDataService.loadedPromise(this._commonDataService.availableAccessorials));
        }
        this.filteredAccessorials = this.accessorials;

        this.currentAccesorials.forEach((currentAcc: AccessorialForQuote) => {
            const matchedAccessorial = this.filteredAccessorials.find((filteredAcc: AccessorialForQuote) => filteredAcc.code === currentAcc.code);
            if (matchedAccessorial) {
                this.selectAccessorial(matchedAccessorial);
                if (currentAcc.value && matchedAccessorial.valueRequired) {
                    const addedAccessorial = this.checkedAccessorials[this.checkedAccessorials.length - 1];
                    addedAccessorial.accessorialValue = currentAcc.value;
                }
            }
        });
    }

    public closeModal() {
        this._signalsService.updateAppState({'modal.isAccessorialSelectorShown': false});
    }

    public selectAll() {
        this.checkedAccessorials = [];
        this.filteredAccessorials.forEach(acc => {
            this.selectAccessorial(acc);
        });
        this.checkAll = true;
    }

    public unselectAll() {
        this.checkedAccessorials = [];
        this.checkAll = false;
    }

    public selectAccessorial(accessorial: AccessorialForQuote): void {
        if (!this.checkedAccessorials || !accessorial) {
            return;
        }

        if (!_.find(this.checkedAccessorials, (acc) => acc.code === accessorial.code)) {
            this.checkedAccessorials.push(accessorial);
        }
    }

    public unselectAccessorial(accessorialIndex): void {
        if (!this.checkedAccessorials || !this.checkedAccessorials.length) {
            return;
        }

        this.checkedAccessorials.splice(accessorialIndex, 1);
    }

    public toggleAccessorialSelection(accessorial: AccessorialForQuote): void {
        let isAccessorialSelected: boolean;
        let selectedAccessorialIndex: number;

        this.checkedAccessorials.forEach((checkedAcc: AccessorialForQuote, checkedAccIndex: number) => {
            if (checkedAcc === accessorial) {
                isAccessorialSelected = true;
                selectedAccessorialIndex = checkedAccIndex;
                this.unselectAccessorial(selectedAccessorialIndex);

                return;
            }
        });

        if (!isAccessorialSelected) {
            this.selectAccessorial(accessorial);
        }
    }

    public updateFilteredValues(): void {
        const accessorialSearchTerm = this.accessorialSelectorForm.get('searchTerm').value;
        const searchPattern = new RegExp(accessorialSearchTerm, 'i');
        this.filteredAccessorials = this.accessorials.filter((acc: AccessorialForQuote) => acc.displayName.match(searchPattern));
    }

    public passToRecord(): void {
        this.requiredValuesPresent = true;

        this.checkedAccessorials.forEach((acc: AccessorialForQuote) => {
            if (acc.valueRequired && !acc.accessorialValue) {
                this.requiredValuesPresent = false;
                this._notificationService.notifyError({
                    title: 'Accessorial Value Required',
                    message: `Please provide a value for ${acc.displayName}`
                });
            }
            if (acc.valueRequired && acc.accessorialValue) {
                acc.accessorialValue = +acc.accessorialValue; // type to number from ngModeled string input field
            }
        });

        if (this.allowSameAccessorialsGiven) {
            if (this.requiredValuesPresent) {
                this.addAccessorialsToRecord.emit(this.checkedAccessorials);
                this.closeModal();
            }
            return;
        }

        if (this.checkedAccessorials.length === 0) {
            this._notificationService.notifyError({
                title: 'Add Accessorial',
                message: 'Please select Accessorial before clicking the Add button'
            });
        } else if (this.requiredValuesPresent) {
            if (!this.checkIfAccessorialsAreDifferent()) {
                this.closeModal();
                return;
            }
            this.addAccessorialsToRecord.emit(this.checkedAccessorials);
            this.closeModal();
        }
    }

    public cancelModal() {
        this.closeModal();
        this.canceled.emit();
    }

    private checkIfAccessorialsAreDifferent(): boolean {
        const sortedCurrentAccessorials = this.currentAccesorials.map((currentAcc: AccessorialForQuote): { code: string, value?: number } => {
            const acc: { code: string, value?: number } = {
                code: currentAcc.code
            };

            if (currentAcc.value) {
                acc.value = currentAcc.value;
            }

            return acc;
        }).sort((accA, accB) => {
            let sortComparison = 0;
            if (accA.code > accB.code) {
                sortComparison = 1;
            } else if (accA.code < accB.code) {
                sortComparison = -1;
            }

            return sortComparison;
        });

        const sortedCheckedAccessorials = this.checkedAccessorials.map((checkedAcc: AccessorialForQuote): { code: string, value?: number } => {
            const acc: { code: string, value?: number } = {
                code: checkedAcc.code
            };

            if (checkedAcc.accessorialValue) {
                acc.value = checkedAcc.accessorialValue;
            }

            return acc;
        }).sort((accA, accB) => {
            let sortComparison = 0;
            if (accA.code > accB.code) {
                sortComparison = 1;
            } else if (accA.code < accB.code) {
                sortComparison = -1;
            }

            return sortComparison;
        });

        if (sortedCheckedAccessorials.length !== sortedCurrentAccessorials.length) {
            return true;
        }

        for (let i = 0; i < sortedCurrentAccessorials.length; i++) {
            const currentAcc = sortedCurrentAccessorials[i];
            const checkedAcc = sortedCheckedAccessorials[i];

            if ((currentAcc.code !== checkedAcc.code) || (currentAcc.value !== checkedAcc.value)) {
                return true;
            }
        }

        return false;
    }
}
