import {
    Component, ContentChild,
    EventEmitter,
    Input,
    Output, TemplateRef,
} from '@angular/core';
import {MenuWithSubmenu} from '../../models/menuWithSubmenu';

@Component({
    selector: 'app-horizontal-menu',
    styleUrls: ['./app-horizontal-menu.component.scss'],
    templateUrl: './app-horizontal-menu.component.html',
    standalone: false
})
export class AppHorizontalMenuComponent {
    @Input()
    public menu: Array<MenuWithSubmenu> = [];
    @Output()
    public click: EventEmitter<MenuWithSubmenu> = new EventEmitter();
    @ContentChild('menuItemRenderer', {static: false})
    menuItemRenderer: TemplateRef<any>;
    @ContentChild('subMenuItemRenderer', {static: false})
    subMenuItemRenderer: TemplateRef<any>;

    constructor() {
    }

    public clicked(menuItem: MenuWithSubmenu) {
        this.click.emit(menuItem);
    }
}
