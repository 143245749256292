import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-mocking-ribbon',
    styleUrls: ['./mocking-ribbon.component.scss'],
    templateUrl: './mocking-ribbon.component.html',
    standalone: false
})
export class MockingRibbonComponent {

    public isMocking: boolean;
    public opacity: number;

    constructor() {
        this.isMocking = environment.usingMocking;
        this.opacity = this.getOpacityValue();
    }

    private getOpacityValue(): number {
        const possibleMocks = 7;
        let actualMocks = 0;
        if (environment.useMocking.clipboard) { actualMocks += 1; }
        if (environment.useMocking.dashboard) { actualMocks += 1; }
        if (environment.useMocking.masterData) { actualMocks += 1; }
        if (environment.useMocking.quote) { actualMocks += 1; }
        if (environment.useMocking.record) { actualMocks += 1; }
        if (environment.useMocking.search) { actualMocks += 1; }
        if (environment.useMocking.user) { actualMocks += 1; }

        return actualMocks / possibleMocks;
    }

}
