import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { CustomerMasterData } from '../../../models/customer.masterData';

@Component({
    selector: 'app-roles-customer-modal',
    templateUrl: './roles-customer-search-modal.component.html',
    styleUrls: ['./roles-customer-search-modal.component.scss'],
    standalone: false
})

export class RolesCustomerSearchModalComponent implements OnInit, AfterViewInit {
    @Output() closeCustomerRolesModal: EventEmitter<any> = new EventEmitter();
    @Input() adminRolesCustomersList: Array<CustomerMasterData>;
    @Output() selectCustomer: EventEmitter<CustomerMasterData> = new EventEmitter();
    @ViewChild('queryField', { static: true }) queryField;

    public customer: CustomerMasterData;
    public roleCustomerListForm: UntypedFormGroup;
    public displayedCustomerList: Array<CustomerMasterData>;
    constructor(
        private _fb: UntypedFormBuilder,
    ) { }

    public ngAfterViewInit() {
        this.queryField.nativeElement.focus();
    }

    public ngOnInit() {
        this.roleCustomerListForm = this._fb.group({
            customerSearchTerm: '',
        });

        this.roleCustomerListForm.valueChanges.subscribe(values => {
            this.filterValues();
        });

        this.displayedCustomerList = this.adminRolesCustomersList;
    }

    public closeModal(): void {
        this.closeCustomerRolesModal.emit(null);
    }

    public selectFilteredCustomer(customer: CustomerMasterData): void {
        this.selectCustomer.emit(customer);
        this.closeModal();
    }

    public filterValues(): void {
        const customerListSearchTerm = this.roleCustomerListForm.get('customerSearchTerm').value;
        if (customerListSearchTerm) {
            this.displayedCustomerList = [];
            const searchPattern = new RegExp(customerListSearchTerm, 'i');

            this.displayedCustomerList = this.adminRolesCustomersList.filter((customer: CustomerMasterData) => customer.custName.match(searchPattern) || customer.custNo.match(searchPattern));
        } else {
            this.displayedCustomerList = this.adminRolesCustomersList;
        }
    }
}
