import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import * as Constants from '../../../constants/constants';
import * as DashboardConstants from '../../../constants/dashboard.constants';
import { DashboardHelper } from '../../../helpers/dashboardHelper';
import { Dashboard } from '../../../models/dashboard';
import { DashboardView } from '../../../models/dashboardView';
import {NotificationService} from "../../../services/notification.service";
import {DashboardService} from "../../../services/dashboard.service";

@Component({
    selector: 'app-dashboard-view-library-modal',
    templateUrl: './dashboard-view-library-modal.component.html',
    styleUrls: ['./dashboard-view-library-modal.component.scss'],
    standalone: false
})
export class DashboardViewLibraryModalComponent implements OnInit {
  @Input() public dashboardViewLibrary: Array<DashboardView>;
  @Input() public dashboardId: number;
  @Input() public activeDashboard: Dashboard;
  @Input() public activeDashboardView: DashboardView;
  @Output() public closeViewLibraryModal = new EventEmitter();
  @Output() public createView = new EventEmitter();

  public viewCategories: Array<any> = [];
  private remainingAvailableViews: number;
  public contentQueryViewType: string = Constants.DashboardViewType_CONTENT_QUERY;
  public templateQueryViewType: string = Constants.DashboardViewType_TEMPLATE_QUERY;
  public dashboardViewCategories: {
    user_created: string,
    global_views: string,
    record_management: string,
    quote_management: string
  } = DashboardConstants.DASHBOARD_VIEW_CATEGORY_NAMES;

  constructor(
    private _router: Router,
    private _dashboardService: DashboardService,
    private _notificationService: NotificationService,
    private _dashboardHelper: DashboardHelper,
  ) { }

  ngOnInit() {
    if (this.activeDashboard && this.activeDashboard.views && this.activeDashboard.views.length) {
      this.remainingAvailableViews = Constants.MAXIMUM_ALLOWABLE_DASHBOARD_VIEWS - this.activeDashboard.views.length;
      const globalSearchIndex = this.activeDashboard.views.findIndex((view: DashboardView) => view.viewType === Constants.DashboardViewType_GLOBAL_SEARCH);

      this.dashboardViewLibrary.forEach((view, index) => {
        if (globalSearchIndex !== -1 && view.viewType === Constants.DashboardViewType_GLOBAL_SEARCH) {
          return;
        }
        if (view.viewType === Constants.DashboardViewType_CREATE_VIEW) {
          return;
        }

        const categoryIndex = this.viewCategories.findIndex(category => category.name === view.category);
        if (categoryIndex === -1) {
          this.viewCategories.push({
            name: view.category,
            associatedViews: [{
              selectable: true,
              selected: false,
              view: view
            }]
          });
        } else {
          this.viewCategories[categoryIndex].associatedViews.push({
            selectable: true,
            selected: false,
            view: view
          });
        }
      });

      if (this.viewCategories.length) {
        const myViewIndex = this.viewCategories.findIndex(category => category.name === Constants.DashboardView_USER_CREATED);
        if (myViewIndex !== 0 && myViewIndex !== -1) {
          const myViews = this.viewCategories[myViewIndex];
          this.viewCategories.splice(myViewIndex, 1);
          this.viewCategories.unshift(myViews);
        }
      }
    }
  }

  public closeModal() {
    this.closeViewLibraryModal.emit('');
  }

  public checkIfAllSelected(category) {
    let checked = true;
    category.associatedViews.forEach(v => {
      if (!v.selected) {
        checked = false;
      }
    });
    return checked;
  }

  public toggleCheck(viewType) {
    if (viewType.selected) {
      this.remainingAvailableViews++;
    } else {
      this.remainingAvailableViews--;
    }
    viewType.selected = !viewType.selected;

    if (this.remainingAvailableViews === 0) {
      this.viewCategories.forEach((category) => {
        category.associatedViews.forEach((libraryView: { view: DashboardView, selectable: boolean, selected: boolean }) => {
          libraryView.selectable = libraryView.selected ? true : false;
        });
      });
    } else if (this.remainingAvailableViews === 1) {
      this.viewCategories.forEach((category) => {
        category.associatedViews.forEach((libraryView: { view: DashboardView, selectable: boolean, selected: boolean }) => {
          libraryView.selectable = true;
        });
      });
    }
  }

  public selectViewsByCategory(category) {
    category.associatedViews.forEach(v => {
      v.selected = true;
    });
  }

  public unselectViewsByCategory(category) {
    category.associatedViews.forEach(v => {
      v.selected = false;
    });
  }

  private prepareSelectedViews(): Array<DashboardView> {
    const selectedViews: Array<DashboardView> = [];
    this.viewCategories.forEach(category => {
      category.associatedViews.forEach(header => {
        if (header.selected && header.view.editable) {
          selectedViews.push(header.view);
        }
      });
    });
    return selectedViews;
  }

  public submit() {
    const selectedViews = this.prepareSelectedViews();
    const activeDashboard = this._dashboardHelper.getActiveDashboard();
    if (selectedViews.length === 0) {
      this._notificationService.notifyError({ title: `Dashboard View Library`, message: `Please select a view before clicking Create View` });
    } else {
      if (selectedViews.length > 1) {
        const sortedActiveDashboardViews = activeDashboard.views.slice().sort((firstView, secondView) => firstView.viewOrder - secondView.viewOrder);
        const globalSearchIndex = sortedActiveDashboardViews.findIndex((dbV: DashboardView) => dbV.viewType === Constants.DashboardViewType_GLOBAL_SEARCH);
        const createViewIndex = sortedActiveDashboardViews.findIndex((dBV: DashboardView) => dBV.viewType === Constants.DashboardViewType_CREATE_VIEW);
        let createdViewViewOrder = (globalSearchIndex !== -1 ? globalSearchIndex + 1 : createViewIndex + 1);
        selectedViews.forEach((view: DashboardView) => {
          view.viewOrder = createdViewViewOrder++;
          this._dashboardService.createDashboardView(view, this.dashboardId);
        });
        this._router.navigate(['dashboard']);
      } else if (selectedViews[0].viewType === 'globalSearch') {
        selectedViews[0].viewOrder = Constants.DashboardView_GLOBAL_SEARCH_VIEW_ORDER;
        this._dashboardService.createDashboardView(selectedViews[0], this.dashboardId);
        this._router.navigate(['dashboard']);
      } else {
        this.createView.emit(selectedViews[0]);
        this.closeModal();
      }
    }
  }

  public removeViewFromLibrary(): void {
    const selectedViews = this.prepareSelectedViews();
    if (selectedViews.length === 0) {
      this._notificationService.notifyError({ title: `Dashboard View Library`, message: `Please select a view before clicking Deleting View` });
      return;
    }
    let isRemovable = true;
    selectedViews.forEach((selectedView: DashboardView) => {
      if (selectedView.category !== Constants.DashboardView_USER_CREATED) {
        this._notificationService.notifyError({ title: 'Dashboard View Library', message: `You can only delete views in the My Views category, please unselect ${selectedView.name} and try again.` });
        isRemovable = false;
      }
    });
    if (isRemovable) {
      this._dashboardService.deleteViewFromLibrary(selectedViews);
      this.closeModal();
    }
  }

}
