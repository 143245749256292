import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn} from '@angular/forms';
import moment from 'moment';

import * as DateConstants from '../../../constants/datetime.constants';

@Component({
    selector: 'app-custom-datepicker',
    templateUrl: './customDatePicker.component.html',
    styleUrls: ['./customDatePicker.component.scss'],
    standalone: false
})
export class CustomDatePickerComponent implements OnInit, AfterViewInit {
    @Input() public isDateRangePossible: boolean;
    @Input() public isDateRangeOnly: boolean;
    @Input() public title: string;
    @Input() public inputDate: string;
    @Input() public inputEndDate: string;
    @Input() public dateFormatPattern: string;
    @Input() public dateFormatValidationPattern: RegExp;
    @Input() public allowSingleIndefinately: boolean;

    @Output() public closeDatePicker = new EventEmitter();

    public weekdayList: Array<string> = DateConstants.WEEKDAYS;
    public weeksInMonth: Array<Array<{ displayText: string, momentValue: moment.Moment, selected: boolean }>> = [];
    public dateForm: UntypedFormGroup;
    public selectedDate: string;
    public currentMonth: { displayText: string, momentValue: moment.Moment };
    @ViewChild('dateField') dateField;
    @ViewChild('rangeDateField') rangeDateField;

    public isDateRangeSelected: boolean = false;

    public activeDateRangeInputFormControl: UntypedFormControl = null;

    constructor(
        private _fb: UntypedFormBuilder,
    ) {
    }

    public ngAfterViewInit() {
        if (this.dateField) {
            this.dateField.nativeElement.focus();
        }
        if (this.rangeDateField) {
            this.rangeDateField.nativeElement.focus();
        }
    }

    public ngOnInit() {
        this.dateFormSetup();
        if (this.dateForm.value.selectedDate && (this.dateForm.value.selectedDate.toLowerCase() !== DateConstants.DATE_VALUE_INDEFINITELY_display.toLowerCase())) {
            this.setCurrentMonth(moment(this.dateForm.value.selectedDate, this.dateFormatPattern));
        } else {
            this.setCurrentMonth(null);
        }
    }

    // ===========================================================================
    // =========================== SETUP METHODS =================================
    // ===========================================================================

    private dateFormSetup(): void {
        if (this.isDateRangeOnly) {
            this.isDateRangeSelected = true;
            this.rangeDateFormSetup();
        } else if (!this.isDateRangeSelected) {
            this.singleDateFormSetup();
        } else {
            this.rangeDateFormSetup();
        }
    }

    private singleDateFormSetup(): void {
        this.activeDateRangeInputFormControl = null;
        if (this.inputDate) {
            this.dateForm = this._fb.group({
                selectedDate: new UntypedFormControl(this.inputDate, [
                    Validators.required,
                    (this.allowSingleIndefinately) ? this.endDatePatternValidator(this.dateFormatValidationPattern) : Validators.pattern(this.dateFormatValidationPattern ? this.dateFormatValidationPattern : DateConstants.VALID_DATE_REGEX)
                ])
            });
        } else {
            this.dateForm = this._fb.group({
                selectedDate: new UntypedFormControl('', [
                    Validators.required,
                    (this.allowSingleIndefinately) ? this.endDatePatternValidator(this.dateFormatValidationPattern) : Validators.pattern(this.dateFormatValidationPattern ? this.dateFormatValidationPattern : DateConstants.VALID_DATE_REGEX)
                ])
            });
        }
    }

    private rangeDateFormSetup(): void {
        const startDate: string = this.dateForm && this.dateForm.get('selectedDate').valid ? this.dateForm.get('selectedDate').value : this.inputDate;
        const endDate: string = this.inputEndDate || '';
        if (startDate) {
            this.dateForm = this._fb.group({
                selectedDate: new UntypedFormControl(startDate, [
                    Validators.required,
                    Validators.pattern(this.dateFormatValidationPattern ? this.dateFormatValidationPattern : DateConstants.VALID_DATE_REGEX)
                ]),
                selectedEndDate: new UntypedFormControl(endDate, [
                    Validators.required,
                    this.endDatePatternValidator(this.dateFormatValidationPattern),
                    this.compareStartEndDateValidator(this.dateFormatPattern)
                ])
            });
        } else {
            this.dateForm = this._fb.group({
                selectedDate: new UntypedFormControl('', [
                    Validators.required,
                    Validators.pattern(this.dateFormatValidationPattern ? this.dateFormatValidationPattern : DateConstants.VALID_DATE_REGEX)
                ]),
                selectedEndDate: new UntypedFormControl(endDate, [
                    Validators.required,
                    this.endDatePatternValidator(this.dateFormatValidationPattern),
                    this.compareStartEndDateValidator(this.dateFormatPattern)
                ])
            });
        }

        this.activeDateRangeInputFormControl = this.dateForm.get('selectedDate') as UntypedFormControl;
    }

    // ===========================================================================
    // ======================== CUSTOM VALIDATORS ==============================
    // ===========================================================================
    private compareStartEndDateValidator(dateFormatPattern: string): ValidatorFn {
        return function (selectedEndDate: UntypedFormControl): { afterStartDate: { valid: boolean } } { // returns null if valid returns form error object otherwise
            if (!selectedEndDate.root || !selectedEndDate.root['controls']) {
                return null;
            }

            const startDateFormControl = selectedEndDate.root.get('selectedDate');
            const isStartDateValid = startDateFormControl.valid;

            if (!isStartDateValid) {
                return null;
            }

            if (selectedEndDate.value.toLowerCase() === DateConstants.DATE_VALUE_INDEFINITELY_display.toLowerCase()) {
                return null;
            }

            const startDateMoment: moment.Moment = moment(startDateFormControl.value, dateFormatPattern);
            const endDateMoment: moment.Moment = moment(selectedEndDate.value, dateFormatPattern);
            const isStartDateBeforeEndDate = moment(startDateMoment, dateFormatPattern).isBefore(endDateMoment);

            if (isStartDateBeforeEndDate) {
                return null;
            }

            return {
                afterStartDate: {
                    valid: false
                }
            };
        }
    }

    private endDatePatternValidator(dateFormatValidationPattern: RegExp): ValidatorFn {
        return function (selectedEndDate: UntypedFormControl): { pattern: { valid: boolean } } {
            if (!selectedEndDate.root || !selectedEndDate.root['controls']) {
                return null;
            }

            const endDate: string = selectedEndDate.value;

            if (endDate.toLowerCase() === DateConstants.DATE_VALUE_INDEFINITELY_display.toLowerCase()) {
                return null;
            }

            if (endDate.match(dateFormatValidationPattern)) {
                return null;
            }

            return {
                pattern: {
                    valid: false
                }
            }
        }
    }

    // ===========================================================================
    // ======================== CALCULATION METHODS ==============================
    // ===========================================================================

    private setCurrentMonth(changedDate: moment.Moment): void {
        const currentMonth = (changedDate ? changedDate.clone().startOf('month') : moment().startOf('month'));
        this.currentMonth = {
            displayText: currentMonth.format('MMMM YYYY'),
            momentValue: currentMonth
        }
        this.assignWeeksFromCurrentMonth();
    }

    private assignWeeksFromCurrentMonth(): void {
        this.weeksInMonth = [];
        const selectedDate = (this.activeDateRangeInputFormControl ? this.activeDateRangeInputFormControl : this.dateForm.get('selectedDate'));
        const isSelectedDateValid = selectedDate.valid;
        const inputDateMomentValue = (isSelectedDateValid ? moment(selectedDate.value, this.dateFormatPattern) : null);
        const numberOfDaysInMonth = this.currentMonth.momentValue.daysInMonth();
        const firstOfMonth = this.currentMonth.momentValue.startOf('month');
        let weekInMonth: Array<{ displayText: string, momentValue: moment.Moment, selected: boolean }> = [];

        for (let i = 0; i < numberOfDaysInMonth; i++) {
            const dayInMonth: moment.Moment = firstOfMonth.clone().add(i, 'days');
            const isEndOfWeek: boolean = dayInMonth.format('dddd') === 'Saturday';
            const isEndOfMonth: boolean = dayInMonth.format('DD') === numberOfDaysInMonth.toString();

            weekInMonth.push({
                displayText: dayInMonth.format('DD'),
                momentValue: dayInMonth,
                selected: false
            });

            if (isEndOfMonth) {
                this.weeksInMonth.push(weekInMonth);
                break;
            }

            if (isEndOfWeek) {
                this.weeksInMonth.push(weekInMonth);
                weekInMonth = [];
            }
        }
        if (inputDateMomentValue) {
            this.updateCalendarSelectedProperty(inputDateMomentValue);
        }
    }

    // ===========================================================================
    // ======================== SUBMISSION METHODS ==============================
    // ===========================================================================

    public closeDatePickerModal(): void {
        this.closeDatePicker.emit('');
    }

    public applyDate(): void {
        if (!this.isDateRangeSelected) {
            this.passSelectedDateToParent();
        } else {
            this.passDateRangeToParent();
        }
    }

    private passSelectedDateToParent(): void {
        const selectedDateFormControl = this.dateForm.get('selectedDate');
        if (!selectedDateFormControl || !selectedDateFormControl.valid) {
            return;
        }

        this.closeDatePicker.emit({selectedDate: selectedDateFormControl.value});
    }

    private passDateRangeToParent(): void {
        const selectedDateFormControl = this.dateForm.get('selectedDate');
        const selectedEndDateFormControl = this.dateForm.get('selectedEndDate');
        if (!selectedDateFormControl || !selectedDateFormControl.valid || !selectedEndDateFormControl || !selectedEndDateFormControl.valid) {
            return;
        }

        if (selectedEndDateFormControl.value.toLowerCase() === DateConstants.DATE_VALUE_INDEFINITELY_display.toLowerCase()) {
            selectedEndDateFormControl.setValue(DateConstants.DATE_VALUE_INDEFINITELY_display);
        }

        this.closeDatePicker.emit({
            selectedDate: selectedDateFormControl.value,
            selectedEndDate: selectedEndDateFormControl.value
        });
    }

    // ===========================================================================
    // ======================== UI METHODS =======================================
    // ===========================================================================

    public toggleSingleOrRangedDateSelection(): void {
        this.isDateRangeSelected = !this.isDateRangeSelected;
        this.dateFormSetup();
    }

    public nextMonth(): void {
        const currentMonth = this.currentMonth.momentValue.clone();
        this.setCurrentMonth(currentMonth.add(1, 'months'));
    }

    public previousMonth(): void {
        const currentMonth = this.currentMonth.momentValue.clone();
        this.setCurrentMonth(currentMonth.subtract(1, 'months'));
    }

    public setNewDateFromInputField(): void {
        const isSelectedDateValid = this.dateForm.controls.selectedDate.valid;
        if (!isSelectedDateValid) {
            return;
        }
        const selectedDateMomentvalue = moment(this.dateForm.get('selectedDate').value, this.dateFormatPattern);
        this.setCurrentMonth(selectedDateMomentvalue);
    }

    public setNewDateFromRangedInputField(): void {
        const isSelectedDateValid = this.activeDateRangeInputFormControl.valid;
        if (!isSelectedDateValid || this.activeDateRangeInputFormControl.value.toLowerCase() === DateConstants.DATE_VALUE_INDEFINITELY_display.toLowerCase()) {
            return;
        }

        const selectedDateMomentValue = moment(this.activeDateRangeInputFormControl.value, this.dateFormatPattern);
        this.setCurrentMonth(selectedDateMomentValue);
    }

    public setActiveDateRangeInputFormControl(dateRangeInput: AbstractControl): void {
        this.activeDateRangeInputFormControl = dateRangeInput as UntypedFormControl;

        this.setNewDateFromRangedInputField();
    }

    public setNewDateFromCalendar(calendarDate: { displayText: string, momentValue: moment.Moment, selected: boolean }): void {
        this.updateCalendarSelectedProperty(calendarDate.momentValue);
        const formattedDisplayText = calendarDate.momentValue.format(this.dateFormatPattern);

        if (this.activeDateRangeInputFormControl) {
            this.activeDateRangeInputFormControl.setValue(formattedDisplayText);
            return;
        }

        this.dateForm.controls.selectedDate.setValue(formattedDisplayText);
    }

    public clearCalenderSelectedProperty() {
        this.weeksInMonth.forEach((week) => {
            week.forEach((weekday) => {
                weekday.selected = false;
            });
        });
    }

    public updateCalendarSelectedProperty(calendarDateMomentValue: moment.Moment): void {
        this.weeksInMonth.forEach((week) => {
            week.forEach((weekday) => {
                if (weekday.momentValue.isSame(calendarDateMomentValue, 'day')) {
                    weekday.selected = true;
                } else {
                    weekday.selected = false;
                }
            });
        });
    }

    public toggleIndefinitelyCheckbox(): void {
        const selectedDateFormControl: UntypedFormControl = (this.allowSingleIndefinately) ? this.dateForm.get('selectedDate') as UntypedFormControl : this.dateForm.get('selectedEndDate') as UntypedFormControl;

        if (selectedDateFormControl.value.toLowerCase() === DateConstants.DATE_VALUE_INDEFINITELY_display.toLowerCase()) {
            selectedDateFormControl.setValue('');
        } else {
            selectedDateFormControl.setValue(DateConstants.DATE_VALUE_INDEFINITELY_display);
        }
        this.clearCalenderSelectedProperty();
        if (this.activeDateRangeInputFormControl !== selectedDateFormControl) {
            this.setActiveDateRangeInputFormControl(selectedDateFormControl);
        }
    }

    public selectSingleDateOnDoubleClick(calendarDate: { displayText: string, momentValue: moment.Moment, selected: boolean }): void {
        if (this.isDateRangeSelected || !calendarDate) {
            return;
        }

        this.applyDate();
    }
}
