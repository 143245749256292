import {
    Component,
    OnInit,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import {PurchaseOrder} from '../../models/purchaseOrder';
import {CommonDataService} from '../../services/commonData.service';

@Component({
    selector: 'app-purchase-order-lines',
    styleUrls: ['./app-purchase-order-lines.component.scss'],
    templateUrl: './app-purchase-order-lines.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppPurchaseOrderLinesComponent,
            multi: true
        },
    ],
    standalone: false
})
export class AppPurchaseOrderLinesComponent implements ControlValueAccessor, OnInit {
    public value: Array<PurchaseOrder> = [];

    constructor(private _commonDataService: CommonDataService,
    ) { }

    public ngOnInit() {
    }

    public writeValue(value) {
        const newLine = this.createNewLine();
        this.value = value;
        this.value.push(newLine);
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public updatePurchaseOrderLine($event: PurchaseOrder, i: number) {
        setTimeout(() => {
            const newLine = this.createNewLine();
            this.value[i] = $event;
            if ((i === this.value.length - 1) && (Object.keys(this.value[i]).length !== 0)) {
                this.value.push(newLine);
            }
            setTimeout(() => {
                this.valueChanged(this.value);
            }, 0);
        }, 0);
    }

    public deletePurchaseOrderDetailLine(i: number) {
        this.value.splice(i, 1);
        setTimeout(() => {
            this.valueChanged(this.value);
        }, 0);
    }

    private valueChanged = (__: any) => {};

    private createNewLine() {
        const newLine = new PurchaseOrder();

        newLine.Pallet = 0;
        newLine.Info = '';

        return newLine;
    }
}
