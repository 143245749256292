import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { unsubscribe } from '../../../helpers/utilities';

import * as AdminConstants from '../../../constants/admin.constants';
import {BreadcrumbService} from "../../../services/breadcrumb.service";
import {SignalsService} from "../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {AppState} from "../../../models/appState";

@Component({
    selector: 'app-admin-tabs',
    styleUrls: ['./admin-tabs.component.scss'],
    templateUrl: './admin-tabs.component.html',
    standalone: false
})
export class AdminTabsComponent implements OnInit, OnDestroy {
    @Input() public activeAdminSection: string;

    public showLevel1Header: boolean;
    private appStateSubscription: any;
    public adminSectionPaths: {
        roles: string,
        customers: string,
        users: string,
        groups: string,
        markups: string
    } = AdminConstants.ADMIN_SECTION_PATHS;
    private appState$;

    constructor(
        private _router: Router,
        private _breadcrumbService: BreadcrumbService,
        private _signalsService: SignalsService
    ) {
        this.appState$ = toObservable(this._signalsService.appStateSignal);
    }

    public ngOnInit() {
        this.appStateSubscription = this.appState$.subscribe((appState: AppState) => {
            this.showLevel1Header = appState['admin.showLevel1Header'];
        });
    }

    public ngOnDestroy() {
        unsubscribe(this.appStateSubscription);
    }

    public setActiveAdminSection(selectedSection: string): void {
        this._breadcrumbService.popBreadcrumb();
        this._router.navigate(['admin', selectedSection]);
    }

}
