import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-invite-user-modal',
    // template: ` `,
    styleUrls: ['./invite-user-modal.component.scss'],
    templateUrl: './invite-user-modal.component.html',
    standalone: false
})

export class InviteUserModalComponent implements OnInit {
    @Output() public confirmUserInvitation = new EventEmitter();
    @Output() public closeInviteUserModal = new EventEmitter();

    public inviteUserForm: UntypedFormGroup;
    public typesArray = ['Single', 'Multi'];
    public isExpirationValueValid: boolean;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _notificationService: NotificationService,
    ) { }

    ngOnInit() {
        this.inviteUserForm = this._formBuilder.group({
            email: new UntypedFormControl('', {
                validators: [
                    Validators.required
                ]
            }),
            expiration: new UntypedFormControl(null, {
                validators: [
                    Validators.required,
                    this.checkExpirationValidity(),
                ]
            }),
            type: new UntypedFormControl('', {
                validators: [
                    Validators.required
                ]
            }),
            roleId: new UntypedFormControl('', {
                validators: [
                    Validators.required
                ]
            }),
            custNo: new UntypedFormControl('', {
                validators: [
                    Validators.required
                ]
            }),
        });
    }

    public prepareUserInvitationDetails(): { email: string, expires: string, type: string, roleId: string } {
        const inviteUserObject = {
            email: this.inviteUserForm.get('email').value,
            expires: this.inviteUserForm.get('expiration').value,
            type: this.inviteUserForm.get('type').value,
            roleId: this.inviteUserForm.get('roleId').value,
            custNo: this.inviteUserForm.get('custNo').value,
        }

        return inviteUserObject;
    }

    private checkExpirationValidity() {
        return function (expirationFormControl: UntypedFormControl): { isValueValid: { valid: boolean } } {
            if (!expirationFormControl.root || !expirationFormControl.root['controls']) {
                return null;
            }
            const passwordFormControl = expirationFormControl.root.get('expiration');
            const isValueValid = passwordFormControl.valid;
            if (!isValueValid) {
                return null;
            }
            if (isNaN(passwordFormControl.value)) {
                return {
                    isValueValid: {
                        valid: true
                    }
                };
            }
        }
    }

    public confirmUserInvite(): void {
        if (!this.inviteUserForm.get('email').value) {
            this._notificationService.notifyError({ title: 'Missing Info', message: 'Please enter an invitee email address' });
        } else if (!this.inviteUserForm.get('expiration').value) {
            this._notificationService.notifyError({ title: 'Missing Info', message: 'Please enter an expiration value' });
        } else if (!this.inviteUserForm.get('roleId').value) {
            this._notificationService.notifyError({ title: 'Missing Info', message: 'Please enter your invitee\'s role ID' });
        } else {
            this.confirmUserInvitation.emit(this.prepareUserInvitationDetails());
            this.close();
        }
    }

    public close(): void {
        this.closeInviteUserModal.emit('');
    }
}
