import { Pipe, PipeTransform } from '@angular/core';

import * as SearchConstants from '../constants/searchCriteria';

@Pipe({
    name: 'apiParseError',
    standalone: false
})
export class ApiParseErrorPipe implements PipeTransform {
  transform(fieldValue: any, fieldName?: string): string {
    if (fieldName === SearchConstants.SEARCH_CRITERIA_type.PRICE || fieldName === SearchConstants.SEARCH_CRITERIA_type.PRICE_2) {
      return fieldValue;
    }

    if (
      (fieldValue === SearchConstants.SEARCH_CRITERIA_value.FALSE) ||
      (fieldValue === SearchConstants.API_PARSE_ERROR_VALUES.FALSE) ||
      (fieldValue === 0)
    ) {
      return 'No';
    }

    if (
      (fieldValue === SearchConstants.SEARCH_CRITERIA_value.TRUE) ||
      (fieldValue === SearchConstants.API_PARSE_ERROR_VALUES.TRUE) ||
      (fieldValue === 1)
    ) {
      return 'Yes';
    }

    if (fieldValue === SearchConstants.API_PARSE_ERROR_VALUES.NULL) {
      return '';
    }

    return fieldValue;
  }
}
