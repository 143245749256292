import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'app-login',
    styleUrls: ['./login.component.scss'],
    templateUrl: './login.component.html',
    standalone: false
})
export class LoginComponent {
    public loginName: string;
    public password: string;
    public showInputs: boolean = false;
    public showSubmitButton: boolean = false;

    constructor(
        private _authService: AuthService,
        private _notificationService: NotificationService,
        private _router: Router
    ) {
    }

    public goToLocation(url: string) {
        window.location.href = url;
    }

    public showInputFields() {
        this.showInputs = true;
        this.showSubmitButton = true;
    }

    public processLogin(): void {
        if (!this.loginName) {
            this._notificationService.notifyError({title: 'Login', message: 'Please enter your username'});
            return;
        }
        if (!this.password) {
            this._notificationService.notifyError({title: 'Login', message: 'Please enter your password'});
            return;
        }
        this._authService.processUserLoginAsync({username: this.loginName, password: this.password});
    }

    public navigateToRegistrationScreen(): void {
        this._router.navigate(['registration']);
    }

    public navigateToResetPasswordScreen() {
        this._router.navigate(['forgot-password']);
    }
}
