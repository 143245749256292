import { Component } from '@angular/core';

@Component({
    selector: 'app-admin-group-list',
    styleUrls: ['./admin-group-list.component.scss'],
    templateUrl: './admin-group-list.component.html',
    standalone: false
})
export class AdminGroupListComponent {

    constructor() { }
}
