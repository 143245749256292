import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash-es';
import {concatMap, map, shareReplay, switchMap} from 'rxjs/operators';
import * as DateConstants from '../../constants/datetime.constants';
import * as MDConstants from '../../constants/masterData.constants';
import {UserHelper} from '../../helpers/userHelper';
import {clearArray} from '../../helpers/utilities';
import {AccessorialForQuote} from '../../models/accessorialForQuote';
import {AccessorialGroup} from '../../models/accessorialGroup';
import {ContactSearchResult} from '../../models/contact.searchResult';
import {Document} from '../../models/document';
import {DocumentUploadRequest} from '../../models/documentUploadRequest';
import {QuotedRate} from '../../models/quotedRate';
import {QuoteSaveResponse} from '../../models/quoteSaveResponse';
import {QuotesRequest} from '../../models/quotesRequest';
import {QuotesResponse} from '../../models/quotesResponse';
import {Shipment} from '../../models/shipment';
import {ShipmentNote} from '../../models/shipmentNote';
import {ShipmentUnified} from '../../models/shipmentUnified';
import {TabInfo} from '../../models/tabInfo';
import {UnitOfMeasure} from '../../models/unitOfMeasure';
import {CommonDataService} from '../../services/commonData.service';
import {FilesService} from '../../services/files.service';
import {EMPTY_BILLTO, ShipmentService} from '../../services/shipment.service';
import * as SearchConstants from '../../constants/searchCriteria';
import * as Constants from '../../constants/constants';
import {ShipmentAddressSelection} from '../app-address/shipment-address.selection';
import {
    CANCELLED, CARRIER_CONFIRMED, DELIVERED, ENROUTE_TO_DESTINATION, INVOICED, NOT_SUBMITTED, ORDER_RECEIVED,
    OS_AND_D, PENDING, PENDING_CONFIRMATION, PENDING_REVIEW, PROCESSING, QUOTE, REJECTED, REVIEW, SCHEDULED_PICKUP
} from "../../constants/shipment.constants";
import {SignalsService} from "../../services/signals.service";
import {TemplateService} from "../../services/template.service";
import {NotificationService} from "../../services/notification.service";
import {Subject, Subscription, of, Observable} from 'rxjs';
import {InsuranceService} from "../../services/insurance.service";
import {Insurance} from "../../models/insurance";
export const HAZMAT_CODE = 'HAZMAT';
export const RESIDENTIALPICKUP_CODE = 'RESIDENTIALPICKUP';
export const RESIDENTIALDELIVERY_CODE = 'RESIDENTIALDELIVERY';
export const APPOINTMENT_CODE = 'APPOINTMENT';
export const APPOINTMENTATPICKUP_CODE = 'APPOINTMENTATPICKUP';

@Component({
    selector: 'app-shipment',
    styleUrls: ['./app-shipment.component.scss'],
    templateUrl: './app-shipment.component.html',
    standalone: false
})
export class AppShipmentComponent implements OnInit, OnDestroy {
    public searchConstants = SearchConstants;
    public shipmentUnifiedForm: UntypedFormGroup;
    public equipmentTypes = [];
    public billToList = [];
    public availableAccessorials = [];
    public dialogUp = false;
    public displayDateFormat: string = DateConstants.DATE_FORMAT_MMMDDYYY_WITH_SLASHES;
    public displayDateValidation: RegExp = DateConstants.VALID_DATE_REGEX_MM_DD_YYYY_Slash;
    public activeTab = 0;
    public unitOfMeasure;
    public constants = Constants;
    public naBillTo: any = {name: EMPTY_BILLTO};
    public shipmentAddressSelection = ShipmentAddressSelection;
    public isQuoteSelectionVisible = false;
    public rates: Array<QuotedRate>;
    public lowestCostRateId;
    public fastestDeliveryRateId;
    public showCostDetails = true;
    public lastQuoteResponse: QuotesResponse;
    public unselectedQuoteResponse: QuotesResponse;
    public lastSelectedRate: QuotedRate;
    public quotedShipment: ShipmentUnified;
    public currency = '';
    public showInvalid = false;
    public isPlaceShipmentModalVisible = false;
    public isQuoteReRateWarningShown = false;
    public isShipmentNotesFloaterVisible = false;
    public isTemplateNameModalVisible = false;
    public showBOLWarning = false;
    public placeToPrintBOL = false;
    public lockBillTo = false;
    public steps = [
        'Received',
        'Scheduled',
        'Enroute',
        'Delivered',
        'Invoiced'
    ];
    public shipmentNotes: Array<ShipmentNote>;
    public userName = '';
    public moreMenu = [];
    public acclGroups: Array<AccessorialGroup>;
    public isHazmatShipment = false;
    public tabs: Array<TabInfo> = [
        {
            tabIcons: [
                {
                    outerIcon: 'fa-circle',
                    outerIconClass: 'shipperOuterIcon',
                    innerIcon: 'fa-truck',
                    innerIconClass: 'shipperInnerIcon'
                }
            ],
            text: 'Shipment'
        },
        {
            tabIcons: [
                {
                    outerIcon: 'fa-circle',
                    outerIconClass: 'genRefOuterIcon',
                    innerIcon: 'fa-list-alt',
                    innerIconClass: 'genRefInnerIcon'
                }
            ],
            text: 'References'
        },
        {
            tabIcons: [
                {
                    outerIcon: 'fa-circle',
                    outerIconClass: 'carrierRefOuterIcon',
                    innerIcon: 'fa-road',
                    innerIconClass: 'carrierRefInnerIcon'
                }
            ],
            text: 'Carrier'
        }
    ];

    private uploadSubjectSubscription: Subscription;
    private uploadSubject$: Subject<DocumentUploadRequest> = new Subject<DocumentUploadRequest>();
    private shipmentUnifiedId;
    private customerId;
    private subs = new Subscription();
    private unitOfMeasures;
    private skipAcclChange = false;
    public isInsuranceModalShown;

    constructor(private _route: ActivatedRoute,
                public _commonDataService: CommonDataService,
                public _signalsService: SignalsService,
                private _router: Router,
                public _shipmentService: ShipmentService,
                private _userHelper: UserHelper,
                private _filesService: FilesService,
                private _notificationService: NotificationService,
                private _templateService: TemplateService,
                private _insuranceService: InsuranceService,
    ) {
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    public ngOnDestroy() {
        this.subs.unsubscribe();
        if (this.uploadSubjectSubscription) {
            this.uploadSubjectSubscription.unsubscribe();
        }
    }

    public async ngOnInit() {
        let queryParamT;
        const naEquipmentType: any = {};

        this.unitOfMeasures = await this._commonDataService.loadedPromise(this._commonDataService.unitOfMeasures);
        this.unitOfMeasure = _.find(this.unitOfMeasures, (unitOfMeasure) => unitOfMeasure.unitOfMeasureCode === Constants.UNIT_OF_MEASURE_Imperial);

        naEquipmentType.description = 'N/A';
        naEquipmentType.id = 0;
        this.equipmentTypes = _.cloneDeep(await this._commonDataService.loadedPromise(this._commonDataService.equipmentTypes));
        this.equipmentTypes.unshift(naEquipmentType);
        if (this._userHelper.shouldBillToContactBeRetrieved()) {
            this.billToList = _.cloneDeep(await this._commonDataService.loadedPromise(this._commonDataService.billToContacts));
        } else {
            this.billToList = [];
        }
        this.billToList.unshift(this.naBillTo);
        this.availableAccessorials = await this._commonDataService.loadedPromise(this._commonDataService.availableAccessorials);
        this.acclGroups = await this._commonDataService.loadedPromise(this._commonDataService.accessorialGroups);
        this.userName = this._userHelper.getUserProfile().name;
        this.placeToPrintBOL = this._signalsService.loginSettingsSignal().permissions[0].placeToPrintBOL;
        this.lockBillTo = !!this._signalsService.loginSettingsSignal().lockBillTo;
        const shipmentUnifiedForm$ = this._route.params.pipe(
            switchMap((params) => {
                this.shipmentUnifiedId = params['shipmentUnifiedId'];
                this.customerId = params['customerId'];

                return this._shipmentService.getShipmentUnifiedByPath(this.shipmentUnifiedId, this.customerId);
            }),
            switchMap((shipmentUnified: ShipmentUnified) => {
                let accessorial: AccessorialForQuote;

                this.customerId = shipmentUnified.Main.Customer;
                this.lastQuoteResponse = shipmentUnified.Quote;
                if (shipmentUnified.Quote.quoteId) {
                    this.quotedShipment = shipmentUnified;
                }
                this.currency = shipmentUnified.Main.currency;
                if (this.lastQuoteResponse.selectedRateId) {
                    this.lastSelectedRate = _.find(this.lastQuoteResponse.carriers, (rate: QuotedRate) => rate.rateId === this.lastQuoteResponse.selectedRateId);
                }
                if (!this.shipmentUnifiedForm) {
                    this.shipmentUnifiedForm = this._shipmentService.getShipmentUnifiedFormFromLastShipmentUnified();
                } else {
                    this._shipmentService.updateForm(this.shipmentUnifiedForm);
                }

                this.unitOfMeasure = _.find(this.unitOfMeasures, (unitOfMeasure) => unitOfMeasure.unitOfMeasureCode === this.shipmentUnifiedForm.controls.unitOfMeasure.value);

                if (this._shipmentService.lastShipmentIsPlaced()) {
                    this.isShipmentNotesFloaterVisible = true;
                    this._shipmentService.getShipmentNotes(shipmentUnified.Main.Customer, shipmentUnified.Main.ID).pipe(
                        map(result => {
                            this.shipmentNotes = result;
                        })
                    ).subscribe();
                    this.activeTab = -1;
                    this.moreMenu.push(...['Print Labels', 'Copy Shipment']);
                } else {
                    this.moreMenu.push('Save as Template');
                    if (shipmentUnified.Main.ID) {
                        this.moreMenu.push('Copy Shipment');
                        if (!this.placeToPrintBOL) {
                            this.moreMenu.push('Print BOL');
                            this.moreMenu.push('Print Labels');
                        }
                        this.moreMenu.push('Summary');
                    }
                }
                queryParamT = this._route.snapshot.queryParamMap.get('t');

                if (queryParamT) {
                    queryParamT = +queryParamT;
                    if ((queryParamT > 0) && (queryParamT <= 2)) {
                        this.activeTab = queryParamT;
                    }
                }

                for (accessorial of this.shipmentUnifiedForm.value.accessorials) {
                    if (accessorial.code === HAZMAT_CODE) {
                        this.isHazmatShipment = true;
                        break;
                    }
                }

                return of(this.shipmentUnifiedForm);
            }),
            shareReplay()
        );

        this.subs.add(shipmentUnifiedForm$.pipe(
            switchMap(() => this.shipmentUnifiedForm.get('shipper').valueChanges),
            map(() => this.contactChanges('shipper'))
        ).subscribe());

        this.subs.add(shipmentUnifiedForm$.pipe(
            switchMap(() => this.shipmentUnifiedForm.get('consignee').valueChanges),
            map(() => this.contactChanges('consignee'))
        ).subscribe());

        this.subs.add(shipmentUnifiedForm$.pipe(
            switchMap(() => this.shipmentUnifiedForm.get('productLines').valueChanges),
            map(() => {
                this.enforceAccessorials();
                this.checkProductLines();
            })
        ).subscribe());

        this.subs.add(shipmentUnifiedForm$.pipe(
            switchMap(() => this.shipmentUnifiedForm.get('accessorials').valueChanges),
            map((changes) => {
                setTimeout(() => {
                    this.enforceAccessorials(true)
                }, 0)
            })
        ).subscribe());

        this.subs.add(shipmentUnifiedForm$.pipe(
            switchMap(() => this.shipmentUnifiedForm.get('tsaOnly').valueChanges),
            map((changes) => this.checkTsa(changes))
        ).subscribe());

        this.subs.add(shipmentUnifiedForm$.pipe(
            switchMap(() => this.shipmentUnifiedForm.get('equipment').valueChanges),
            map((changes) => this.checkEquipment(changes))
        ).subscribe());

        this.subs.add(shipmentUnifiedForm$.pipe(
            switchMap(() => this.shipmentUnifiedForm.get('pickupDate').valueChanges),
            map((changes) => this.checkPickupDate(changes))
        ).subscribe());

    }

    public getQuote() {
        const quoteRequest: QuotesRequest = this._shipmentService.getQuoteFromForm(this.shipmentUnifiedForm);
        this._shipmentService.getRates(quoteRequest).pipe(
            map((quoteResponse: QuotesResponse) => {
                this.rates = quoteResponse.carriers;
                this.lowestCostRateId = quoteResponse.lowestCostRateId;
                this.fastestDeliveryRateId = quoteResponse.fastestDeliveryRateId;
                this.unselectedQuoteResponse = quoteResponse;
                this.isQuoteSelectionVisible = true;
            })
        ).subscribe();
    }

    public saveQuoteWithShipment(shipmentUnified: ShipmentUnified) {
        if (!this._shipmentService.lastShipmentUnified.Quote.selectedRateId) {
            return this._shipmentService.createQuote({
                rate_id: this.lastSelectedRate.rateId.toString(),
                customer: this._userHelper.getUserCustomer(),
                quote_name: ''
            }).pipe(
                switchMap((resp: QuoteSaveResponse) => this._shipmentService.getQuote(resp.quote_id)),
                switchMap((resp: QuotesResponse) => {
                    shipmentUnified.Quote = resp;
                    this._shipmentService.updateShipmentUnifiedWithQuote(shipmentUnified, resp);
                    if (shipmentUnified.Main.ID) {
                        return this._shipmentService.updateShipmentUnified(shipmentUnified);
                    } else {
                        return this._shipmentService.createShipmentUnified(shipmentUnified);
                    }
                })
            );
        } else {
            return this._shipmentService.updateQuoteSelectedRate({
                quoteId: this.lastQuoteResponse.quoteId,
                rateId: this.lastSelectedRate.rateId,
                quoteName: ''
            }).pipe(
                switchMap(response => {
                    shipmentUnified.Quote = response;
                    this._shipmentService.updateShipmentUnifiedWithQuote(shipmentUnified, response);
                    shipmentUnified.Main.quoteId = response.quoteId;
                    if (shipmentUnified.Main.ID) {
                        return this._shipmentService.updateShipmentUnified(shipmentUnified);
                    } else {
                        return this._shipmentService.createShipmentUnified(shipmentUnified);
                    }
                })
            );
        }
    }

    public save() {
        let saveShipmentObservable: Observable<ShipmentUnified>;
        let returnedShipmentUnified: ShipmentUnified;
        let insurance: Insurance;
        const formShipmentUnified = this._shipmentService.getShipmentUnifiedFromForm(this.shipmentUnifiedForm, this.lastQuoteResponse, this.lastSelectedRate);
        if (this.lastQuoteResponse && this.lastSelectedRate && (this.lastQuoteResponse.quoteId !== this._shipmentService.lastShipmentUnified.Main.quoteId)) {
            saveShipmentObservable = this.saveQuoteWithShipment(formShipmentUnified);
        } else if (formShipmentUnified.Main.ID) {
            if (!this.lastSelectedRate) {
                formShipmentUnified.Quote = {} as QuotesResponse;
                formShipmentUnified.Main.quoteId = 0;
            }
            saveShipmentObservable = this._shipmentService.updateShipmentUnified(formShipmentUnified);
        } else {
            saveShipmentObservable = this._shipmentService.createShipmentUnified(formShipmentUnified);
        }
        saveShipmentObservable.pipe(
            switchMap((shipmentUnified: ShipmentUnified) => {
                returnedShipmentUnified = shipmentUnified;
                insurance = this.shipmentUnifiedForm.get('insurance').value;
                if (insurance && insurance.insuranceCharge) {
                    return this._insuranceService.createInsurance(shipmentUnified.Main.ID, (shipmentUnified.Main.quoteId) ? shipmentUnified.Main.quoteId : null, insurance.insuranceCharge, insurance.shipmentValue);
                } else {
                    return this._insuranceService.removeRecordInsurance(shipmentUnified.Main.ID);
                }
            }),
            map(() => {
                this._router.navigate(['shipments', returnedShipmentUnified.Main.Customer, returnedShipmentUnified.Main.ID], {queryParams: {t: this.activeTab}});
            })
        ).subscribe();
    }

    public placeShipment(placeShipmentFormValue: { bolEmail: string, bolName: string, bolPhone: string, shipmentValue: string, insuranceCharge: string }) {
        const shipmentUnified = this._shipmentService.getLastShipmentUnified();
        let saveInsObservable: Observable<any>;

        this.isPlaceShipmentModalVisible = false;

        if (!placeShipmentFormValue) {
            return;
        }

        if (placeShipmentFormValue.insuranceCharge) {
            saveInsObservable = this._insuranceService.createInsurance(shipmentUnified.Main.ID, shipmentUnified.Quote.quoteId, +placeShipmentFormValue.insuranceCharge, +placeShipmentFormValue.shipmentValue);
        } else {
            saveInsObservable = of(null);
        }

        saveInsObservable.pipe(
            switchMap(() => {
                return this._shipmentService.placeShipment(shipmentUnified.Main.Customer, shipmentUnified.Main.ID, placeShipmentFormValue);
            }),
            map(() => {
                this._router.navigate(['shipments', shipmentUnified.Main.Customer, shipmentUnified.Main.ID]);
            })
        ).subscribe();
    }

    public validateShipment() {
        const shipmentUnified = this._shipmentService.getLastShipmentUnified();

        this.shipmentUnifiedForm.controls.productLines.updateValueAndValidity();
        if (!this.shipmentUnifiedForm.valid) {
            this.activeTab = 0;
            this.showInvalid = true;
            this._notificationService.notifyError({title: 'Can not place shipment.\nPlease correct highlighted fields.', message: ''});
            return;
        }

        this._shipmentService.validateShipment(shipmentUnified.Main.Customer, shipmentUnified.Main.ID).pipe(
            map(() => this.isPlaceShipmentModalVisible = true)
        ).subscribe();
    }

    public saveAsTemplate() {
        this.isTemplateNameModalVisible = true;
    }

    public updateUom($event: UnitOfMeasure) {
        this.unitOfMeasure = $event;
        this.shipmentUnifiedForm.controls.unitOfMeasure.setValue($event.unitOfMeasureCode);
    }

    public getShipment() {
        return this._shipmentService.lastShipmentUnified.Main;
    }

    public getFiles() {
        if (this.getShipment().ID) {
            return this._shipmentService.lastShipmentUnified.Documents;
        } else {
            return undefined;
        }
    }

    public getBillToLabel(billToObj) {
        if (billToObj) {
            return billToObj.name;
        }
        return undefined;
    }

    public downloadDocument(docList: Array<Document>) {
        let doc: Document;

        for (doc of docList) {
            this._filesService.downloadFileAttachment(doc).subscribe();
        }
    }

    public reloadDocuments() {
        let doc: Document;

        if (this.getShipment().ID) {
            this._filesService.getDocuments(this.getShipment().ID).subscribe((docs) => {
                clearArray(this._shipmentService.lastShipmentUnified.Documents);
                for (doc of docs) {
                    this._shipmentService.lastShipmentUnified.Documents.push(doc);
                }
            });
        }
    }

    public uploadDocuments($event: DocumentUploadRequest) {
        if ($event) {
            $event.shipmentId = this.getShipment().ID;
            $event.customerId = this.customerId;
            $event.userId = this._userHelper.getUserId();

            if (!this.uploadSubjectSubscription) {
                this.uploadSubjectSubscription = this.uploadSubject$.pipe(
                    concatMap((d: DocumentUploadRequest) => this._filesService.doUpload(d))
                ).subscribe((d) => {
                    this.reloadDocuments();
                });
            }
            this.uploadSubject$.next($event);
        }
    }

    public deleteDocument($event: Document) {
        this._filesService.deleteFileAttachment($event.id).subscribe(() => {
            this.reloadDocuments();
        });
    }

    public updateDocument($event) {
        this._filesService.updateFileAttachmentDescription($event).subscribe(() => {
            this.reloadDocuments();
        });
    }

    public getSelectedCarrier() {
        if (this.lastSelectedRate?.rateId) {
            return _.find(this.lastQuoteResponse.carriers, (carrier: QuotedRate) => carrier.rateId === this.lastSelectedRate.rateId);
        }
        return false;
    }

    public selectedRate(rate: QuotedRate) {
        let result;
        let accessorial;
        let accessorials = [];

        this.isQuoteSelectionVisible = false;
        this.lastSelectedRate = rate;
        this.lastQuoteResponse = this.unselectedQuoteResponse;

        for (let acclChg of rate.asc.charges) {
            accessorial = _.find(this.availableAccessorials, (acc: AccessorialForQuote) => acc.code === acclChg.code);
            if (accessorial) {
                result = this.addAccessorialToForm(accessorials, accessorial);
            }
        }

        if (result) {
            // Don't show quote changed dialog even though accls are changing because changes are coming from selected quote:
            this.skipAcclChange = true;
            this.shipmentUnifiedForm.get('accessorials').setValue(result.accessorials);
        } else {
            this.shipmentUnifiedForm.get('accessorials').setValue([]);
        }

        this.quotedShipment = this._shipmentService.getShipmentUnifiedFromForm(this.shipmentUnifiedForm, this.lastQuoteResponse, this.lastSelectedRate);
        // Force update on cost details component by toggling details:
        this.showCostDetails = false;
        setTimeout(() => {
            this.showCostDetails = true;
        }, 0);
    }

    public printBOL() {
        if (!this._shipmentService.lastShipmentIsPlaced()) {
            if (this.shipmentUnifiedForm.dirty) {
                this.showBOLWarning = true;
                return;
            }
        }
        this._shipmentService.openBOL({ customerId: this.customerId, shipmentId: this._shipmentService.lastShipmentUnified.Main.ID });
    }

    public printLabels() {
        this._shipmentService.openShippingLabel({ customerId: this.customerId, shipmentId: this._shipmentService.lastShipmentUnified.Main.ID });
    }

    public printSummary() {
        window.print();
    }

    public copyShipment() {
        this._shipmentService.copyShipment(this.customerId, this._shipmentService.lastShipmentUnified.Main.ID).pipe(
            map(response => {
                this._router.navigate(['shipments', response.customer, response.id]);
            })
        ).subscribe();
    }

    public getReactiveDate() {
        let dateInfo;
        switch (this._shipmentService.lastShipmentUnified.Main.status) {
            case NOT_SUBMITTED:
            case ORDER_RECEIVED:
            case SCHEDULED_PICKUP:
            case PENDING:
            case CARRIER_CONFIRMED:
            case OS_AND_D:
            case QUOTE:
            case REVIEW:
            case PENDING_CONFIRMATION:
                dateInfo = this.getPickupDate(this._shipmentService.lastShipmentUnified.Main);
                return dateInfo.text + ': ' + dateInfo.date;
            case ENROUTE_TO_DESTINATION:
            case DELIVERED:
            case INVOICED:
            case PROCESSING:
            case PENDING_REVIEW:
                dateInfo = this.getDeliveryDate(this._shipmentService.lastShipmentUnified.Main);
                return dateInfo.text + ': ' + dateInfo.date;
            case CANCELLED:
                return 'Canceled';
            case REJECTED:
                return 'Rejected';
            default:
                return '';
        }
    }

    public getStepForStatus(status) {
        switch (status) {
            case ORDER_RECEIVED:
            case QUOTE:
            case CANCELLED:
            case REJECTED:
            case OS_AND_D:
                return this.steps[0];
            case SCHEDULED_PICKUP:
            case PENDING:
            case REVIEW:
            case PENDING_CONFIRMATION:
            case CARRIER_CONFIRMED:
                return this.steps[1];
            case ENROUTE_TO_DESTINATION:
                return this.steps[2];
            case DELIVERED:
            case PROCESSING:
            case PENDING_REVIEW:
                return this.steps[3];
            case INVOICED:
                return this.steps[4];
            default:
                return this.steps[0];
        }
    }

    public getStepLabelForStatus(status: number, i: any) {
        if (i === 0) {
            switch (status) {
                case CANCELLED:
                    return 'Canceled';
                case QUOTE:
                    return 'Quote';
                case REJECTED:
                    return 'Rejected';
                case OS_AND_D:
                    return 'OS&D';
            }
        } else if (i === 1) {
            switch (status) {
                case PENDING:
                case PENDING_CONFIRMATION:
                    return 'Pending';
                case REVIEW:
                    return 'Review';
                case CARRIER_CONFIRMED:
                    return 'Confirmed';
            }
        } else if (i === 3) {
            switch (status) {
                case PENDING_REVIEW:
                    return 'Reviewing';
            }
        }
        return this.steps[i];
    }

    public needWarningIcon(status, i) {
        if (i === 0) {
            switch (status) {
                case CANCELLED:
                case REJECTED:
                case OS_AND_D:
                    return true;
            }
        } else if (i === 1) {
            switch (status) {
                case PENDING:
                case REVIEW:
                case PENDING_CONFIRMATION:
                case CARRIER_CONFIRMED:
                    return true;
            }
        } else if (i === 3) {
            switch (status) {
                case PENDING_REVIEW:
                    return true;
            }
        }

        return false;
    }

    public acceptQuoteReRating() {
        this.lastQuoteResponse = undefined;
        this.lastSelectedRate = undefined;
        this.setQuoteReRateWarning(false);
    }

    public declineQuoteReRating() {
        const formValue = this.shipmentUnifiedForm.value;

        formValue.shipper.zip = this.quotedShipment.Main.shipper_zip;
        formValue.shipper.city = this.quotedShipment.Main.shipper_city;
        formValue.shipper.state = this.quotedShipment.Main.shipper_state;
        formValue.shipper.country = this.quotedShipment.Main.shipper_country;
        formValue.shipper.callappt = this.quotedShipment.Main.shipperCallAppt;
        formValue.shipper.commercialresidential = this.quotedShipment.Main.shipperCommercialResidential;
        formValue.consignee.zip = this.quotedShipment.Main.consignee_zip;
        formValue.consignee.city = this.quotedShipment.Main.consignee_city;
        formValue.consignee.state = this.quotedShipment.Main.consignee_state;
        formValue.consignee.country = this.quotedShipment.Main.consignee_country;
        formValue.consignee.callappt = this.quotedShipment.Main.consigneeCallAppt;
        formValue.consignee.commercialresidential = this.quotedShipment.Main.consigneeCommercialResidential;

        formValue.accessorials = this._shipmentService.accessorialsTransform(this.quotedShipment);

        formValue.equipment = this._shipmentService.equipmentTypeTransform(this.quotedShipment.Main.equipmentType);
        formValue.tsaOnly = this._shipmentService.tsaTransform(this.quotedShipment.Quote.tsa);
        formValue.pickupDate = this._shipmentService.dateTransform(this.quotedShipment.Quote.shipdate);

        formValue.productLines = this._shipmentService.productsTransform(this.quotedShipment.Products);
        formValue.unitOfMeasure = this.quotedShipment.Main.unitmeasurement;
        this.unitOfMeasure = _.find(this.unitOfMeasures, (unitOfMeasure) => unitOfMeasure.unitOfMeasureCode === this.quotedShipment.Main.unitmeasurement);

        formValue.purchaseOrderLines.splice(formValue.purchaseOrderLines.length-1, 1);

        this.shipmentUnifiedForm.setValue(formValue);

        this.setQuoteReRateWarning(false);
    }

    public closeTemplateNameModal(templateName: string): void {
        let shipmentValue;

        this.isTemplateNameModalVisible = false;
        if (templateName) {
            const templateReq: any = {
                templateType: MDConstants.TEMPLATE_TYPES_shipment,
                templateName,
                template: this._shipmentService.getShipmentUnifiedFromForm(this.shipmentUnifiedForm, this.lastQuoteResponse, this.lastSelectedRate),
                templateGroup: '?'
            };

            shipmentValue = this.shipmentUnifiedForm.get('insurance').value.shipmentValue;

            if (shipmentValue) {
                templateReq.shipmentValue = shipmentValue;
            }

            this._templateService.createUserTemplate(templateReq).pipe(
                switchMap(() => this._templateService.getUserTemplates())
            ).subscribe();
        }
    }

    public handleMenuButton($event: any) {
        switch ($event) {
            case 'Save as Template':
                this.saveAsTemplate();
                break;
            case 'Print BOL':
                this.printBOL();
                break;
            case 'Print Labels':
                this.printLabels();
                break;
            case 'Copy Shipment':
                this.copyShipment();
                break;
            case 'Summary':
                if (this.saveInactive()) {
                    this.activeTab = -1;
                    this.moreMenu[this.moreMenu.length-1] = 'Edit'
                } else {
                    this._notificationService.notifyWarning({ title: 'Save for Summary', message: `Please Save shipment to show summary.` });
                }
                break;
            case 'Edit':
                this.activeTab = 0;
                this.moreMenu[this.moreMenu.length-1] = 'Summary'
                break;
        }
    }

    public quoteChanged() {
        return this.lastQuoteResponse.selectedRateId !== this._shipmentService.lastShipmentUnified.Quote.selectedRateId;
    }

    public isOnlyQuote() {
        return this.lastQuoteResponse.quoteId && !this._shipmentService.lastShipmentUnified.Main.ID;
    }

    public saveInactive() {
        return !(this.shipmentUnifiedForm.dirty || this.isOnlyQuote() || this.quoteChanged());
    }

    public getClassFor(fieldName) {
        return {
            error: this.showInvalid && this.shipmentUnifiedForm.get(fieldName).invalid,
        };
    }

    public getInsuranceCharge() {
        if (this.shipmentUnifiedForm.get('insurance')?.value?.insuranceCharge) {
            return this.shipmentUnifiedForm.get('insurance').value.insuranceCharge;
        } else {
            return 0;
        }
    }

    public getPickupDate(shipment: Shipment) {
        if (shipment.actualPickupDate) {
            return {
                text: 'Picked up',
                date: this._shipmentService.dateTransform(shipment.actualPickupDate).date
            }
        } else if (shipment.p_appt_date) {
            return {
                text: 'Est. Pick up',
                date: this._shipmentService.dateTransform(shipment.p_appt_date).date
            }
        } else {
            return {
                text: 'Req. Pick up',
                date: this._shipmentService.dateTransform(shipment.Pickup_Date).date
            }
        }
    }

    public getDeliveryDate(shipment: Shipment) {
        if (shipment.actualDeliveryDate) {
            return {
                text: 'Delivered',
                date: this._shipmentService.dateTransform(shipment.actualDeliveryDate).date
            }
        } else if (shipment.d_appt_date) {
            return {
                text: 'Est. Delivery',
                date: this._shipmentService.dateTransform(shipment.d_appt_date).date
            }
        } else {
            return {
                text: 'Req. Delivery',
                date: this._shipmentService.dateTransform(shipment.Delivery_Date).date
            }
        }
    }

    private removeAccessorialFromForm(accessorials: Array<AccessorialForQuote>, accessorial: AccessorialForQuote) {
        const idx = _.findIndex(accessorials, a => a.code === accessorial.code);

        if (idx === -1) {
            return {
                accessorials,
                modified: false
            };
        }

        accessorials.splice(idx, 1);
        return {
            accessorials,
            modified: true
        };
    }

    private addAccessorialToForm(accessorials: Array<AccessorialForQuote>, accessorial: AccessorialForQuote) {
        let acc: AccessorialForQuote;

        for (acc of accessorials) {
            if (acc.code === accessorial.code) {
                return {
                    accessorials,
                    modified: false
                };
            }
        }

        accessorials.push(accessorial);
        return {
            accessorials,
            modified: true
        };
    }

    private enforceAccessorials(notifyOnHazmatEnforcement = false) {
        let line;
        let accessorial: AccessorialForQuote;
        let foundResidentialPickup = false;
        let foundResidentialDelivery = false;
        let foundAppointment = false;
        let foundAppointmentAtPickup = false;
        let foundHazmat = false;
        const shipper = this.shipmentUnifiedForm.value.shipper;
        const consignee = this.shipmentUnifiedForm.value.consignee;
        let madeChange = false;
        let result;
        const linesControl: UntypedFormArray = this.shipmentUnifiedForm.get('accessorials') as UntypedFormArray;
        const accessorials: Array<AccessorialForQuote> = linesControl.value;
        let accl;

        for (line of this.shipmentUnifiedForm.value.productLines) {
            if (line.hazmat) {
                accessorial = _.find(this.availableAccessorials, (acc: AccessorialForQuote) => acc.code === HAZMAT_CODE);
                result = this.addAccessorialToForm(accessorials, accessorial);
                if (result.modified) {
                    this.shipmentUnifiedForm.get('accessorials').setValue(result.accessorials);
                    if (notifyOnHazmatEnforcement) {
                        this._notificationService.notifyWarning({ title: 'Hazmat Enforcement', message: `Hazmat accessorial required for ${line.name}.` });
                    }
                }
                break;
            }
        }

        for (accessorial of this.shipmentUnifiedForm.value.accessorials) {
            switch (accessorial.code) {
                case RESIDENTIALPICKUP_CODE:
                    foundResidentialPickup = true;
                    break;
                case RESIDENTIALDELIVERY_CODE:
                    foundResidentialDelivery = true;
                    break;
                case APPOINTMENT_CODE:
                    foundAppointment = true;
                    break;
                case APPOINTMENTATPICKUP_CODE:
                    foundAppointmentAtPickup = true;
                    break;
                case HAZMAT_CODE:
                    foundHazmat = true;
                    break;
            }
        }

        this.isHazmatShipment = foundHazmat;

        if (foundResidentialPickup && (shipper.commercialresidential !== 'R')) {
            madeChange = true;
            shipper.commercialresidential = 'R';
        } else if (!foundResidentialPickup && (shipper.commmercialresidential !== 'C')) {
            madeChange = true;
            shipper.commercialresidential = 'C';
        }

        if (foundResidentialDelivery && (consignee.commercialresidential !== 'R')) {
            madeChange = true;
            consignee.commercialresidential = 'R';
        } else if (!foundResidentialDelivery && (consignee.commercialresidential !== 'C')) {
            madeChange = true;
            consignee.commercialresidential = 'C';
        }
        if (foundAppointment && !consignee.callappt) {
            madeChange = true;
            consignee.callappt = 1;
        } else if (!foundAppointment && consignee.callappt) {
            madeChange = true;
            consignee.callappt = 0;
        }
        if (foundAppointmentAtPickup && !shipper.callappt) {
            madeChange = true;
            shipper.callappt = 1;
        } else if (!foundAppointmentAtPickup && shipper.callappt) {
            madeChange = true;
            shipper.callappt = 0;
        }

        if (madeChange) {
            this.shipmentUnifiedForm.get('shipper').setValue(shipper);
            this.shipmentUnifiedForm.get('consignee').setValue(consignee);
        }

        if (this.quotedShipment && this.lastQuoteResponse && !this._shipmentService.lastShipmentIsPlaced()) {
            accl = this._shipmentService.accessorialsTransform(this.quotedShipment);
            if (!_.isEqual(_.sortBy(accl, (acc) => acc.code), _.sortBy(this.shipmentUnifiedForm.value.accessorials, (acc) => acc.code))) {
                this.setQuoteReRateWarning(true);
            }
        }
    }

    private contactChanges(contactType: string) {
        const contact = this.shipmentUnifiedForm.value[contactType];
        let accessorial: AccessorialForQuote;
        let accessorialCode;
        let changedApptOrCommRes = false;
        const linesControl: UntypedFormArray = this.shipmentUnifiedForm.get('accessorials') as UntypedFormArray;
        let accessorials: Array<AccessorialForQuote> = linesControl.value;
        let updatedAccessorials = false;
        let result;
        let showDialog = false;

        if (this[contactType] === undefined) {
            this[contactType] = _.cloneDeep(contact);
        }

        if (this.quotedShipment && this.lastQuoteResponse && contact && !this._shipmentService.lastShipmentIsPlaced()) {
            if (contactType === 'shipper') {
                showDialog =  (contact.zip !== this.quotedShipment.Main.shipper_zip) ||
                    (contact.country !== this.quotedShipment.Main.shipper_country) ||
                    (contact.callappt !== this.quotedShipment.Main.shipperCallAppt) ||
                    (contact.commercialresidential !== this.quotedShipment.Main.shipperCommercialResidential);
            } else {
                showDialog =  (contact.zip !== this.quotedShipment.Main.consignee_zip) ||
                    (contact.country !== this.quotedShipment.Main.consignee_country) ||
                    (contact.callappt !== this.quotedShipment.Main.consigneeCallAppt) ||
                    (contact.commercialresidential !== this.quotedShipment.Main.consigneeCommercialResidential);
            }
        }

        if (contactType === 'consignee') {
            accessorialCode = 'APPOINTMENT';
        } else {
            accessorialCode = 'APPOINTMENTATPICKUP';
        }

        if (this[contactType].callappt !== contact.callappt) {
            changedApptOrCommRes = true;
            accessorial = _.find(this.availableAccessorials, (acc: AccessorialForQuote) => acc.code === accessorialCode);
            if (contact.callappt) {
                result = this.addAccessorialToForm(accessorials, accessorial);
                updatedAccessorials = updatedAccessorials || result.modified;
                accessorials = result.accessorials;
            } else {
                result = this.removeAccessorialFromForm(accessorials, accessorial);
                updatedAccessorials = updatedAccessorials || result.modified;
                accessorials = result.accessorials;
            }
        }

        if (this[contactType].commercialresidential !== contact.commercialresidential) {
            changedApptOrCommRes = true;
            accessorialCode = (contactType === 'shipper') ? 'RESIDENTIALPICKUP' : 'RESIDENTIALDELIVERY';
            accessorial = _.find(this.availableAccessorials, (acc: AccessorialForQuote) => acc.code === accessorialCode);
            if (contact.commercialresidential === 'R') {
                result = this.addAccessorialToForm(accessorials, accessorial);
                updatedAccessorials = updatedAccessorials || result.modified;
                accessorials = result.accessorials;
            } else {
                result = this.removeAccessorialFromForm(accessorials, accessorial);
                updatedAccessorials = updatedAccessorials || result.modified;
                accessorials = result.accessorials;
            }
        }

        if (updatedAccessorials) {
            this.shipmentUnifiedForm.get('accessorials').setValue(accessorials);
        }

        if (changedApptOrCommRes) {
            this[contactType] = _.cloneDeep(contact);
        }

        if (showDialog) {
            this.setQuoteReRateWarning(true);
        }
    }

    private setQuoteReRateWarning(val) {
        if (!val || !this.skipAcclChange) {
            this.isQuoteReRateWarningShown = val;
        } else {
            this.skipAcclChange = false;
        }
    }

    private checkTsa(changes) {
        let showDialog = false;
        if (this.quotedShipment && this.lastQuoteResponse && !this._shipmentService.lastShipmentIsPlaced()) {
            showDialog = (changes !== this.quotedShipment.Quote.tsa);
        }
        if (showDialog) {
            this.setQuoteReRateWarning(true);
        }
    }

    private checkPickupDate(changes) {
        let showDialog = false;
        if (this.quotedShipment && this.lastQuoteResponse && !this._shipmentService.lastShipmentIsPlaced()) {
            showDialog = (changes !== this.quotedShipment.Quote.shipdate);
        }
        if (showDialog) {
            this.setQuoteReRateWarning(true);
        }
    }

    private checkEquipment(changes) {
        let showDialog = false;
        if (this.quotedShipment && this.lastQuoteResponse && !this._shipmentService.lastShipmentIsPlaced()) {
            showDialog = (changes.id !== this.quotedShipment.Main.equipmentType);
        }
        if (showDialog) {
            this.setQuoteReRateWarning(true);
        }
    }

    private checkProductLines() {
        let showDialog = false;
        let productLines;

        if (this.quotedShipment && this.lastQuoteResponse && !this._shipmentService.lastShipmentIsPlaced()) {
            productLines = this.shipmentUnifiedForm.value.productLines;
            productLines = productLines.slice(0, productLines.length-1);
            showDialog = !_.isEqual(productLines, this._shipmentService.productsTransform(this.quotedShipment.Products));
        }

        if (showDialog) {
            this.setQuoteReRateWarning(true);
        }
    }

    public addInsurance() {
        this.isInsuranceModalShown = true;
    }

    public closeInsuranceQuotingModal(insurance) {
        this.isInsuranceModalShown = false;
        if (insurance) {
            this.shipmentUnifiedForm.get('insurance').setValue({
                shipmentValue: insurance.declaredShipmentValue,
                insuranceCharge: insurance.insuranceCharge,
            });
            this.shipmentUnifiedForm.get('insurance').markAsDirty();
        }
    }

    public getShipmentValueForModal() {
        if (this._shipmentService.lastInsurance) {
            return this._shipmentService.lastInsurance.shipmentValue
        } else {
            return this._shipmentService.defaultShipmentValue;
        }
    }
}
