import {
    Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,
} from '@angular/core';
import moment from 'moment';
import {map} from 'rxjs/operators';
import * as DateConstants from '../../constants/datetime.constants';
import {Document} from '../../models/document';
import {DocumentUploadRequest} from '../../models/documentUploadRequest';
import {Subject} from "rxjs";

class File extends Document {
    selected?: boolean;
    expanded?: boolean;
}

@Component({
    selector: 'app-files',
    styleUrls: ['./app-files.component.scss'],
    templateUrl: './app-files.component.html',
    standalone: false
})
export class AppFilesComponent {
    @ViewChild('fileInput')
    fileInput: ElementRef;
    @Input()
    public files: Array<File>;
    @Input()
    public fileElemId = 'fileElem';
    @Input()
    public showSaveButton = true;
    @Output()
    public requestRecordSave = new EventEmitter();
    @Output()
    public requestDelete = new EventEmitter<Document>();
    @Output()
    public requestUpdate = new EventEmitter<any>();
    @Output()
    public requestUpload = new EventEmitter<DocumentUploadRequest>();
    @Output()
    public requestDownload = new EventEmitter<Array<Document>>();

    public allFiles = false;
    public isConfirmDeleteModalVisible = false;
    public fileToDelete;
    public fileToUpdate;
    public isEditDocumentPropertiesModalShown = false;

    constructor() {
    }

    public onFileChange($event) {
        const obs$: Subject<undefined> = new Subject<undefined>();
        let reader;
        let file;
        let idx = 0;
        const files = $event.target.files;
        if (files?.length > 0) {
            obs$.pipe(
                map(() => {
                    file = files[idx];
                    reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        const uploadInfo: DocumentUploadRequest = new DocumentUploadRequest();
                        uploadInfo.file = {
                            filename: file.name,
                            filetype: file.type,
                            value: (reader.result as string).split(',')[1]
                        };
                        this.requestUpload.emit(uploadInfo);
                        if (++idx >= files.length) {
                            // Clear out the fileInput element so new downloads of files with same name(s) can trigger change:
                            this.fileInput.nativeElement.value = '';
                            obs$.complete();
                        } else {
                            obs$.next();
                        }
                    };
                })
            ).subscribe();
            obs$.next();
        }
    }

    public saveRecord() {
        this.requestRecordSave.emit();
    }

    public getDocumentDisplayDate(doc: Document) {
        return moment(doc.attachedDate, DateConstants.DATE_TIME_FORMAT_YYYYMMDDHHmmssS).format(DateConstants.DATE_FORMAT_MMMDDYYY_WITH_SLASHES);
    }

    public showConfirmDeleteModal(theFile: File) {
        this.fileToDelete = theFile;
        this.isConfirmDeleteModalVisible = true;
    }

    public hideConfirmDeleteModal(): void {
        this.isConfirmDeleteModalVisible = false;
    }

    public deleteAttachment(theFile: File) {
        let i;
        this.hideConfirmDeleteModal();
        for (i=0; i<this.files.length; i++) {
            if (this.files[i].id === theFile.id) {
                this.files.splice(i, 1);
                this.fixAllChecked();
                break;
            }
        }
        this.requestDelete.emit(theFile);
    }

    public hideEditDocumentPropertiesModal(theUpdateReq) {
        if (theUpdateReq) {
            this.requestUpdate.emit(theUpdateReq);
        }
        this.isEditDocumentPropertiesModalShown = false;
    }

    public downloadSelectedFiles() {
        let i;
        const selectedAttachments: Array<Document> = new Array<Document>();

        for (i=0; i<this.files.length; i++) {
            if (this.files[i].selected) {
                selectedAttachments.push(this.files[i]);
            }
        }
        if (selectedAttachments.length > 0) {
            this.requestDownload.emit(selectedAttachments);
        }
    }

    public toggleSelectAll() {
        let i;
        this.allFiles = !this.allFiles;
        for (i=0; i<this.files.length; i++) {
            this.files[i].selected = this.allFiles;
        }
    }

    public doSelect(file) {
        let i;
        if (!file.selected) {
            setTimeout(() => this.allFiles = false, 0);
        } else {
            this.fixAllChecked();
        }
    }

    public showProperties(file: Document) {
        this.fileToUpdate = file;
        this.isEditDocumentPropertiesModalShown = true;
    }

    public toggleDescription(file: File) {
        file.expanded = !file.expanded;
    }

    private fixAllChecked() {
        let shouldBe = true;
        let i;

        for (i=0; i<this.files.length; i++) {
            if (!this.files[i].selected) {
                shouldBe = false;
                break;
            }
        }
        setTimeout(() => this.allFiles = shouldBe, 0);
    }
}
