import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

import * as Constants from '../../../constants/constants';
import {UserHelper} from '../../../helpers/userHelper';
import { unsubscribe } from '../../../helpers/utilities';
import {CustomerMasterData} from '../../../models/customer.masterData';
import {LoginSettings} from '../../../models/loginSettings';
import {RoleMasterData} from '../../../models/role.masterData';
import {User} from '../../../models/user';
import {CustomerService} from '../../../services/customer.service';
import {RoleService} from '../../../services/role.service';
import {UserService} from '../../../services/user.service';
import {BreadcrumbService} from "../../../services/breadcrumb.service";
import {AdminButtonPanelService} from "../../../services/adminButtonPanel.service";
import {LoginService} from "../../../services/login.service";
import {SignalsService} from "../../../services/signals.service";

@Component({
    selector: 'app-invite-user',
    styleUrls: ['./invite-user.component.scss'],
    templateUrl: './invite-user.component.html',
    standalone: false
})

export class InviteUserComponent implements OnInit, OnDestroy {
    public inviteForm: UntypedFormGroup;
    public typesArray = ['Single', 'Multi'];
    public isExpirationValueValid: boolean;
    public masterDataRoles: Array<RoleMasterData> = [];
    public masterDataCustomers: Array<CustomerMasterData> = [];
    public userSettings: LoginSettings;
    public canAccessGlobalLevelCustomers: boolean;
    public canAccessUserLevelCustomers: boolean;
    public canAccessGroupLevelCustomers: boolean;
    public userProfile: User;
    public filteredRoles: Array<RoleMasterData> = [];

    get expiration() {
        return this.inviteForm.get('expiration');
    }
    get email() {
        return this.inviteForm.get('email');
    }

    private customerValueChangeSubscription: Subscription;
    private inviteFormChangeSubscription: Subscription;

    constructor(
        private _router: Router,
        private _userHelper: UserHelper,
        private _breadcrumbService: BreadcrumbService,
        private _adminButtonPanelService: AdminButtonPanelService,
        private _customerService: CustomerService,
        private _roleService: RoleService,
        private _userService: UserService,
        private _signalsService: SignalsService,
    ) {}

    ngOnInit() {
        this.masterDataRoles = this._roleService.roles;
        this.masterDataCustomers = this._customerService.customers;

        this._breadcrumbService.addBreadcrumb([
            {
                label: 'Invite User',
                urlValue: 'admin/inviteUser'
            }
        ]);

        this._adminButtonPanelService.showCancelButton(true);
        this._adminButtonPanelService.showSaveButton(false);
        this._adminButtonPanelService.showCustomButton(true);
        this._adminButtonPanelService.setCustomButtonIcon('fa-envelope');
        this._adminButtonPanelService.setCustomButtonLabel('Invite User');

        if (!this.masterDataCustomers.length) {
            this._customerService.getCustomerList().subscribe();
        }

        if (!this.masterDataRoles.length) {
            this._roleService.getRolesList().subscribe();
        }

        this._adminButtonPanelService.register({
            cancelEvent: () => {
                this._router.navigate(['admin/users']);
            },
            customEvent: () => {
                this.submitInvitation();
            },
        });

        this.inviteForm = this._userService.getInviteForm();
        this._adminButtonPanelService.activateCustomButton(this.inviteForm.valid);
        this.inviteFormChangeSubscription = this.inviteForm.valueChanges.subscribe(() => {
            this._adminButtonPanelService.activateCustomButton(this.inviteForm.valid);
        })

        this.userSettings = this._signalsService.loginSettingsSignal();

        this.canAccessGlobalLevelCustomers = this._userHelper.canAccess({ requestedAccess: Constants.ACCESS_TYPES.read, thingToBeAccessed: 'customers', permissions: this.userSettings, accessPermissionLevel: Constants.ACCESS_PERMISSION_LEVELS.global });
        this.canAccessUserLevelCustomers = this._userHelper.canAccess({ requestedAccess: Constants.ACCESS_TYPES.read, thingToBeAccessed: 'customers', permissions: this.userSettings, accessPermissionLevel: Constants.ACCESS_PERMISSION_LEVELS.user });
        this.canAccessGroupLevelCustomers = this._userHelper.canAccess({ requestedAccess: Constants.ACCESS_TYPES.read, thingToBeAccessed: 'customers', permissions: this.userSettings, accessPermissionLevel: Constants.ACCESS_PERMISSION_LEVELS.group });

        this.userProfile = this._signalsService.userSignal();
        if (!this.canAccessGlobalLevelCustomers && (this.canAccessUserLevelCustomers || this.canAccessGroupLevelCustomers)) {
            this.inviteForm.get('custNo').setValue(this.userProfile.customer);
        }

        if (this.inviteForm.get('custNo')) {
            this.customerValueChangeSubscription = this.inviteForm.get('custNo').valueChanges.subscribe((custNo: string) => {
                const customerFormControlValue = this.inviteForm.get('custNo').value;
                this.filteredRoles = this.masterDataRoles.filter((role: RoleMasterData) => role.roleCustNo === customerFormControlValue);
            })
        }
    }

    ngOnDestroy() {
        this._adminButtonPanelService.showCancelButton(false);
        this._adminButtonPanelService.showCustomButton(false);
        this._breadcrumbService.popBreadcrumb();
        unsubscribe(this.customerValueChangeSubscription);
        unsubscribe(this.inviteFormChangeSubscription);
    }

    public submitInvitation(): void {
        this._userService.inviteUserForRegistration(this._userService.getInviteFromForm(this.inviteForm));
    }
}
