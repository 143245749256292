import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {ProductSearchResult} from 'app/models/product.searchResult';
import {NotificationService} from "../../../services/notification.service";
import {ShipmentService} from "../../../services/shipment.service";

@Component({
    selector: 'app-product-search-modal',
    templateUrl: './product-search-modal.component.html',
    styleUrls: ['./product-search-modal.component.scss'],
    standalone: false
})
export class ProductSearchModalComponent implements OnInit {
    @Input() public passedSearchQuery: string;
    @Output() public passProductToRecordCreation = new EventEmitter();
    public productSearchQuery: string = '';
    public searchResults: Array<ProductSearchResult> = [];

    constructor(
        private _shipmentService: ShipmentService,
        private _notificationService: NotificationService
    ) {
    }

    public ngOnInit() {
        this.productSearchQuery = this.passedSearchQuery;
        this.searchProducts();
    }

    public searchProducts(): void {
        this._shipmentService.searchRecordProducts(this.productSearchQuery).subscribe(
            response => {
                if (response.isValid && response.dto && response.dto.searchResults.length) {
                    this.searchResults = response.dto.searchResults[0].entities;
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({
                        response,
                        title: 'Search - Products'
                    });
                }
            },
            (err) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({
                    response: err,
                    title: 'Search - Products'
                });
            }
        );
    }

    public selectProduct(selectedProduct: ProductSearchResult): void {
        this.passProductToRecordCreation.emit(selectedProduct);
    }

    public closeModal(): void {
        this.passProductToRecordCreation.emit('');
    }
}
