import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import { unsubscribe } from '../../../helpers/utilities';
import {Markup} from '../../../models/markup';
import {MarkupRangeType} from '../../../models/markup.rangeType';
import {RateOn} from '../../../models/markupRateOn';
import {MarkupType} from '../../../models/markupType';
import {CommonDataService} from '../../../services/commonData.service';
import {MarkupsService} from '../../../services/markups.service';
import {BreadcrumbService} from "../../../services/breadcrumb.service";
import { AdminButtonPanelService } from 'app/services/adminButtonPanel.service';
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-admin-markups',
    templateUrl: './admin-markups.component.html',
    styleUrls: ['./admin-markups.component.scss'],
    standalone: false
})
export class AdminMarkupsComponent implements OnInit, OnDestroy {
    public markupForm: UntypedFormGroup;
    public markupTypes: Array<MarkupType> = [];
    public rateOnOptions: Array<RateOn> = [];
    public rangeTypes: Array<MarkupRangeType> = [];
    public markupType = new MarkupType();
    public markupId;

    private markupFormChangeSubscription: Subscription;

    get markupName() {
        return this.markupForm.get('markupName');
    }
    get markupDescription() {
        return this.markupForm.get('markupDescription');
    }

    constructor(
        private route: ActivatedRoute,
        private _markupsService: MarkupsService,
        private _notificationService: NotificationService,
        private _breadcrumbService: BreadcrumbService,
        private _adminButtonPanelService: AdminButtonPanelService,
        private _router: Router,
        private _commonDataService: CommonDataService,
    ) {
    }

    public ngOnDestroy(): void {
        this._adminButtonPanelService.showSaveCancelButtons(false);
        this._adminButtonPanelService.showCustomButton(false);
        this._adminButtonPanelService.showCustomButton2(false);
        this._adminButtonPanelService.activateSaveButton(false);
        this._adminButtonPanelService.activateCustomButton2(false);
        unsubscribe(this.markupFormChangeSubscription);
    }

    public async ngOnInit() {
        this.setupButtonPanel();

        this.rateOnOptions = await this._commonDataService.loadedPromise(this._commonDataService.rateOnOptions);
        this.rangeTypes = await this._commonDataService.loadedPromise(this._commonDataService.markupRangeTypes);
        this.markupTypes = await this._commonDataService.loadedPromise(this._commonDataService.markupTypes);

        this.route.params.pipe(
            switchMap(params => {
                this.markupId = params['markupId'];
                return this._markupsService.getMarkupByPath(this.markupId);
            }),
            map(() => {
                if (this._markupsService.getLastMarkupForm()) {
                    this.markupForm = this._markupsService.getLastMarkupForm();
                    this._markupsService.setLastMarkupForm();
                } else {
                    this.markupForm = this._markupsService.getMarkupFormFromLastMarkup();
                }
                this.setBreadcrumb(this.markupId);
                this.markupType = this.findMarkupTypeByName(this.markupForm.controls.markupType.value);
                if (this.markupFormChangeSubscription) {
                    this.markupFormChangeSubscription.unsubscribe();
                }
                this.activateButtons();
                this.markupFormChangeSubscription = this.markupForm.valueChanges.subscribe(() => {
                    this.markupType = this.findMarkupTypeByName(this.markupForm.controls.markupType.value);
                    this.activateButtons();
                })
            }),
            catchError(err => {
                this._notificationService.showNotificationsFromResponseDtoMessages({response: err, title: 'MarkupsService'});
                throw err;
            })
        ).subscribe();
    }

    public addRate() {
        this._markupsService.addNewRateToForm(this.markupForm, this.markupType, this.rateOnOptions[0], this.rangeTypes[0]);
    }

    public deleteRate(idx) {
        this._markupsService.deleteRateFromForm(this.markupForm, idx);
    }

    public addException() {
        this._markupsService.addNewExceptionToForm(this.markupForm, this.markupType, this.rateOnOptions[0], this.rangeTypes[0]);
    }

    public deleteException(idx) {
        this._markupsService.deleteExceptionFromForm(this.markupForm, idx);
    }
    public toggleMarkupActive(): void {
        const markupControl = this.markupForm.get('markupActive');
        markupControl.markAsDirty();
        markupControl.setValue(!markupControl.value);
    }

    public saveChanges(): void {
        if (this.markupForm.invalid) {
            this._notificationService.notifyWarning({title: 'Markup Form', message: 'Invalid Fields in Form'});
            return;
        }
        const markup: Markup = this._markupsService.getMarkupFromForm(this.markupForm);
        if (markup.markupID) {
            this._markupsService.updateMarkup(markup).pipe(
                map((updatedMarkup: Markup) => {
                    this._markupsService.getMarkupList().subscribe();
                    this._markupsService.updateLastMarkup(updatedMarkup);
                    this._markupsService.updateForm(this.markupForm);
                })
            ).subscribe();
        } else {
            this._markupsService.createMarkup(markup).pipe(
                map(() => {
                    this._breadcrumbService.popBreadcrumb();
                    this._markupsService.getMarkupList().subscribe();
                })
            ).subscribe();
        }
    }

    private findMarkupTypeByName(name: string) {
        return this.markupTypes.find((markupType) => (this.markupForm.controls.markupType.value === markupType.displayMarkupType));
    }

    private lastMarkupToForm() {
        this._markupsService.updateForm(this.markupForm);
        this.markupForm.markAsPristine();
    }

    private cloneMarkup() {
        this._markupsService.updateLastMarkup(this._markupsService.getMarkupFromForm(this.markupForm));
        this._router.navigate(['admin/markups/clone']);
    }

    private newMarkup() {
        this._router.navigate(['admin/markups/new']);
    }

    private setBreadcrumb(markupId) {
        let breadcrumbLabel;
        const breadcrumbUrl = 'admin/markups/' + markupId;

        switch (markupId) {
            case 'new':
                breadcrumbLabel = 'Create Markup';
                break;
            case 'clone':
                breadcrumbLabel = 'Clone Markup';
                break;
            default:
                breadcrumbLabel = this.markupForm.controls.markupName.value;
        }
        this._breadcrumbService.addOrUpdateBreadcrumb({label: breadcrumbLabel, urlValue: breadcrumbUrl});
    }

    private setupButtonPanel() {
        this._adminButtonPanelService.showSaveCancelButtons(true);
        this._adminButtonPanelService.showCustomButton(true);
        this._adminButtonPanelService.setCustomButtonIcon('fa-clone');
        this._adminButtonPanelService.setCustomButtonLabel('Clone');
        this._adminButtonPanelService.showCustomButton2(true);
        this._adminButtonPanelService.setCustomButton2Icon('fa-plus-circle');
        this._adminButtonPanelService.setCustomButton2Label('Create');
        this._adminButtonPanelService.activateCustomButton(false);

        this._adminButtonPanelService.register({
            saveEvent: () => {
                this.saveChanges();
            },
            cancelEvent: () => {
                this.lastMarkupToForm();
            },
            customEvent: () => {
                this.cloneMarkup();
            },
            custom2Event: () => {
                this.newMarkup();
            }
        });
    }

    private activateButtons() {
        if (this.markupForm.controls.markupID.value && this._markupsService.formEqualsLastMarkup(this.markupForm)) {
            this.markupForm.markAsPristine();
        } else {
            this.markupForm.markAsDirty();
        }

        this._adminButtonPanelService.activateSaveButton(this.markupForm.dirty && this.markupForm.valid);
        this._adminButtonPanelService.activateCustomButton(!this.markupForm.dirty && (this.markupForm.controls.markupID.value !== undefined))
        this._adminButtonPanelService.activateCustomButton2(!this.markupForm.dirty);
    }
}
