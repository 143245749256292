import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import * as DateConstants from '../../../../constants/datetime.constants';
import {DateTimeHelper} from '../../../../helpers/datetime.helper';
import {RateOn} from '../../../../models/markupRateOn';
import {MarkupType} from '../../../../models/markupType';
import {RateType} from '../../../../models/rateType';

@Component({
    selector: 'app-admin-markup-rates',
    templateUrl: './admin-markup-rates.component.html',
    styleUrls: ['./admin-markup-rates.component.scss'],
    standalone: false
})
export class AdminMarkupRatesComponent {
    @Input()
    public ratesFormArray: UntypedFormArray;
    @Input()
    public rateOnOptions: Array<RateOn>;
    @Input()
    public markupTypes: Array<MarkupType>;
    @Input()
    public markupType: MarkupType;

    @Output()
    add: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @Output()
    delete: EventEmitter<number> = new EventEmitter<number>();

    public isDatePickerShown = false;
    public selectedDateFormGroup: UntypedFormGroup;
    public markupDisplayDateFormat: string = DateConstants.UNIFORM_DATE_DISPLAY.format;

    constructor(
        public _dateHelper: DateTimeHelper,
    ) {}

    public showDatePicker(group): void {
        this.selectedDateFormGroup = group;
        this.isDatePickerShown = true;
    }

    public hideDatePicker({selectedDate, selectedEndDate}: { selectedDate: string, selectedEndDate?: string }): void {
        if (selectedDate) {
            this.selectedDateFormGroup.get('effective').setValue(selectedDate);
        }

        if (selectedEndDate) {
            this.selectedDateFormGroup.get('end').setValue(selectedEndDate);
        }

        this.isDatePickerShown = false;
    }

    public getRateIcon(rateTypeId) {
        const foundRateType: RateType = this.markupType.rateTypes.find((rateType) => (rateType.rateTypeID === rateTypeId));
        return (foundRateType) ? foundRateType.RateTypeIcon : undefined;
    }

    public doAddEvent($event: MouseEvent) {
        this.add.emit($event);
    }

    public doDeleteEvent(idx) {
        this.delete.emit(idx);
    }
}
