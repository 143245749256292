{
  "name": "cts-shiprite",
  "version": "25.01.00",
  "scripts": {
    "analyze": "npm run analyze:main",
    "analyze:main": "npm-run-all -s clean:dist build:prod analyze:sme:main",
    "analyze:polyfills": "npm-run-all -s clean:dist build:prod analyze:sme:polyfills",
    "analyze:vendor": "npm-run-all -s clean:dist build:prod analyze:sme:vendor",
    "analyze:sme:main": "source-map-explorer ./dist/main.*.bundle.js",
    "analyze:sme:polyfills": "source-map-explorer ./dist/polyfills.bundle.js",
    "analyze:sme:vendor": "source-map-explorer ./dist/vendor.bundle.js",
    "appSettings:alpha": "mv appSettings.json appSettings.local.json && mv appSettings.alpha.json appSettings.json",
    "appSettings:dev": "mv appSettings.json appSettings.local.json && mv appSettings.dev.json appSettings.json",
    "appSettings:test": "mv appSettings.json appSettings.local.json && mv appSettings.test.json appSettings.json",
    "appSettings:prod": "mv appSettings.json appSettings.local.json && mv appSettings.prod.json appSettings.json",
    "build:alpha": "ng build --configuration production --configuration=alpha",
    "build:mock": "ng build --dev --configuration=mock",
    "build:dev": "ng build --configuration=dev",
    "build:test": "ng build --configuration production --configuration=test",
    "build:prod": "ng build --configuration production --configuration=production",
    "bump:major": "npm version major",
    "bump:minor": "npm version minor",
    "bump:patch": "npm version patch",
    "bump:prerelease": "npm version prerelease --no-git-tag-version",
    "clean": "rimraf -- dist/* test/e2e-testresults/* node_modules ",
    "clean:dist": "rimraf -- dist/*",
    "commit": "git commit -am ",
    "commit:example": "echo-cli 'npm run commit -- \"my commit message\"'",
    "e2e": "ng e2e --configuration=dev",
    "env": "env",
    "lint": "ng lint",
    "jenkins:ci": "npm run tag:build:ci && npm run make:dev",
    "jenkins:create:release": "npm run tag:build:release && npm run merge:master2release",
    "jenkins:deploy:test": "npm run make:test",
    "jenkins:deploy:prod": "npm run make:prod",
    "make:mock": "npm run clean:dist && npm run lint && npm run build:mock",
    "make:dev": "npm run clean:dist && npm run lint && npm run build:dev",
    "make:test": "npm run clean:dist && npm run lint && npm run build:test",
    "make:prod": "npm run clean:dist && npm run lint && npm run build:prod",
    "make:alpha": "npm run clean:dist && npm run lint && npm run build:alpha",
    "merge:master2release": "git checkout release && git merge master --no-edit && git push origin release",
    "ng": "ng",
    "precommit": "npm run lint && npm run bump:prerelease",
    "pree2e": "webdriver-manager update --standalone",
    "postcommit": "git push origin master",
    "smoketest": "npm run smoketest:dev",
    "smoketest:dev": "npm run e2e",
    "smoketest:live": "npm run smoketest:live-server",
    "smoketest:mock": "npm-run-all -p -r start:mock e2e",
    "smoketest:live-server": "npm-run-all -s build:prod smoketest:live-server:run",
    "smoketest:live-server:run": "npm-run-all -p -r smoketest:live-server:dist e2e",
    "smoketest:live-server:dist": "cd dist && live-server",
    "start": "npm run lint && ng serve --configuration=local",
    "start:vb": "npm run ng serve -- --host=0.0.0.0 --configuration=local",
    "start:vbprod": "npm run ng serve -- --host=0.0.0.0 --configuration=prod",
    "start:alpha": "npm run lint && ng serve --configuration=alpha",
    "start:mock": "npm run lint && ng serve --configuration=mock",
    "start:mock:nolint": "ng serve --configuration=mock",
    "tag:build:ci": "update-version --build ${BUILD_NUMBER}",
    "tag:build:release": "npm run tag-release:patch",
    "tag-release:major": "npm run bump:major && git push origin HEAD:master --follow-tags",
    "tag-release:minor": "npm run bump:minor && git push origin HEAD:master --follow-tags",
    "tag-release:patch": "npm run bump:patch && git push origin HEAD:master --follow-tags",
    "test": "npm run lint && ng test",
    "todo": "node todo.config.js"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.0.4",
    "@angular/cdk": "^19.0.3",
    "@angular/common": "19.0.4",
    "@angular/compiler": "19.0.4",
    "@angular/core": "19.0.4",
    "@angular/forms": "19.0.4",
    "@angular/platform-browser": "19.0.4",
    "@angular/platform-browser-dynamic": "19.0.4",
    "@angular/router": "19.0.4",
    "@auth0/angular-jwt": "^5.0.2",
    "file-saver-es": "^2.0.5",
    "lodash-es": "4.17.21",
    "mkdirp": "0.5.1",
    "moment": "^2.29.1",
    "ng2-dragula": "^5.1.0",
    "ng2-pdf-viewer": "^10.0.0",
    "ngx-toastr": "^18.0.0",
    "rxjs": "^6.6.7",
    "tslib": "^2.0.0",
    "webpack": "^5.30.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.0.5",
    "@angular-devkit/build-optimizer": "0.0.26",
    "@angular-devkit/core": "19.0.5",
    "@angular-eslint/builder": "^18.4.3",
    "@angular-eslint/eslint-plugin": "^18.4.3",
    "@angular-eslint/eslint-plugin-template": "^18.4.3",
    "@angular-eslint/schematics": "^18.4.3",
    "@angular-eslint/template-parser": "^18.4.3",
    "@angular/cli": "^19.0.5",
    "@angular/compiler-cli": "19.0.4",
    "@angular/language-service": "19.0.4",
    "@types/file-saver": "^1.3.0",
    "@types/jasmine": "~3.6.0",
    "@types/lodash": "4.14.108",
    "@types/node": "^12.11.1",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/utils": "^7.2.0",
    "@typescript-eslint/types": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "echo-cli": "~2.0.0",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "2.25.4",
    "eslint-plugin-jsdoc": "43.0.5",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "jasmine-core": "^3.8.0",
    "jasmine-reporters": "~2.2.1",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.3.4",
    "karma-chrome-launcher": "~3.1.0",
    "karma-cli": "~2.0.0",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "karma-junit-reporter": "~1.2.0",
    "npm-run-all": "~4.1.1",
    "protractor": "~7.0.0",
    "protractor-http-mock": "~0.10.0",
    "rimraf": "^2.6.2",
    "ts-node": "~5.0.1",
    "typescript": "~5.5.4",
    "update-version": "~1.0.2"
  },
  "overrides": {
    "ng2-dragula": {
      "@angular/common": "$@angular/common",
      "@angular/core": "$@angular/core",
      "@angular/animations": "$@angular/animations"
    }
  }
}
