import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import * as DateConstants from '../../../constants/datetime.constants';

@Component({
    selector: 'app-quoting-ship-date-selector-modal',
    templateUrl: './quoting-ship-date-selector-modal.component.html',
    styleUrls: ['./quoting-ship-date-selector-modal.component.scss'],
    standalone: false
})
export class QuotingShipDateSelectorModalComponent implements OnInit {
  public myDatePickerOptions: any;
  @Input() public dateRange: boolean;
  @Input() public dateInputValue: string;
  @Output() public closeShipDateSelectorModal = new EventEmitter();

  public parsedDate: string;
  constructor() { }

  ngOnInit() {
    this.myDatePickerOptions = {
      dateFormat: DateConstants.DATE_FORMAT_yyyymmdd,
      showInputField: false,
      showClearDateBtn: false,
      inline: true,
      showTodayBtn: false,
      yearSelector: true,
      sunHighlight: false,
      dayLabels: { su: 'SU', mo: 'MO', tu: 'TU', we: 'WE', th: 'TH', fr: 'FR', sa: 'SA' },
      editableDateField: true,
      selectorHeight: '24em',
      selectorWidth: '24em'
    }
  }

  public onDateChanged(selectedDateEvent: any): void {
    const unparsedDateObject = selectedDateEvent.date;
    this.parsedDate = `${unparsedDateObject.year}-${unparsedDateObject.month}-${unparsedDateObject.day}`;
  }

  public closeModal(): void {
    this.closeShipDateSelectorModal.emit('');
  }

  public passParsedDateToParent(): void {
    this.closeShipDateSelectorModal.emit(this.parsedDate);
  }
}
