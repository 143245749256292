import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
    selector: 'app-markup-exception-description-modal',
    templateUrl: './markup-exception-description-modal.component.html',
    styleUrls: ['./markup-exception-description-modal.component.scss'],
    standalone: false
})
export class MarkupExceptionDescriptionModalComponent implements OnInit {
    @Input() selectedMarkupExceptionFormGroup: UntypedFormGroup;
    @Output() hideModal = new EventEmitter<void>();

    public exceptionDescription: string = '';

    constructor() {
    }

    ngOnInit() {
        if (this.selectedMarkupExceptionFormGroup) {
            if (this.selectedMarkupExceptionFormGroup.get('description')) {
                this.exceptionDescription = this.selectedMarkupExceptionFormGroup.value.description;
            } else if (this.selectedMarkupExceptionFormGroup.get('exceptionDescription')) {
                this.exceptionDescription = this.selectedMarkupExceptionFormGroup.value.exceptionDescription;
            }
        }
    }

    public closeModal(): void {
        this.hideModal.emit();
    }

    public updateMarkupDescription(): void {
        let exceptionDescriptionFormControl: UntypedFormControl;

        if (this.selectedMarkupExceptionFormGroup) {
            exceptionDescriptionFormControl = this.selectedMarkupExceptionFormGroup.get('description') as UntypedFormControl;
            if (!exceptionDescriptionFormControl) {
                exceptionDescriptionFormControl = this.selectedMarkupExceptionFormGroup.get('exceptionDescription') as UntypedFormControl;
            }
            if (exceptionDescriptionFormControl) {
                exceptionDescriptionFormControl.markAsDirty();
                exceptionDescriptionFormControl.setValue(this.exceptionDescription);
            }
        }

        this.closeModal();
    }
}
