import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSizePipe',
    standalone: false
})
export class FileSizePipe implements PipeTransform {

    transform(size: number): string {
        if (size < 1000) {
            return size + '';
        } else if (1000 <= size && size < 1000000) {
            return Math.round(size / 1000) + 'KB';
        } else if (1000000 <= size && size < 1000000000) {
            return Math.round(size / 1000000) + 'MB';
        } else {
            return size + '';
        }
    }
}
