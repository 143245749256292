import {
    Component,
    EventEmitter,
    Input,
    OnChanges, OnInit,
    Output, ViewChild,
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import * as _ from 'lodash-es';
import {map, switchMap} from 'rxjs/operators';
import * as RecordCreationConstants from '../../constants/recordCreation.constants';
import * as SearchConstants from '../../constants/searchCriteria';
import {UserHelper} from '../../helpers/userHelper';
import {ContactMasterData} from '../../models/contact.masterData';
import {ContactSearchResult} from '../../models/contact.searchResult';
import {RadioValue} from '../../models/radioValue';
import {ZipSearchResult} from '../../models/zip.searchResult';
import {CommonDataService} from '../../services/commonData.service';
import {ContactService} from '../../services/contact.service';
import {contactMasterDataToContactSearchResult} from '../../helpers/utilities';

@Component({
    selector: 'app-contact',
    styleUrls: ['./app-contact.component.scss'],
    templateUrl: './app-contact.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppContactComponent,
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: AppContactComponent,
            multi: true
        }
    ],
    standalone: false
})
export class AppContactComponent implements OnInit, ControlValueAccessor {
    @Input()
    public contactType = SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER;
    @Input()
    public showVlscfs = false;
    @Input()
    public showSecondAddressLine = false;
    @Input()
    public showAliasLine = false;
    @Input()
    public tabindex = 0;
    @Input()
    public highlightInvalids = false;
    @Output()
    public dialogShown = new EventEmitter();
    @Output()
    public savedContact = new EventEmitter<ContactSearchResult>();

    @ViewChild('stateModel', { static: false }) stateModel: NgModel;
    @ViewChild('countryModel', { static: false }) countryModel: NgModel;

    public vlscfsContactType = RecordCreationConstants.VANGUARD_CFS_CONTACT_TYPE.ORIGIN;
    public countries = [];
    public states = [];
    public contact: ContactSearchResult = new ContactSearchResult();
    public listStateObj;
    public listCountryObj;
    public appointmentCheckbox;
    public contactFields = SearchConstants.SEARCH_CRITERIA_type;
    public value: ContactSearchResult;

    public radioGroupValues: Array<RadioValue> = [
        {
            value: 'R',
            label: '<u>R</u>esidential',
            shortcut: 'r'
        },
        {
            value: 'C',
            label: '<u>C</u>ommercial',
            shortcut: 'c'
        },
    ];

    private loadedArrays = new Promise<void>((resolve) => {
        if ((this.states.length > 0) && (this.countries.length > 0)) {
            resolve();
        } else {
            this.waitForArrays(resolve);
        }
    });

    constructor(private _commonDataService: CommonDataService,
                private _contactService: ContactService,
                private _userHelper: UserHelper) {
    }

    public async ngOnInit() {
        this.countries = _.cloneDeep(await this._commonDataService.loadedPromise(this._commonDataService.countries));
        this.countries.unshift({countryCode: ''});

        this.states = _.cloneDeep(await this._commonDataService.loadedPromise(this._commonDataService.states));
        this.states.unshift({Code: ''});

        this.vlscfsContactType = (this.contactType === SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER ?
                RecordCreationConstants.VANGUARD_CFS_CONTACT_TYPE.ORIGIN :
                RecordCreationConstants.VANGUARD_CFS_CONTACT_TYPE.DESTINATION)
    }

    public validate(c: UntypedFormControl) {
        return this.validateFields();
    }

    public fixUndefined(field) {
        let defaultVal;
        if (!this.value[field]) {
            switch (field) {
                case 'hoursopen':
                    defaultVal = '00:00:00';
                    break;
                case 'hoursclose':
                    defaultVal = '12:00:00';
                    break;
                case 'commercialresidential':
                    defaultVal = 'C';
                    break;
                case 'callappt':
                    defaultVal = 0;
                    break;
                default:
                    defaultVal = '';
                    break;
            }
            this.contact[field] = defaultVal;
            this.value[field] = defaultVal;
            return true;
        }

        this.contact[field] = this.value[field];
        return false;
    }

    public async writeValue(value) {
        let fixedUndefined = false;

        this.value = value;

        fixedUndefined = this.fixUndefined('name') || fixedUndefined;
        fixedUndefined = this.fixUndefined('name2') || fixedUndefined;
        fixedUndefined = this.fixUndefined('address1') || fixedUndefined;
        fixedUndefined = this.fixUndefined('address2') || fixedUndefined;
        fixedUndefined = this.fixUndefined('city') || fixedUndefined;
        fixedUndefined = this.fixUndefined('state') || fixedUndefined;
        fixedUndefined = this.fixUndefined('zip') || fixedUndefined;
        fixedUndefined = this.fixUndefined('country') || fixedUndefined;
        fixedUndefined = this.fixUndefined('contact') || fixedUndefined;
        fixedUndefined = this.fixUndefined('phone') || fixedUndefined;
        fixedUndefined = this.fixUndefined('fax') || fixedUndefined;
        fixedUndefined = this.fixUndefined('email') || fixedUndefined;
        fixedUndefined = this.fixUndefined('hoursopen') || fixedUndefined;
        fixedUndefined = this.fixUndefined('hoursclose') || fixedUndefined;
        fixedUndefined = this.fixUndefined('commercialresidential') || fixedUndefined;
        fixedUndefined = this.fixUndefined('callappt') || fixedUndefined;

        this.value.contact_billto = 0;
        this.value.contact_thirdparty = 0;
        this.value.default_contact = 0;
        this.value.contact_consignee = (this.contactType === SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER) ? 0 : 1;
        this.value.contact_shipper = (this.contactType === SearchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE) ? 0 : 1;

        fixedUndefined = (!this.value.customer) || fixedUndefined;
        if (!this.value.customer) {
            this.value.customer = this._userHelper.getUserCustomer();
        }

        await this.loadedArrays;
        this.listStateObj = _.find(this.states, (state) => state.Code === this.contact.state);
        this.listCountryObj = _.find(this.countries, (country) => country.countryCode === this.contact.country);
        this.appointmentCheckbox = (this.contact.callappt !== 0);

//        if (fixedUndefined) {
//            this.valueChanged(this.value);
//        }
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public contactChanged($event: ContactSearchResult) {
        if ($event) {
            this.value.id = this.contact.id = $event.id;
            this.value.contactsid = this.contact.contactsid = $event.contactsid;
            this.value.active = this.contact.active = $event.active;
            this.value.gl_code = this.contact.gl_code = $event.gl_code;
            this.value.gl_code2 = this.contact.gl_code2 = $event.gl_code2;
            this.value.name = this.contact.name = $event.name ? $event.name : '';
            this.value.name2 = this.contact.name2 = $event.name2 ? $event.name2 : '';
            this.value.address1 = this.contact.address1 = $event.address1 ? $event.address1 : '';
            this.value.address2 = this.contact.address2 = $event.address2 ? $event.address2 : '';
            this.value.city = this.contact.city = $event.city ? $event.city : '';
            this.value.state = this.contact.state = $event.state ? $event.state : '';
            this.listStateObj = _.find(this.states, (state) => state.Code === this.value.state);
            this.value.zip = this.contact.zip = $event.zip ? $event.zip : '';
            this.value.country = this.contact.country = $event.country ? $event.country : '';
            this.listCountryObj = _.find(this.countries, (country) => country.countryCode === this.value.country);
            this.value.contact = this.contact.contact = $event.contact ? $event.contact : '';
            this.value.phone = this.contact.phone = $event.phone ? $event.phone : '';
            this.value.fax = this.contact.fax = $event.fax ? $event.fax : '';
            this.value.email = this.contact.email = $event.email ? $event.email : '';
            this.value.hoursopen = this.contact.hoursopen = $event.hoursopen ? $event.hoursopen : '00:00:00';
            this.value.hoursclose = this.contact.hoursclose = $event.hoursclose ? $event.hoursclose : '12:00:00';
            this.value.commercialresidential = this.contact.commercialresidential = $event.commercialresidential ? $event.commercialresidential : 'C';
            this.value.callappt = $event.callappt;
            this.appointmentCheckbox = (this.value.callappt !== 0);

            this.valueChanged(this.value);
        }
    }

    public getContactTypeTitle() {
        switch (this.contactType) {
            case SearchConstants.SEARCH_CRITERIA_type.CONTACT_SHIPPER:
                return 'Shipper';
            case SearchConstants.SEARCH_CRITERIA_type.CONTACT_CONSIGNEE:
                return 'Consignee';
        }
        return 'Unknown';
    }

    public updateState($event) {
        this.listStateObj = $event;
        this.value.state = this.contact.state = $event.Code;
        this.valueChanged(this.value);
    }

    public updateCountry($event) {
        this.listCountryObj = $event;
        this.value.country = this.contact.country = $event.countryCode;
        this.valueChanged(this.value);
    }

    public updateField(name: string, $event) {
        this.value[name] = this.contact[name] = $event;
        this.valueChanged(this.value);
    }

    public updateAppointment($event) {
        if ($event !== undefined) {
            this.appointmentCheckbox = $event;
            this.value.callappt = this.contact.callappt = ($event ? 1 : 0);
            this.valueChanged(this.value);
        }
    }

    public getCountryCode(country) {
        if (country) {
            return country.countryCode;
        }
        return undefined;
    }

    public getStateCode(state) {
        if (state) {
            return state.Code;
        }
        return undefined;
    }

    public zipChanged($event: ZipSearchResult) {
        this.contact.zip = this.value.zip = $event.zip_code.toString();
        this.contact.city = this.value.city = $event.city;
        this.contact.state = this.value.state = $event.state;
        this.contact.country = this.value.country = $event.countryname;
        this.listStateObj = _.find(this.states, (state) => state.Code === this.value.state);
        this.listCountryObj = _.find(this.countries, (country) => country.countryCode === this.value.country);

        this.valueChanged(this.value);
    }

    public saveContact() {
        const contact = this.contactSearchResultToContactMasterData(this.value);
        let saveContact$;
        if (!contact.contactsId) {
            saveContact$ = this._contactService.createContact(contact).pipe(
                switchMap(result => this._contactService.getContact(result.locationId)),
                map(result => result[0])
            );
        } else {
            saveContact$ = this._contactService.updateContact(contact).pipe(
                map(result => result[0])
            );
        }

        saveContact$.subscribe((result) => {
            const csr = contactMasterDataToContactSearchResult(result);
            this.writeValue(csr);
            this.valueChanged(this.value);
            this.savedContact.emit(csr);
        });
    }

    public clearContact() {
        this.writeValue(new ContactSearchResult());
        this.valueChanged(this.value);
    }

    public getClassFor(ngModel: NgModel) {
        if ((ngModel === this.stateModel) && (ngModel.value?.Code === '')) {
            return {
                error: this.highlightInvalids,
            }
        } else if ((ngModel === this.countryModel) && (ngModel.value?.countryCode === '')) {
            return {
                error: this.highlightInvalids,
            }
        }
        return {
            error: this.highlightInvalids && ngModel.invalid,
        };
    }

    private valueChanged = (__: any) => {};

    private validateFields() {
        if (!this.contact.name || !this.contact.address1 || !this.contact.city || !this.contact.state || ! this.contact.zip ||
            !this.contact.country || !this.contact.contact || !this.contact.phone
        ) {
            return {invalidContact: true};
        } else {
            return null;
        }
    }

    private contactSearchResultToContactMasterData(value: ContactSearchResult) {
        const mcd = new ContactMasterData();

        mcd.contactsId = value.contactsid;
        mcd.Contact = value.contact;
        mcd.default_contact = value.default_contact;
        mcd.country = value.country;
        mcd.Fax = value.fax;
        mcd.Customer = value.customer;
        mcd.contact_thirdparty = value.contact_thirdparty;
        mcd.contact_shipper = value.contact_shipper;
        mcd.contact_consignee = value.contact_consignee;
        mcd.contact_billto = value.contact_billto;
        mcd.commercialResidential = value.commercialresidential;
        mcd.Phone = value.phone;
        mcd.callAppt = value.callappt;
        mcd.hoursClose = value.hoursclose;
        mcd.hoursOpen = value.hoursopen;
        mcd.Email = value.email;
        mcd.Zip = '' + value.zip;
        mcd.State = value.state;
        mcd.City = value.city;
        mcd.Address2 = value.address2;
        mcd.Address1 = value.address1;
        mcd.Name2 = value.name2;
        mcd.Name = value.name;
        mcd.ID = value.id;
        mcd.Active = value.active;

        return mcd;
    }

    private waitForArrays(resolve) {
        setTimeout(() => {
            if ((this.states.length > 0) && (this.countries.length > 0)) {
                resolve();
            } else {
                this.waitForArrays(resolve);
            }
        }, 100);
    }

}
