import {Component, OnDestroy, OnInit} from '@angular/core';
import * as SearchConstants from '../../../constants/searchCriteria';
import {DashboardHelper} from '../../../helpers/dashboardHelper';
import {Dashboard} from '../../../models/dashboard';
import {SearchRequest} from '../../../models/searchRequest';
import {SearchState} from '../../../models/searchState';
import {SignalsService} from "../../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";
import {SearchService} from "../../../services/search.service";

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    standalone: false
})
export class SearchComponent implements OnInit, OnDestroy {

    public searchResponse: SearchState;
    private searchSubscription: any;

    public searchCriteriaTypes: Array<string>;
    private displayDataSubscription: any;

    public entityFilterTerm: string;
    private searchFilterSubscription: any;

    private appStateSubscription;
    public appState;

    public filteredSearchResultTotal: number;

    private dashboardSubscription;
    private dashboards: Array<Dashboard>;
    private activeDashboard: Dashboard;
    public showOkCancelModal: boolean = false;
    public saveViewFromSearchTitle: string = 'Save Dashboard View Error';
    public saveViewFromSearchMessage: string = 'Your current dashboard has the maximum number of views. Please switch to another dashboard with fewer views before saving';

    private appState$;
    private search$;
    private displayData$;
    private dashboards$;

    constructor(
        private _signalsService: SignalsService,
        private _dashboardHelper: DashboardHelper,
        private _searchService: SearchService
    ) {
        this.appState$ = toObservable(this._signalsService.appStateSignal);
        this.searchResponse = this._signalsService.searchStateSignal();
        this.search$ = toObservable(this._signalsService.searchStateSignal);
        this.displayData$ = toObservable(this._signalsService.displaySignal);
        this.dashboards$ = toObservable(this._signalsService.dashboardsSignal);
    }

    public ngOnInit() {
        this.dashboardSubscription = this.dashboards$.subscribe((dashboards: Array<Dashboard>) => {
            if (dashboards) {
                this.dashboards = dashboards;
            }

            this.activeDashboard = this._dashboardHelper.getActiveDashboard();
        });

        this.appStateSubscription = this.appState$.subscribe(appState => {
            this.appState = appState;
            this.showOkCancelModal = this.appState['modal.isSaveDashboardErrorModalShown'];
        });

        this.searchSubscription = this.search$.subscribe((search: SearchState) => {
            this.searchResponse = search;

            if (this.searchResponse && this.searchResponse.filterTerm) {
                this.filteredSearchResultTotal = this._searchService.getSearchResultTotalByEntityType(this.searchResponse.filterTerm);
            }
        });

        this.displayDataSubscription = this.displayData$.subscribe((displayData) => {
            this.searchCriteriaTypes = displayData.searchCriteriaTypes;
        });
    }

    public ngOnDestroy() {
        this.appStateSubscription.unsubscribe();
        this.searchSubscription.unsubscribe();
        this.displayDataSubscription.unsubscribe();
        this._signalsService.updateAppState({
            'dashboard.createViewBySearch': false,
            'dashboard.editDashboardViewSearch': false
        });
    }

    public saveDashboardViewContent(): void {
        this._searchService.saveDashboardViewContent();
    }

    public closeOkCancelModal() {
        this._signalsService.updateAppState({'modal.isSaveDashboardErrorModalShown': false});
    }

    public getBreadcrumbSearchResultCountText(): string {
        if (!this.searchResponse || !this.searchResponse.searchResults || !this.searchResponse.searchResults.length) {
            return '';
        }

        if (!this._searchService.areSearchResultsFilteredByEntityType()) {
            const searchResultsTotal: number = this._searchService.getSearchResultTotalSum();

            return searchResultsTotal ? `(${searchResultsTotal})` : '';
        } else {
            const filteredEntityType: string = this.searchResponse.filterTerm;
            const searchResultsTotal: number = this._searchService.getSearchResultTotalByEntityType(filteredEntityType);

            return searchResultsTotal ? `(${searchResultsTotal})` : '';
        }
    }

    public getBreadcrumbHeaderText(): string {
        if (!this.searchResponse || !this.searchResponse.searchResults || !this.searchResponse.searchResults.length) {
            return `Global Search`;
        }

        return `Global Search Results ${this.getBreadcrumbSearchResultCountText()}`;
    }

    public handleSearchBarRequestChanges(searchBarRequest: { searchCriteriaType: string, searchQuery: string }): void {
        this._searchService.processGlobalSearch({
            searchCriteria: [
                {
                    type: searchBarRequest.searchCriteriaType,
                    value: searchBarRequest.searchQuery,
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.ALL,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH,
                    from: SearchConstants.SEARCH_CRITERIA_FROM.default,
                    size: SearchConstants.SEARCH_CRITERIA_SIZE.default,
                }
            ]
        });
    }

    public handlePaginationPanelChanges(paginationChanges: { from: number, size: number }): void {
        const paginatedSearchRequest: SearchRequest = {
            searchCriteria: [
                {
                    ...this.searchResponse.searchRequest.searchCriteria[0],
                    from: paginationChanges.from,
                    size: paginationChanges.size,
                },
                ...this.searchResponse.searchRequest.searchCriteria.slice(1)
            ],
        };

        this._searchService.processPaginatedGlobalSearch(paginatedSearchRequest, this.searchResponse.filterTerm);
    }
}
