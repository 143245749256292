import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, NgModel} from '@angular/forms';
import {catchError, map} from 'rxjs/operators';
import {productMasterDataToProduct, searchResultToProductMasterData} from '../../helpers/utilities';
import {Product} from '../../models/product';
import {ProductMasterData} from '../../models/product.masterData';
import {ProductSearchResult} from '../../models/product.searchResult';
import {NotificationService} from "../../services/notification.service";
import {ShipmentService} from "../../services/shipment.service";

@Component({
    selector: 'app-product-input',
    styleUrls: ['./app-product-input.component.scss'],
    templateUrl: './app-product-input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppProductInputComponent,
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: AppProductInputComponent,
            multi: true
        }
    ],
    standalone: false
})
export class AppProductInputComponent implements ControlValueAccessor, OnChanges {
    @Input()
    public isRequired = false;
    @Output()
    public dialogShown = new EventEmitter();

    @ViewChild('inputModel', { static: false }) inputModel: NgModel;

    public inputValue;
    public products: Array<ProductSearchResult> = [];
    public showNewProductsLink = false;
    public showSearchModal = false;
    public showDetailsModal = false;
    public showProductPopup = false;
    public value: ProductMasterData = new ProductMasterData();
    public detailsToShow: Product;
    private productSearchTimeout;
    private timeoutCleared = true;
    private control: UntypedFormControl;

    constructor(private _shipmentService: ShipmentService,
                private _notificationService: NotificationService) { }

    public ngOnChanges(changes) {
        if (changes) {
            this.validateFn = () => this.validateInput();
        }
    }

    public validate(c: UntypedFormControl) {
        if (!this.control) {
            this.control = c;
        }
        return this.validateFn(c);
    }

    public writeValue(value) {
        if (value) {
            this.value = value;
            this.inputValue = this.value.Name;
            setTimeout(() => this.control?.updateValueAndValidity(), 0);
        }
        this.control?.markAsPristine();
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public showAddNewProductModal() {}

    public productSearch($event) {
        let newVal;
        if (this.inputValue !== $event) {
            newVal = new ProductMasterData();
            newVal.Name = $event;
            this.value = newVal;
            this.inputValue = $event;
            this.valueChanged(this.value);
            this.clearRestCall();
        } else {
            return;
        }

        this.productSearchTimeout = setTimeout(() => {
            if (!this.inputValue) {
                this.products = null;
                return;
            }

            this.timeoutCleared = false;
            this._shipmentService.searchRecordProducts(this.inputValue).pipe(
                map((response) => {
                    if (!this.timeoutCleared) {
                        this.showProductPopup = true;
                        if (response.isValid && response.dto && response.dto.searchResults.length) {
                            this.products = response.dto.searchResults[0].entities;
                        } else {
                            this.products = [];
                        }
                    }
                }),
                catchError((err) => {
                    this.products = null;
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'Product Search Error' });
                    throw err;
                })
            ).subscribe();
        }, 500);
    }

    public clearLastSearch() {
        this.clearRestCall();
        setTimeout(() => {
            this.showProductPopup = false;
        }, 200);
    }

    public showProductDetailsModal(showOrHide, productEntryElement?) {
        this.showDetailsModal = showOrHide;
        this.dialogShown.emit(showOrHide);
        if (showOrHide) {
            this.detailsToShow = productMasterDataToProduct(this.value);
        } else {
            productEntryElement.focus();
        }
    }

    public showProductSearchModal() {
        this.dialogShown.emit(true);
        this.showSearchModal = true;
    }

    public updateProduct(product, productEntryElement) {
        this.value = searchResultToProductMasterData(product);
        this.inputValue = this.value.Name;
        this.valueChanged(this.value);
        if (this.showSearchModal) {
            this.dialogShown.emit(false);
        }
        this.showSearchModal = false;
        productEntryElement.focus();
    }

    private validateFn: Function = () => {};

    private validateInput() {
        if (this.inputModel?.invalid) {
            return {invalidProduct: true};
        }
        return null;
    }

    private valueChanged = (_: any) => {};

    private clearRestCall() {
        clearTimeout(this.productSearchTimeout);
        this.timeoutCleared = true;
    }
}
