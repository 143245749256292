import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash-es';
import {QuotedRate} from '../../../models/quotedRate';
import {BookingOption, TerminalRoute} from '../../../models/unifiedShipmentObject';

@Component({
    selector: 'app-terminal-details-modal',
    templateUrl: './terminal-details-modal.component.html',
    styleUrls: ['./terminal-details-modal.component.scss'],
    standalone: false
})

export class TerminalDetailsModalComponent implements OnInit {
    @Input() public bookingOptions: Array<BookingOption>;
    @Input() public rates: Array<QuotedRate>;
    @Output() public cancel: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    @HostListener('document:keydown.escape', ['$event']) clicked(event) {
        event.preventDefault();
        this.cancel.emit();
    }

    public ngOnInit() {
    }

    public getCarrierName() {
        if (this.bookingOptions) {
            return this.bookingOptions[0].LSP.name;
        } else {
            return this.rates[0].name;
        }
    }

    public cancelClicked() {
        this.cancel.emit();
    }

    public getOriginTerminal() {
        if (this.bookingOptions) {
            const bookingSelection: BookingOption = this.bookingOptions[0];
            const originTerminalStop = bookingSelection.terminalDetails[0].originStop;
            const terminals = bookingSelection.terminalDetails[0].terminalRoute;

            return _.find(terminals, (terminal: TerminalRoute) => terminal.terminalStopID === originTerminalStop).terminal;
        } else {
            return this.rates[0].originterminal;
        }
    }

    public getDestinationTerminal() {
        if (this.bookingOptions) {
            const bookingSelection: BookingOption = this.bookingOptions[0];
            const destinationTerminalStop = bookingSelection.terminalDetails[0].destinationStop;
            const terminals = bookingSelection.terminalDetails[0].terminalRoute;

            return _.find(terminals, (terminal: TerminalRoute) => terminal.terminalStopID === destinationTerminalStop).terminal;
        } else {
            return this.rates[0].destinationterminal;
        }
    }
}
