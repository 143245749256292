import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash-es';
import {Observable} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {UserHelper} from '../helpers/userHelper';
import {clearArray} from '../helpers/utilities';
import {SetPassword} from '../models/setPassword';
import {User} from '../models/user';
import {UserMasterData} from '../models/user.masterData';
import {ApiService, REQUEST_TYPE_POST} from './api.service';
import {RegistrationRequest} from "../models/registrationRequest";
import { HttpErrorResponse, HttpRequest } from "@angular/common/http";
import {ResponseDTO} from "../models/responseDto";
import {InviteUserRequest} from "../models/inviteUserRequest";
import {Router} from "@angular/router";
import { EntityService } from './entity.service';
import {NotificationService} from "./notification.service";
import {validateMoneyOrNothing} from "../validators/money.validator";

@Injectable()
export class UserService {
    public users = [];
    private lastUser: User;

    constructor(
        private _entityService: EntityService,
        private _userHelper: UserHelper,
        private _fb: UntypedFormBuilder,
        private _api: ApiService,
        private _notificationService: NotificationService,
        private _router: Router,
    ) {}

    public getUsersList() {
        return this._entityService.getEntityList<UserMasterData>('User').pipe(
            take(1),
            map(list => {
                if (Array.isArray(list)) {
                    clearArray(this.users);
                    this.users.push(...list);
                }
            })
        );
    }

    public getUserByPath(id) {
        switch (id) {
            case 'new':
                return new Observable(subscriber => {
                    this.lastUser = new User();
                    subscriber.next(_.cloneDeep(this.lastUser));
                    subscriber.complete();
                });
            default:
                if (!id) {
                    id = this._userHelper.getUserId()
                }
                return this.getUser(id).pipe(
                    take(1),
                    map(user => {
                        if (Object.keys(user).length > 0) {
                            this.lastUser = user as User;
                        }
                        return _.cloneDeep(this.lastUser);
                    }),
                    catchError(err => {
                        throw err;
                    })
                )
        }
    }

    public deleteUser(id) {
        return this._entityService.deleteEntity(id, 'User', 'deactivate');
    }

    public createUser(user) {
        return this._entityService.createEntity<User>(user, 'User', 'userID', 'name', 'admin/users');
    }

    public updateUser(user) {
        return this._entityService.updateEntity<User>(user, 'User', user.userID, 'name');
    }

    public getUser(id) {
        return this._entityService.getEntity<User>('User', id);
    }

    public getUserFormFromLastUser() {
        const userForm: UntypedFormGroup = this._fb.group({
            name: this.lastUser.name,
            userID: this.lastUser.userID ? this.lastUser.userID : '',
            firstname: new UntypedFormControl(this.lastUser.firstname ? this.lastUser.firstname : '', {
                validators: [
                    Validators.required
                ],
            }),
            lastname: new UntypedFormControl(this.lastUser.lastname ? this.lastUser.lastname : '', {
                validators: [
                    Validators.required
                ],
            }),
            email: new UntypedFormControl(this.lastUser.email ? this.lastUser.email : '', {
                validators: [
                    Validators.required,
                    Validators.pattern('\\S+'),
                ],
            }),
            shipmentValue: new UntypedFormControl(this.lastUser.shipmentValue ? this.lastUser.shipmentValue: '', {
                validators: [
                    validateMoneyOrNothing
                ]
            }),
            customer: new UntypedFormControl(this.lastUser.customer ? this.lastUser.customer : '', {
                validators: [
                    Validators.required
                ],
            }),
            roleID: new UntypedFormControl(this.lastUser.roleID ? this.lastUser.roleID : null, {
                validators: [
                    Validators.required
                ],
            }),
            defaultOriginType: this.lastUser.defaultOriginType ? this.lastUser.defaultOriginType : '',
            defaultDestType: this.lastUser.defaultDestType ? this.lastUser.defaultDestType : '',
            defaultDirection: this.lastUser.defaultDirection ? this.lastUser.defaultDirection : '',
            defaultUoM: this.lastUser.defaultUoM ? this.lastUser.defaultUoM : '',
            defaultCommodity: this.lastUser.defaultCommodity ? this.lastUser.defaultCommodity : '',
            defaultTSA: !!this.lastUser.defaultTSA,
            defaultRecordTab: this.lastUser.defaultRecordTab ? this.lastUser.defaultRecordTab : '',
            preferredRecordDisplayStyle: this.lastUser.preferredRecordDisplayStyle ? this.lastUser.preferredRecordDisplayStyle : '',
            active: !!this.lastUser.active,
            phone: this.lastUser.phone ? this.lastUser.phone : '',
            autoSaveContacts: !!this.lastUser.autoSaveContacts,
            defaultOriginZip: this.lastUser.defaultOriginZip ? this.lastUser.defaultOriginZip : '',
            defaultDestZip: this.lastUser.defaultDestZip ? this.lastUser.defaultDestZip : '',
            defaultClass: this.lastUser.defaultClass ? this.lastUser.defaultClass : '',
            defaultShipper: new UntypedFormControl(this.lastUser.defaultShipper ? this.lastUser.defaultShipper : '', {
                updateOn: 'blur'
            }),
            defaultShipperId: this.lastUser.defaultShipper ? this.lastUser.defaultShipper : '',
            defaultConsignee: new UntypedFormControl(this.lastUser.defaultConsignee ? this.lastUser.defaultConsignee : '', {
                updateOn: 'blur'
            }),
            defaultConsigneeId: this.lastUser.defaultConsignee ? this.lastUser.defaultConsignee : '',
            ccBillingAddress1: this.lastUser.ccBillingAddress1 ? this.lastUser.ccBillingAddress1 : '',
            ccBillingAddress2: this.lastUser.ccBillingAddress2 ? this.lastUser.ccBillingAddress2 : '',
            ccBillingCity: this.lastUser.ccBillingCity ? this.lastUser.ccBillingCity : '',
            ccBillingSt: this.lastUser.ccBillingSt ? this.lastUser.ccBillingSt : '',
            ccBillingZip: this.lastUser.ccBillingZip ? this.lastUser.ccBillingZip : '',
            ccFirstName: this.lastUser.ccFirstName ? this.lastUser.ccFirstName : '',
            ccLastName: this.lastUser.ccLastName ? this.lastUser.ccLastName : '',
            password: new UntypedFormControl(new SetPassword()),
            lockedOut: !!this.lastUser.lockedOut,
        });

        const userloginControl = (this.lastUser.userID) ? new UntypedFormControl(this.lastUser.userlogin ? this.lastUser.userlogin : '') :
            new UntypedFormControl(this.lastUser.userlogin ? this.lastUser.userlogin : '', {
            validators: [
                Validators.required,
                Validators.pattern('\\S+'),
            ],
        });

        userForm.addControl('userlogin', userloginControl);

        return userForm;
    }

    public getInviteForm() {
        return this._fb.group({
            email: new UntypedFormControl('', {
                validators: [
                    Validators.required,
                    Validators.email,
                ]
            }),
            expiration: new UntypedFormControl(null, {
                validators: [
                    Validators.required,
                    Validators.min(2),
                    Validators.max(30),
                ]
            }),
            roleId: new UntypedFormControl('', {
                validators: [
                    Validators.required
                ]
            }),
            custNo: new UntypedFormControl('', {
                validators: [
                    Validators.required
                ]
            }),
        });
    }

    public updateForm(userForm: UntypedFormGroup) {
        userForm.controls.userID.setValue(this.lastUser.userID ? this.lastUser.userID : '');
        userForm.controls.password.setValue(new SetPassword());
        userForm.controls.userlogin.setValue(this.lastUser.userlogin ? this.lastUser.userlogin : '');
        userForm.controls.firstname.setValue(this.lastUser.firstname ? this.lastUser.firstname : '');
        userForm.controls.lastname.setValue(this.lastUser.lastname ? this.lastUser.lastname : '');
        userForm.controls.email.setValue(this.lastUser.email ? this.lastUser.email : '');
        userForm.controls.shipmentValue.setValue(this.lastUser.shipmentValue ? this.lastUser.shipmentValue : '');
        userForm.controls.customer.setValue(this.lastUser.customer ? this.lastUser.customer : '');
        userForm.controls.roleID.setValue(this.lastUser.roleID ? this.lastUser.roleID : null);
        userForm.controls.defaultOriginType.setValue(this.lastUser.defaultOriginType ? this.lastUser.defaultOriginType : '');
        userForm.controls.defaultDestType.setValue(this.lastUser.defaultDestType ? this.lastUser.defaultDestType : '');
        userForm.controls.defaultDirection.setValue(this.lastUser.defaultDirection ? this.lastUser.defaultDirection : '');
        userForm.controls.defaultUoM.setValue(this.lastUser.defaultUoM ? this.lastUser.defaultUoM : '');
        userForm.controls.defaultCommodity.setValue(this.lastUser.defaultCommodity ? this.lastUser.defaultCommodity : '');
        userForm.controls.defaultTSA.setValue(!!this.lastUser.defaultTSA);
        userForm.controls.defaultRecordTab.setValue(this.lastUser.defaultRecordTab ? this.lastUser.defaultRecordTab : '');
        userForm.controls.preferredRecordDisplayStyle.setValue(this.lastUser.preferredRecordDisplayStyle ? this.lastUser.preferredRecordDisplayStyle : '');
        userForm.controls.active.setValue(!!this.lastUser.active);
        userForm.controls.phone.setValue(this.lastUser.phone ? this.lastUser.phone : '');
        userForm.controls.autoSaveContacts.setValue(!!this.lastUser.autoSaveContacts);
        userForm.controls.defaultOriginZip.setValue(this.lastUser.defaultOriginZip ? this.lastUser.defaultOriginZip : '');
        userForm.controls.defaultDestZip.setValue(this.lastUser.defaultDestZip ? this.lastUser.defaultDestZip : '');
        userForm.controls.defaultClass.setValue(this.lastUser.defaultClass ? this.lastUser.defaultClass : '');
        userForm.controls.defaultShipper.setValue(this.lastUser.defaultShipper ? this.lastUser.defaultShipper : '');
        userForm.controls.defaultShipperId.setValue(this.lastUser.defaultShipper ? this.lastUser.defaultShipper : '');
        userForm.controls.defaultConsignee.setValue(this.lastUser.defaultConsignee ? this.lastUser.defaultConsignee : '');
        userForm.controls.defaultConsigneeId.setValue(this.lastUser.defaultConsignee ? this.lastUser.defaultConsignee : '');
        userForm.controls.ccBillingAddress1.setValue(this.lastUser.ccBillingAddress1 ? this.lastUser.ccBillingAddress1 : '');
        userForm.controls.ccBillingAddress2.setValue(this.lastUser.ccBillingAddress2 ? this.lastUser.ccBillingAddress2 : '');
        userForm.controls.ccBillingCity.setValue(this.lastUser.ccBillingCity ? this.lastUser.ccBillingCity : '');
        userForm.controls.ccBillingSt.setValue(this.lastUser.ccBillingSt ? this.lastUser.ccBillingSt : '');
        userForm.controls.ccBillingZip.setValue(this.lastUser.ccBillingZip ? this.lastUser.ccBillingZip : '');
        userForm.controls.ccFirstName.setValue(this.lastUser.ccFirstName ? this.lastUser.ccFirstName : '');
        userForm.controls.ccLastName.setValue(this.lastUser.ccLastName ? this.lastUser.ccLastName : '');
    }

    public getUserFromForm(userForm: UntypedFormGroup) {
        const user: User = _.cloneDeep(this.lastUser);
        const password = userForm.get('password').value.newPasswordValue;

        user.userlogin = userForm.get('userlogin').value;
        // eslint-disable-next-line no-unused-expressions
        (password) ? user.password = password : null;
        user.phone = userForm.get('phone').value;
        user.firstname = userForm.get('firstname').value;
        user.lastname = userForm.get('lastname').value;
        user.email = userForm.get('email').value;
        user.shipmentValue = userForm.get('shipmentValue').value;
        user.active = userForm.get('active').value ? 1 : 0;
        user.customer = userForm.get('customer').value;
        user.roleID = userForm.get('roleID').value;
        /* eslint-disable no-unused-expressions */
        (userForm.get('defaultOriginType').value) ? user.defaultOriginType = userForm.get('defaultOriginType').value : null;
        (userForm.get('defaultDestType').value) ? user.defaultDestType = userForm.get('defaultDestType').value : null;
        (userForm.get('defaultDirection').value) ? user.defaultDirection = userForm.get('defaultDirection').value : null;
        (userForm.get('defaultUoM').value) ? user.defaultUoM = userForm.get('defaultUoM').value : null;
        (userForm.get('defaultCommodity').value) ? user.defaultCommodity = userForm.get('defaultCommodity').value : null;
        /* eslint-disable no-unused-expressions */
        user.defaultTSA = userForm.get('defaultTSA').value ? 1 : 0;
        user.defaultRecordTab = userForm.get('defaultRecordTab').value;
        user.preferredRecordDisplayStyle = userForm.get('preferredRecordDisplayStyle').value;
        user.autoSaveContacts = userForm.get('autoSaveContacts').value;
        user.defaultOriginZip = userForm.get('defaultOriginZip').value;
        user.defaultDestZip = userForm.get('defaultDestZip').value;
        user.defaultClass = userForm.get('defaultClass').value;
        user.defaultShipper = userForm.get('defaultShipperId').value;
        user.defaultConsignee = userForm.get('defaultConsigneeId').value;
        user.ccBillingAddress1 = userForm.get('ccBillingAddress1').value;
        user.ccBillingAddress2 = userForm.get('ccBillingAddress2').value;
        user.ccBillingCity = userForm.get('ccBillingCity').value;
        user.ccBillingSt = userForm.get('ccBillingSt').value;
        user.ccBillingZip = userForm.get('ccBillingZip').value;
        user.ccFirstName = userForm.get('ccFirstName').value;
        user.ccLastName = userForm.get('ccLastName').value;

        return user;
    }

    public getInviteFromForm(inviteForm: UntypedFormGroup) {
        return {
            email: inviteForm.get('email').value,
            expires: inviteForm.get('expiration').value,
            roleId: inviteForm.get('roleId').value,
            custNo: inviteForm.get('custNo').value
        };
    }

    public formEqualsLastUser(userForm: UntypedFormGroup) {
        return (this.lastUser.userlogin === userForm.controls.userlogin.value) &&
            (this.lastUser.firstname === userForm.controls.firstname.value) &&
            (this.lastUser.lastname === userForm.controls.lastname.value) &&
            (this.lastUser.email === userForm.controls.email.value) &&
            (this.lastUser.shipmentValue === +(userForm.controls.shipmentValue.value)) &&
            (this.lastUser.customer === userForm.controls.customer.value) &&
            (this.lastUser.roleID === userForm.controls.roleID.value) &&
            (this.lastUser.defaultOriginType === userForm.controls.defaultOriginType.value) &&
            (this.lastUser.defaultDestType === userForm.controls.defaultDestType.value) &&
            (this.lastUser.defaultDirection === userForm.controls.defaultDirection.value) &&
            (this.lastUser.defaultUoM === userForm.controls.defaultUoM.value) &&
            (this.lastUser.defaultCommodity === userForm.controls.defaultCommodity.value) &&
            (!!this.lastUser.defaultTSA === userForm.controls.defaultTSA.value) &&
            (this.lastUser.defaultRecordTab === userForm.controls.defaultRecordTab.value) &&
            (this.lastUser.preferredRecordDisplayStyle === userForm.controls.preferredRecordDisplayStyle.value) &&
            (!!this.lastUser.active === userForm.controls.active.value) &&
            (this.lastUser.phone === userForm.controls.phone.value) &&
            (!!this.lastUser.autoSaveContacts === userForm.controls.autoSaveContacts.value) &&
            (this.lastUser.defaultOriginZip === userForm.controls.defaultOriginZip.value) &&
            (this.lastUser.defaultDestZip === userForm.controls.defaultDestZip.value) &&
            (this.lastUser.defaultClass === userForm.controls.defaultClass.value) &&
            (this.lastUser.defaultShipper === userForm.controls.defaultShipper.value) &&
            (this.lastUser.defaultConsignee === userForm.controls.defaultConsignee.value) &&
            (this.lastUser.ccBillingAddress1 === userForm.controls.ccBillingAddress1.value) &&
            (this.lastUser.ccBillingAddress2 === userForm.controls.ccBillingAddress2.value) &&
            (this.lastUser.ccBillingCity === userForm.controls.ccBillingCity.value) &&
            (this.lastUser.ccBillingSt === userForm.controls.ccBillingSt.value) &&
            (this.lastUser.ccBillingZip === userForm.controls.ccBillingZip.value) &&
            (this.lastUser.ccFirstName === userForm.controls.ccFirstName.value) &&
            (this.lastUser.ccLastName === userForm.controls.ccLastName.value);
    }

    public updateLastUser(user: User) {
        this.lastUser = user;
    }

    public processUserRegistration(registrationRequest: RegistrationRequest): void {
        const req = new HttpRequest(REQUEST_TYPE_POST, `MasterData/User/signUp/createUser`, registrationRequest);
        this._api.callApiService<ResponseDTO<any>>(req).subscribe(
            (response) => {
                if (response && response.isValid) {
                    this._notificationService.notifySuccess({ title: 'Registration Success', message: 'Please sign in with your username and password' });
                    this._router.navigate(['']);
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'User Signup' });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'User Signup' });
            });
    }

    public inviteUserForRegistration(inviteUserRequest: InviteUserRequest): void {
        const req = new HttpRequest(REQUEST_TYPE_POST, `MasterData/User/signUp/generate`, inviteUserRequest);
        this._api.callApiService<ResponseDTO<any>>(req).subscribe(
            (response) => {
                if (response && response.isValid) {
                    this._notificationService.notifySuccess({ title: 'User Registration Invite', message: 'Your request was successful' });
                } else {
                    this._notificationService.showNotificationsFromResponseDtoMessages({ response, title: 'User Registration Invite' });
                }
            },
            (err: HttpErrorResponse) => {
                this._notificationService.showNotificationsFromResponseDtoMessages({ response: err, title: 'User Registration Invite' });
            });
    }
}
