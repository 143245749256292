import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DragulaService} from 'ng2-dragula';

import * as Constants from '../../constants/constants'
import * as DashboardConstants from '../../constants/dashboard.constants';
import {DashboardHelper} from '../../helpers/dashboardHelper';
import {Breadcrumb} from '../../models/breadCrumb';
import {Dashboard} from '../../models/dashboard';
import {DashboardView} from '../../models/dashboardView';
import {Shipment} from '../../models/shipment';
import {BreadcrumbService} from "../../services/breadcrumb.service";
import { SignalsService } from 'app/services/signals.service';
import {toObservable} from "@angular/core/rxjs-interop";
import {NotificationService} from "../../services/notification.service";
import {DashboardService} from "../../services/dashboard.service";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: [
        './dashboard.component.scss',
        '../../../assets/css/styles-dragula.scss'
    ],
    standalone: false
})
export class DashboardComponent implements OnInit, OnDestroy {
    private searchResultViewContentSubscription: any;
    private dashboardSubscription: any;
    private appStateSubscription: any;

    public dashboardViewContent: Array<any>;
    public showMenu: boolean = false;
    public titleEditable: boolean = false;
    public dashboardName: string;
    private dashboardId;
    public showModal: boolean = false;
    private createdDashboardName: string;
    public activeDashboard: Dashboard;
    public activeDashboardView: DashboardView;
    public dashboards: Array<Dashboard>;
    public showOkCancelModal: boolean = false;
    public dashboardDeleteTitle: string = 'Delete Dashboard';
    public dashboardDeleteMessage: string = 'Do you want to delete your Dashboard?';
    public currentDashboardName: string;
    private createViewIndex: number = -1;
    public createViewViewOrder: number = Constants.DashboardView_CREATE_VIEW_VIEW_ORDER;
    public globalSearchViewOrder: number = Constants.DashboardView_GLOBAL_SEARCH_VIEW_ORDER;
    public dashboardViewTypes: {
        global_search: string;
        create_view: string;
        template_query: string;
        content_query: string;
        help_center: string;
    } = DashboardConstants.DASHBOARD_VIEW_TYPES;
    public maxDashboardNameCharacterLength: number = Constants.MAXIMUM_CHARACTERS_DASHBOARD_NAME;

    public dashboardViewLibrary: Array<DashboardView>;

    private appState$;
    private dashboards$;
    private dashboardViewContent$;

    constructor(
        private _router: Router,
        private _signalsService: SignalsService,
        private _breadcrumbService: BreadcrumbService,
        private _dashboardService: DashboardService,
        private _notificationService: NotificationService,
        private _dashboardHelper: DashboardHelper,
        private _dragula: DragulaService,
    ) {
        this.appState$ = toObservable(this._signalsService.appStateSignal);
        this.dashboards$ = toObservable(this._signalsService.dashboardsSignal);
        this.dashboardViewContent$ = toObservable(this._signalsService.dashboardViewContentSignal);
    }

    ngOnInit() {
        this.dashboardSubscription = this.dashboards$.subscribe((dashboards: Array<Dashboard>) => {
            if (dashboards) {
                this.dashboards = dashboards;
                this.titleEditable = false;
                this.activeDashboardView = this._dashboardHelper.getActiveDashboardView();
            }
        });

        this.appStateSubscription = this.appState$.subscribe((appState) => {
            this.showModal = appState['modal.isCreateDashboardModalShown'];
            this.showMenu = appState['menu.isDashboardMenuShown'];

            if (appState['dashboard.activeDashboardId'] && this.dashboards && this.dashboards.length) {
                this.activeDashboard = this._dashboardHelper.getActiveDashboard();
                if (this.activeDashboard) {
                    this.dashboardId = this.activeDashboard?.id;
                    this.currentDashboardName = this.activeDashboard?.name;
                }
            }
            if (appState['dashboard.activeDashboardViewId'] &&
                (
                    !this.activeDashboardView ||
                    appState['dashboard.activeDashboardViewId'] !== this.activeDashboardView?.id
                ) && this.dashboards &&
                this.dashboards.length
            ) {
                this.activeDashboardView = this._dashboardHelper.getActiveDashboardView();
                this._dashboardService.getDashboardViewContent();
            }
        });

        this.searchResultViewContentSubscription = this.dashboardViewContent$.subscribe((vC: Array<Shipment>) => {
            this.dashboardViewContent = vC;

            if (this.activeDashboard && this.activeDashboard.views.length >= Constants.MAXIMUM_ALLOWABLE_DASHBOARD_VIEWS) {
                this.createViewIndex = this.activeDashboard.views.findIndex((dbV: DashboardView) => dbV.viewType === Constants.DashboardViewType_CREATE_VIEW);
                const createViewNode = document.getElementById(`createViewNode`);

                if (this.createViewIndex !== -1 && createViewNode) {
                    createViewNode.style.display = 'none';
                }
            } else {
                this.createViewIndex = -1;
            }
        });

        this._dragula.createGroup('bag-views', {
            revertOnSpill: true,
            direction: 'horizontal'
        });

        this._dragula.drop('bag-views').subscribe(views => {
            const viewArray = [];
            if (views.name !== 'bag-views') {
                return;
            }
            viewArray.push(views.el);
            viewArray.push(views.target);
            viewArray.push(views.source);
            viewArray.push(views.sibling);
            this.reorderDashboardViews(viewArray);
        });

        this._dashboardService.getAllLibraryViews();

    }

    private reorderDashboardViews(args) {
        const [draggedViewNode, viewsContainerNode] = args;
        const dashboardViewReorderingReq = [];
        let viewOrder = 1;
        for (let index = 0; index < viewsContainerNode.childNodes.length; index++) {
            const iconSectionNode = viewsContainerNode.childNodes[index];
            if (iconSectionNode.nodeType === Constants.NODE_TYPES.ELEMENT_NODE && iconSectionNode.children.length) {
                const viewId = viewsContainerNode.childNodes[index].getAttribute('viewid');
                dashboardViewReorderingReq.push({viewId, viewOrder});
                viewOrder++;
            }
        }
        this._dashboardService.updateDashboardViewOrder(dashboardViewReorderingReq);
    }

    ngOnDestroy() {
        this.dashboardSubscription.unsubscribe();
        this.appStateSubscription.unsubscribe();
        this.searchResultViewContentSubscription.unsubscribe();
        this._dragula.destroy('bag-views');
    }

    public changeActiveDashboard(selectedDashboardId: number) {
        const selectedDashboard = this.dashboards.find(dashboard => dashboard.id === selectedDashboardId);
        this._dashboardService.switchDashboard(selectedDashboard);
        this.hideDashboardMenu();
    }

    public changeActiveDashboardView(view: DashboardView) {
        if (this.activeDashboardView && view.id === this.activeDashboardView.id) {
            return;
        }

        this._dashboardService.switchDashboardView(view);
    }

    public showDashboardMenu(): void {
        this._signalsService.updateAppState({'menu.isDashboardMenuShown': true});
    }

    public hideDashboardMenu(): void {
        this._signalsService.updateAppState({'menu.isDashboardMenuShown': false});
    }

    public renameDashboard(): void {
        this.titleEditable = true;
        this.showMenu = false;
    }

    public updateDashboardName(dashboardName: string): void {
        if (!dashboardName || !dashboardName.length) {
            this.titleEditable = true;
            this._notificationService.notifyError({
                title: `Missing Dashboard Name`,
                message: `Please enter a dashboard name before submitting`
            });
            return;
        } else if (dashboardName.length > this.maxDashboardNameCharacterLength) {
            this.titleEditable = true;
            this._notificationService.notifyError({
                title: `Update Dashboard Name Error`,
                message: `Your dashboard name '${dashboardName.length}' has more than ${this.maxDashboardNameCharacterLength} characters. Please shorten and try again.`
            });
            return;
        }

        const defaultDashboard = this.activeDashboard.defaultDashboard;
        this._dashboardService.updateDashboardName(dashboardName, this.dashboardId, defaultDashboard, this.activeDashboardView.id);
    }

    public exitEditDashboardName(): void {
        if (!this.activeDashboard || !this.activeDashboard.name || !this.activeDashboard.name.length) {
            this.titleEditable = true;
            this._notificationService.notifyError({
                title: `Missing Dashboard Name`,
                message: `Please enter a dashboard name before submitting`
            });
            return;
        }
        if (this.activeDashboard.name.length > this.maxDashboardNameCharacterLength) {
            this.titleEditable = true;
            this._notificationService.notifyError({
                title: `Update Dashboard Name Error`,
                message: `Your dashboard name '${this.activeDashboard.name.length}' has more than ${this.maxDashboardNameCharacterLength} characters. Please shorten and try again.`
            });
            return;
        } else if (this.currentDashboardName !== this.activeDashboard.name) {
            this.updateDashboardName(this.activeDashboard.name);
        }

        this.titleEditable = false;
    }

    public showCreateDashboardModal(): void {
        this._signalsService.updateAppState({'modal.isCreateDashboardModalShown': true});
    }

    public hideCreateDashboardModal(): void {
        this._signalsService.updateAppState({'modal.isCreateDashboardModalShown': false});
    }

    public receiveCreatedDashboardName(createdDashboardName: string): void {
        this.createdDashboardName = createdDashboardName;
        this.hideCreateDashboardModal();
        this._dashboardService.createDashboard(this.createdDashboardName);
    }

    public navigateToSection(view: DashboardView): void {
        switch (view.viewType) {

            case DashboardConstants.DASHBOARD_VIEW_TYPES.global_search:
                const breadcrumbs: Array<Breadcrumb> = this._breadcrumbService.breadcrumbsForSearch()
                this._breadcrumbService.addBreadcrumb(breadcrumbs);
                this._router.navigate(['search']);
                break;

            case DashboardConstants.DASHBOARD_VIEW_TYPES.create_view:
                this._router.navigate(['createView']);
                break;

            default:
                this.changeActiveDashboardView(view);
        }
    }

    public showEditView(id: number): void {
        this._signalsService.updateAppState({'dashboard.editDashboardView': true, 'dashboard.editDashboardViewId': id});
        this._router.navigate(['createView']);
    }

    public showConfirmDeleteModal(): void {
        this.showOkCancelModal = true;
    }

    public hideConfirmDeleteModal(): void {
        this.showOkCancelModal = false;
        this.hideDashboardMenu();
    }

    public confirmDeleteDashboard(): void {
        if (this.dashboards.length === 1) {
            this._notificationService.notifyError({
                title: `Delete Dashboard`,
                message: `This is your last dashboard, you can't delete it!`
            });
        } else if (this.dashboards.length > 1) {
            this._dashboardService.deleteDashboard(this.dashboards, this.activeDashboard.id);
            this.hideConfirmDeleteModal();
        }
    }

    public setDashboardAsDefault(): void {
        this._dashboardService.setDefaultDashboard(this.activeDashboard.id, 1, this.activeDashboard.name, this.activeDashboardView.id);
    }

    public deleteView(event: Event, dashboardView: DashboardView) {
        event.stopPropagation();
        this._dashboardService.deleteDashboardView(dashboardView, this.activeDashboard.id);
    }
}
