@if (quickQuote || insuranceCharge) {
    <div class="mainBorder">
        <div class="qqShipmentSummaryTitle">{{(quickQuote) ? 'ShipmentDetails:' : 'Insurance:'}}</div>
        <div class="qqShipmentSummaryData">
            @if (quickQuote) {
                Ship Date: {{shipDate}}<br />
                Origin: {{origin}}<br />
                Destination: {{destination}}<br />
                <span *ngIf="distance">Distance: {{distance}}<br /></span>
                Total Weight: {{weight}}<br />
                <span *ngIf="class">Class: {{class}}<br /></span>
                <span *ngIf="densityClass"> Density Class: {{densityClass}}<br /></span>
                <span *ngIf="cubicValue">{{cubicLabel}}: {{cubicValue}}<br /></span>
                <span *ngIf="pallets">Pallets: {{pallets}}<br /></span>
                <span *ngIf="dimensions">Dimensions: {{dimensions}}<br /></span>
                <span *ngIf="accessorials">Accessorials: {{accessorials}}<br /></span>
            }
            @if (insuranceCharge) {
                <span>Insurance Charge: {{insuranceCharge | currency}}<br /></span>
            }
        </div>
    </div>
}
