// **RTG** This component is not really used in the app, and does not work with the API either as is
//         The decision seems to have been made earlier on that global search does what this would have
//         done, so the only way into it was via the routing which I will remove.  Best to consider
//         removing when a larger refactoring of the reports is done, but testing will be needed to
//         be sure there is no dependency I am unaware of here.

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as DateConstants from '../../../../constants/datetime.constants';
import { DateTimeHelper } from '../../../../helpers/datetime.helper';
import { ShipmentDetailReportRequest } from '../../../../models/shipmentDetailReportRequest';
import { ShipmentReportDetail } from '../../../../models/shipmentReportDetail';
import {toObservable} from "@angular/core/rxjs-interop";
import {SignalsService} from "../../../../services/signals.service";
import {ReportingService} from "../../../../services/reporting.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
    selector: 'app-reports-shipment-detail',
    styleUrls: ['./reports-shipment-detail.component.scss'],
    templateUrl: './reports-shipment-detail.component.html',
    standalone: false
})
export class ReportsShipmentDetailComponent implements OnInit, OnDestroy {
    private reportingShipmentDetailSubscription: any;
    private routeParamsSubscription: any;

    public shipmentReportDetailList: Array<ShipmentReportDetail>;
    public shipmentDetailApiDateFormat: string = DateConstants.DATE_FORMAT_yyyymmdd;

    private detailOption: string = '';
    private reportingState$;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _notificationService: NotificationService,
        private _dateTimeHelper: DateTimeHelper,
        private _reportingService: ReportingService,
        private _signalsService: SignalsService,
    ) {
        this.reportingState$ = toObservable(this._signalsService.reportingSignal);
    }

    ngOnInit() {
        this.reportingShipmentDetailSubscription = this.reportingState$.subscribe((reporting) => {
            this.shipmentReportDetailList = reporting && reporting.shipmentDetailReports ? reporting.shipmentDetailReports.Report : [];
        });

        this.routeParamsSubscription = this._activatedRoute.params.subscribe((route: Params) => {
            if (route['shipmentId']) {
                this.retrieveShipmentIDData(route['shipmentId']);
            }
        });
    }

    ngOnDestroy() {
        this.reportingShipmentDetailSubscription.unsubscribe();
        this.routeParamsSubscription.unsubscribe();
    }

    public displayInProgressNotification(): void {
        this._notificationService.displayInProgressNotification({ title: `Shipment Reporting`, message: `Functionality is still in progress` });
    }

    public navigateToShipmentSummary(): void {
        this._router.navigate(['reports/shipments']);
    }

    public retrieveShipmentIDData(shipmentId: string): void {
        const currentDate = this._dateTimeHelper.getPreparedCurrentDate(this.shipmentDetailApiDateFormat);

        const shipmentDetailReportRequest: ShipmentDetailReportRequest = {
            startDate: '2018-06-01', // hard coded for now per Josh
            endDate: currentDate, // hard coded for now per Josh
            dateType: 'delivery', // hard coded for now per Josh
            detailType: 'carrier', // hard coded for now per Josh
            detailOption: shipmentId, // hard coded for now per Josh
        };

        this._reportingService.getShipmentDetailReport(shipmentDetailReportRequest);

    }

    public navigateToRecord(shipmentReportDetail: ShipmentReportDetail): void {
        if (!shipmentReportDetail || !shipmentReportDetail.Customer || !shipmentReportDetail.ShipmentID) {
            return;
        }

        const customer = shipmentReportDetail.Customer;
        const shipmentId = shipmentReportDetail.ShipmentID.substring(customer.length + 1);

        this._router.navigate(['record', customer, shipmentId]);
    }
}
