import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {UserHelper} from '../../helpers/userHelper';
import {Permissions} from '../../models/loginSettings';
import {CommonDataService} from '../../services/commonData.service';
import {CustomerService} from '../../services/customer.service';
import {MarkupsService} from '../../services/markups.service';
import {RoleService} from '../../services/role.service';
import {UserService} from '../../services/user.service';
import {BreadcrumbService} from "../../services/breadcrumb.service";

@Component({
    selector: 'app-admin',
    styleUrls: ['./admin.component.scss'],
    templateUrl: './admin.component.html',
    standalone: false
})
export class AdminComponent implements OnInit {
    constructor(
        private _router: Router,
        private _breadcrumbService: BreadcrumbService,
        private _userHelper: UserHelper,
        private _customerService: CustomerService,
        private _markupsService: MarkupsService,
        private _commonDataService: CommonDataService,
        private _userService: UserService,
        private _roleService: RoleService,
    ) { }

    public ngOnInit() {
        const userPermissions: Permissions = this._userHelper.getUserPermissions();
        if (userPermissions && userPermissions.users_profile_adminfields) {
            this._roleService.getRolesList().subscribe();
            this._commonDataService.getRateOptions().subscribe();
            this._commonDataService.getFuelOptions().subscribe();
            this._commonDataService.getAcclOptions().subscribe();
            this._customerService.getCustomerList().subscribe();
            this._markupsService.getMarkupList().subscribe();
            this._commonDataService.loadAllNameCodeOptions();
            this._commonDataService.getMarkupTypesAndRateTypes().subscribe();
            this._commonDataService.getMarkupRateOnOptions().subscribe();
            this._commonDataService.getMarkupRangeTypes().subscribe();
        }

        this._userService.getUsersList().subscribe();
    }

    public navigateToAdmin(): void {
        this._router.navigate(['admin']);
    }
}
