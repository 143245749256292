import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import * as _ from 'lodash-es';
import { MarkupRateTierOption } from '../../../models/markupRateTierOption';
import {CommonDataService} from '../../../services/commonData.service';

@Component({
    selector: 'app-rate-markup-search-modal',
    templateUrl: './rate-markup-search-modal.component.html',
    styleUrls: ['./rate-markup-search-modal.component.scss'],
    standalone: false
})

export class RateMarkupSearchModalComponent implements OnInit, OnDestroy {
    @Output() public closeRateMarkupSearchModal = new EventEmitter();
    @Output() public passRateMarkupOption = new EventEmitter();

    public markupRateTierOptions: Array<MarkupRateTierOption> = [];
    public filteredRateTierRateMarkupOptions: Array<MarkupRateTierOption> = [];
    public rateMarkupSearchForm: UntypedFormGroup;

    private rateMarkupFormValueChangesSubscription: any;

    constructor(
        private _fb: UntypedFormBuilder,
        private _commonDataService: CommonDataService,
    ) { }

    public async ngOnInit() {
        await this._commonDataService.loadedPromise(this._commonDataService.rateOptions);
        this.markupRateTierOptions = _.cloneDeep(this._commonDataService.rateOptions);
        this.filteredRateTierRateMarkupOptions = this.markupRateTierOptions;

        this.rateMarkupSearchForm = this._fb.group({
            searchTerm: ''
        });

        this.rateMarkupFormValueChangesSubscription = this.rateMarkupSearchForm.valueChanges.subscribe(values => {
            this.filterResults();
        });
    }

    public ngOnDestroy() {
        this.rateMarkupFormValueChangesSubscription.unsubscribe();
    }

    public filterResults(): void {
        const searchTerm = this.rateMarkupSearchForm.get('searchTerm').value;
        const searchPattern = new RegExp(searchTerm, 'i');

        this.filteredRateTierRateMarkupOptions = this.markupRateTierOptions.filter((result: MarkupRateTierOption) => {
            if (result.markupName.match(searchPattern) || result.markupDescription.match(searchPattern)) {
                return result;
            }
        });
    }

    public closeModal(): void {
        this.closeRateMarkupSearchModal.emit('');
    }

    public selectRateMarkupOption(rateMarkupOption: MarkupRateTierOption): void {
        this.passRateMarkupOption.emit(rateMarkupOption);
    }
}
