import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import * as Constants from '../../../constants/constants';
import * as SearchConstants from '../../../constants/searchCriteria';
import {UserHelper} from '../../../helpers/userHelper';
import {unsubscribe} from '../../../helpers/utilities';
import {CommodityProducts} from '../../../models/commodityProducts';
import {ContactSearchResult} from '../../../models/contact.searchResult';
import {CustomerMasterData} from '../../../models/customer.masterData';
import {NmfcClass} from '../../../models/nmfcClass';
import {RecordTabOption} from '../../../models/recordTabOption';
import {RoleMasterData} from '../../../models/role.masterData';
import {SetPassword} from '../../../models/setPassword';
import {UnitOfMeasure} from '../../../models/unitOfMeasure';
import {ZipSearchResult} from '../../../models/zip.searchResult';
import {CommonDataService} from '../../../services/commonData.service';
import {ContactService} from '../../../services/contact.service';
import {CustomerService} from '../../../services/customer.service';
import {PasswordService} from '../../../services/password.service';
import {RoleService} from '../../../services/role.service';
import {UserService} from '../../../services/user.service';
import {IntegrationsToken} from "../../../models/integrationsToken";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-admin-user-detail',
    styleUrls: ['./admin-user-detail.component.scss'],
    templateUrl: './admin-user-detail.component.html',
    standalone: false
})
export class AdminUserDetailComponent implements OnInit, OnDestroy {
    @Input() public isUserProfileAdminFieldVisible: number;
    @Input() public activeUserSection: string;
    @Input() public isCreditCardPaymentPermissible: boolean;
    @Input() public userForm$: Observable<UntypedFormGroup>;

    get isNewUser() {
        return !this.userForm.controls.userID.value;
    }

    get isCurrentUser() {
        return this.userForm.controls.userID.value === this._userHelper.getUserId();
    }

    public userForm: UntypedFormGroup;

    public isLoseChangesWarningModalVisible: boolean = false;
    public isSelectContactModalShown: boolean = false;
    public contactInput: string;
    public contactField: string;
    public contactType: string;
    public profile: string = Constants.USER_SECTIONS_profile;
    public defaults: string = Constants.USER_SECTIONS_defaults;
    public creditCard: string = Constants.USER_SECTIONS_creditCard;

    public defaultShipper: { name: string, address: string, city: string, state: string, zip: string };
    public defaultConsignee: { name: string, address: string, city: string, state: string, zip: string };

    public isAdminExternal: boolean;
    public isAdminInternal: boolean;

    public masterDataCustomersList: Array<CustomerMasterData> = [];
    public masterDataRolesList: Array<RoleMasterData> = [];
    public recordCreationTabOptions: Array<RecordTabOption> = [];
    public unitOfMeasures: Array<UnitOfMeasure> = [];
    public commodityProductOptions: Array<CommodityProducts> = [];
    public nmfcClasses: Array<NmfcClass>;

    public isZipSearchModalShown: boolean;
    public zipSearchModalType: string;
    public zipTypes = {
        origin: 'origin',
        destination: 'destination',
        creditCard: 'creditCard'
    };

    public isCustomerRolesModalShown: boolean = false;

    public searchConstants = SearchConstants;

    public showPasswordChange = false;

    public isCustomerRoleIdModalShown: boolean = false;
    public recordDisplayStyles: Array<string> = [Constants.RecordDisplayStyle_Accordion, Constants.RecordDisplayStyle_Wizard];
    public passwordRegex;
    public passwordRegexText;
    public lockedOut;
    public integrationsToken = '';
    public integrationsTokenDaysLeft = 0;
    public integrationsTokenIsExpiring = false;
    public isAuthorizedApiUser = false;

    get shipmentValue() {
        return this.userForm.get('shipmentValue');
    }

    get userlogin() {
        return this.userForm.get('userlogin');
    }

    get password() {
        return this.userForm.get('password');
    }

    get firstname() {
        return this.userForm.get('firstname');
    }

    get lastname() {
        return this.userForm.get('lastname');
    }

    get email() {
        return this.userForm.get('email');
    }

    private navToFunction: Function;
    private userFormReadySubscription: Subscription;
    private formControlSubscriptionMap: Map<string, Subscription> = new Map<string, Subscription>();

    private selectedContactFormControl: UntypedFormControl = null;

    constructor(
        private _notificationService: NotificationService,
        private _userHelper: UserHelper,
        private _commonDataService: CommonDataService,
        private _customerService: CustomerService,
        private _roleService: RoleService,
        private _contactService: ContactService,
        private _userService: UserService,
        private _router: Router,
        private _passwordService: PasswordService,
    ) {
    }

    public ngOnInit() {
        this.nmfcClasses = this._commonDataService.nmfcClasses;
        this.unitOfMeasures = this._commonDataService.unitOfMeasures;
        this.recordCreationTabOptions = this._commonDataService.recordTabOptions;
        this.masterDataCustomersList = this._customerService.customers;
        this.masterDataRolesList = this._roleService.roles;
        this.commodityProductOptions = this._commonDataService.commodityProducts;

        this.isAdminExternal = this._userHelper.isAdminExternal();
        this.isAdminInternal = this._userHelper.isAdminInternal();

        this._passwordService.getPasswordPolicy().pipe(
            switchMap(result => {
                this.passwordRegex = result.regex;
                this.passwordRegexText = result.Policy;
                return this.userForm$;
            }),
            map((form: UntypedFormGroup) => {
                this.userForm = form;

                this.isAuthorizedApiUser = this.isCurrentUser && this._userHelper.isAuthorizedApiUser();
                if (this._userHelper.integrationTokenExists()) {
                    this._passwordService.getIntegrationsToken().pipe(
                        map(res => {
                            this.integrationsToken = res.jwt;
                            this.integrationsTokenDaysLeft = res.daysLeft;
                            this.integrationsTokenIsExpiring = !!(res.isExpiring);
                        })
                    ).subscribe();
                }

                this.lockedOut = form.value.lockedOut;
                const role: RoleMasterData = this.getMatchFromList('roleID', this.masterDataRolesList, 'RoleID');
                const customer: CustomerMasterData = this.getMatchFromList('customer', this.masterDataCustomersList, 'custNo');

                if ((role?.roleCustNo !== customer?.custNo) && (this._router.url !== '/userprofile')) {
                    this._notificationService.notifyWarning({title: 'Customer-Role Discrepency', message: 'User\'s customer does not match role\'s customer.'});
                }

                this.subscribeToFormControlChange('defaultConsignee', () => this.getContactInfo('defaultConsignee'));
                this.subscribeToFormControlChange('defaultShipper', () => this.getContactInfo('defaultShipper'));
            })
        ).subscribe();
    }

    public ngOnDestroy() {
        unsubscribe(this.userFormReadySubscription);
        this.formControlSubscriptionMap.forEach((subscription) => {
            unsubscribe(subscription);
        })
    }

    public getNewToken() {
        this._passwordService.getNewToken().pipe(
            map((res: IntegrationsToken) =>  {
                this.integrationsToken = res.jwt;
                this.integrationsTokenDaysLeft = res.daysLeft;
                this.integrationsTokenIsExpiring = !!(res.isExpiring);
            })
        ).subscribe();
    }

    // ========== ZIP SEARCH MODAL METHODS ==================
    public showZipSearchModal(zipType: string): void {
        this.zipSearchModalType = zipType;
        this.isZipSearchModalShown = true;
    }

    public hideZipSearchModal(selectedZipResult: ZipSearchResult): void {
        if (selectedZipResult) {
            switch (this.zipSearchModalType) {
                case this.zipTypes.origin:
                    this.userForm.get('defaultOriginZip').setValue(selectedZipResult.zip_code);
                    break;
                case this.zipTypes.destination:
                    this.userForm.get('defaultDestZip').setValue(selectedZipResult.zip_code);
                    break;
                case this.zipTypes.creditCard:
                    this.userForm.get('ccBillingCity').setValue(selectedZipResult.city);
                    this.userForm.get('ccBillingSt').setValue(selectedZipResult.state);
                    this.userForm.get('ccBillingZip').setValue(selectedZipResult.zip_code);
                    break;
            }
        }
        this.zipSearchModalType = '';
        this.isZipSearchModalShown = false;
    }

    // ============= CONTACT SEARCH MODAL METHODS =============
    public showContactModal(formControlName, searchCriteria) {
        this.selectedContactFormControl = this.userForm.get(formControlName) as UntypedFormControl;
        this.contactType = searchCriteria;
        this.contactInput = this.userForm.get(formControlName).value;
        this.isSelectContactModalShown = true;
    }

    public hideContactModal(contactSearchResult: ContactSearchResult): void {
        this.isSelectContactModalShown = false;
        if (!contactSearchResult) {
            return;
        }

        if (this.selectedContactFormControl) {
            this.selectedContactFormControl.setValue(contactSearchResult.contactsid);
            this.selectedContactFormControl.markAsDirty();
        }
    }

    public getMatchFromList(idFormControlName, list, listIdName) {
        const id = this.userForm.get(idFormControlName).value;

        if (!id) {
            return;
        }
        return list.find((option: CustomerMasterData) => option[listIdName] === id);
    }

    public togglePasswordInput(): void {
        const passwordInputElement = document.getElementById('passwordInput');

        if (passwordInputElement.getAttribute('type') === 'text') {
            passwordInputElement.setAttribute('type', 'password');
        } else {
            passwordInputElement.setAttribute('type', 'text');
        }
    }

    public toggleTsaOnly(): void {
        document.getElementById('tsaCheckbox').focus(); // preserve focus on checkbox when toggling
        const currentTsaValue = this.userForm.get('defaultTSA').value;
        this.userForm.get('defaultTSA').setValue(!currentTsaValue);
    }

    public navAway($event, path, fieldName) {
        $event.stopPropagation();
        if (this._userService.formEqualsLastUser(this.userForm)) {
            this._router.navigate([path, this.userForm.get(fieldName).value]);
        } else {
            this.navToFunction = () => this._router.navigate([path, this.userForm.get(fieldName).value]);
            this.isLoseChangesWarningModalVisible = true;
        }
    }

    public doNavAway() {
        this.isLoseChangesWarningModalVisible = false;
        if (this.navToFunction) {
            this.navToFunction();
        }
    }

    public cancelNavAway() {
        this.isLoseChangesWarningModalVisible = false;
        this.navToFunction = undefined;
    }

    public setPassword($event: SetPassword) {
        this._passwordService.updatePassword(this.userForm.controls.userID.value, $event).pipe(
            map(response => {
                this._notificationService.notifySuccess({title: 'Set Password', message: response.Result});
                this.showPasswordChange = false;
            })
        ).subscribe();
    }

    public doLockoutReset(userId: string) {
        this._passwordService.resetLockout(userId).pipe(
            map(message => {
                this._notificationService.notifySuccess({title:'Reset Lockout', message});
                this.lockedOut = false;
            })
        ).subscribe()
    }

    public doClipboardCopy() {
        navigator.clipboard.writeText(this.integrationsToken);
        this._notificationService.notifySuccess({title: 'Integrations Token', message: 'Copied'});
    }

    private subscribeToFormControlChange(formControlName: string, callback: Function) {
        let subscription = this.formControlSubscriptionMap.get(formControlName);
        callback();
        if (subscription) {
            subscription.unsubscribe();
        }
        subscription = this.userForm.get(formControlName).valueChanges.subscribe(() => callback());
        this.formControlSubscriptionMap.set(formControlName, subscription);
    }

    private clearContactField(contactFieldName) {
        this.userForm.get(contactFieldName).setValue('');
        this.userForm.get(contactFieldName + 'Id').setValue('');
    }

    private getContactInfo(contactFieldName) {
        const id = this.userForm.get(contactFieldName).value;
        if (+id) {
            this._contactService.getContact(id).pipe(
                take(1),
                map((contact) => {
                    if (contact[0]) {
                        this[contactFieldName] = {
                            name: contact[0].Name,
                            address: contact[0].Address1,
                            city: contact[0].City,
                            state: contact[0].State,
                            zip: contact[0].Zip
                        }
                        this.userForm.get(contactFieldName + 'Id').setValue(id);
                    } else {
                        this.clearContactField(contactFieldName);
                    }
                })
            ).subscribe();
        } else if (id !== '') {
            this.clearContactField(contactFieldName);
        }
    }
}
