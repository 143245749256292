import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlightPipe',
    standalone: false
})
export class HighlightPipe implements PipeTransform {

    transform(text: string, filter: string): any {
        if (!text) {
            return '';
        }
        text = text.toString();
        if (filter) {
            text = text.replace(
                new RegExp('(' + filter + ')', 'gi'),
                '<span class="highlighted">$1</span>'
            );
        }
        return text;
    }
}
