import {Component, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Insurance} from '../../models/insurance';
import {InsuranceService} from '../../services/insurance.service';
import {ShipmentService} from "../../services/shipment.service";

@Component({
    selector: 'app-insurance',
    templateUrl: './app-insurance.component.html',
    styleUrls: ['./app-insurance.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppInsuranceComponent,
            multi: true
        },
    ],
    standalone: false
})
export class AppInsuranceComponent implements ControlValueAccessor {
    @Input()
    public carrier;

    public value;
    public isInsuranceModalShown = false;

    constructor(private _insuranceService: InsuranceService,
                private _shipmentService: ShipmentService) {
    }

    public valueChanged = (__: any) => {};

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public writeValue(value): void {
        if (value) {
            this.value = value;
        }
    }

    public removeInsurance() {
        this.value = undefined;
        this.valueChanged(this.value);
    }

    public addInsurance() {
        this.isInsuranceModalShown = true;
    }

    public async closeInsuranceQuotingModal(insInfo) {
        this.isInsuranceModalShown = false;

        if (insInfo) {
            const insurance: Insurance = {
                insuranceCharge: await this._insuranceService.getInsuranceCharge(insInfo.declaredShipmentValue).toPromise(),
                shipmentValue: insInfo.declaredShipmentValue
            };
            this.value = insurance;
            this.valueChanged(this.value);
        }
    }

    public getShipmentValueForModal() {
        if (this.value?.shipmentValue) {
            return this.value?.shipmentValue
        } else {
            return this._shipmentService.defaultShipmentValue;
        }
    }
}
