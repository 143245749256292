import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuotedRate} from '../../models/quotedRate';
import {BookingOption} from '../../models/unifiedShipmentObject';

@Component({
    selector: 'app-carrier-option-icon',
    styleUrls: ['./app-carrier-option-icon.component.scss'],
    templateUrl: './app-carrier-option-icon.component.html',
    standalone: false
})
export class AppCarrierOptionIconComponent implements OnInit {
    @Input()
    public carrierOption: BookingOption;
    @Input()
    public rate: QuotedRate;
    @Input()
    public isCarrierKPIShown = true;
    @Input()
    public imgStyling = {};
    @Input()
    public textStyling = {};
    @Input()
    public infoTooltip = '';
    @Input()
    public showInfoIcon = false;
    @Output()
    public imageClick: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public infoClick: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    public ngOnInit(): void {
    }

    public doImageClick() {
        this.imageClick.emit();
    }

    public doInfoClick() {
        this.infoClick.emit();
    }

    public carrierLogo() {
        if (this.carrierOption) {
            return this.carrierOption.LSP.logoURL;
        } else if (this.rate) {
            return this.rate.carrierLogoURL;
        }
    }

    public carrierName() {
        if (this.carrierOption) {
            return this.carrierOption.LSP.name;
        } else if (this.rate) {
            return this.rate.name;
        }
    }

    public starRanking() {
        if (this.carrierOption) {
            return this.carrierOption.LSP.starRanking;
        } else if (this.rate) {
            return this.rate.carrierStarRank;
        }
    }

    public tsa() {
        if (this.carrierOption) {
            return this.carrierOption.LSP.TSA;
        } else if (this.rate) {
            return this.rate.tsa;
        }
    }
}
