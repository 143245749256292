import {AfterViewInit, Component, Injector, Input} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-list',
    styleUrls: ['./app-list.component.scss'],
    templateUrl: './app-list.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppListComponent,
            multi: true
        }
    ],
    standalone: false
})
export class AppListComponent implements ControlValueAccessor, AfterViewInit {
    @Input()
    public emptyOption;
    @Input()
    public list = [];
    @Input()
    public tabindex = 0;
    @Input()
    public valueField;
    @Input()
    public isDisabled = false;

    public selectValue;
    public value;

    private control: UntypedFormControl;

    constructor(private injector: Injector) { }

    @Input()
    public getKey = (_: any) => {
        if (!_) {
            return _;
        }
        if (_.id !== undefined) {
            return _.id;
        } else if (_.name !== undefined) {
            return _.name;
        } else if (_.label !== undefined) {
            return _.label;
        } else if (_.description !== undefined) {
            return _.description;
        } else {
            return _;
        }
    }

    @Input()
    public getLabel = (_: any) => {
        if (!_) {
            return _;
        }
        if (_.label !== undefined) {
            return _.label;
        } else if (_.name !== undefined) {
            return _.name;
        } else if (_.description !== undefined) {
            return _.description;
        } else {
            return _;
        }
    };

    public ngAfterViewInit(): void {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this.control = ngControl.control as UntypedFormControl;
        } else {
            console.log('List Component is missing form control binding.');
        }
    }

    public writeValue(value) {
        if (value !== null) {
            this.value = value;
            if (this.valueField === undefined) {
                this.selectValue = this.getKey(value);
            } else {
                this.selectValue = value;
            }
        }
        if (this.control) {
            this.control.markAsPristine();
        }
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public setValue(value) {
        let item;
        for (item of this.list) {
            if (this.getKey(item).toString() === value) {
                this.selectValue = value;
                if (this.valueField === undefined) {
                    this.value = item;
                } else {
                    this.value = item[this.valueField];
                }
                this.valueChanged(this.value);
            }
        }
    }

    private valueChanged = (_: any) => {};
}
