import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { ContactMasterData } from '../../../models/contact.masterData';
import {ContactsMasterDataService} from "../../../services/contacts.masterdata.service";
import {UserHelper} from "../../../helpers/userHelper";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'app-masterdata-contacts-list',
    styleUrls: ['./masterdata-contacts-list.component.scss'],
    templateUrl: './masterdata-contacts-list.component.html',
    standalone: false
})
export class MasterDataContactsListComponent implements OnInit, OnDestroy {
    public masterDataContactsList: Array<ContactMasterData>;
    public filteredContactsList: Array<ContactMasterData> = [];
    public contactListForm: UntypedFormGroup;
    public selectedFilterField: string;
    public dropdownFilterFields: Array<{ displayText: string, filterField: string }> = [
        { displayText: 'All', filterField: 'all' },
        { displayText: 'Address', filterField: 'Address1' },
        { displayText: 'City', filterField: 'City' },
        { displayText: 'State', filterField: 'State' },
        { displayText: 'Zip', filterField: 'Zip' },
    ];

    public isConfirmDeleteModalVisible: boolean = false;
    public confirmDeleteContactTitle: string = 'Confirm Contact Deletion';
    public confirmDeleteContactMessage: string = 'Are you certain you\'d like to delete this contact?';
    public selectedContact: ContactMasterData;

    private contactsListValueChangesSubscription: any;

    constructor(
        private _contactsMasterDataService: ContactsMasterDataService,
        private _notificationService: NotificationService,
        private _fb: UntypedFormBuilder,
        private _router: Router,
        private _userHelper: UserHelper,
    ) { }

    public ngOnInit() {
        this.masterDataContactsList = this._contactsMasterDataService.contactsMasterData;
        this.filteredContactsList = this._contactsMasterDataService.contactsMasterData;

        this.contactListForm = this._fb.group({
            filterTerm: ''
        });

        this.contactsListValueChangesSubscription = this.contactListForm.valueChanges.subscribe(val => {
            this.filterContacts();
        });

        this.selectedFilterField = this.dropdownFilterFields[0].filterField;
    }

    public ngOnDestroy() {
        this.contactsListValueChangesSubscription.unsubscribe();
    }

    public filterContacts(): void {
        const filteredResults = [];
        const filterTerm = this.contactListForm.get('filterTerm').value;
        const searchPattern = new RegExp(filterTerm, 'i');

        this.filteredContactsList = this.masterDataContactsList.filter((mdContact: ContactMasterData) => {
            if (this.selectedFilterField === 'all') {
                if (mdContact.Name.match(searchPattern) || mdContact.Address1.match(searchPattern) || mdContact.City.match(searchPattern) || mdContact.State.match(searchPattern) || mdContact.Zip.match(searchPattern)) {
                    return mdContact;
                }
            } else if (mdContact[this.selectedFilterField].toString().match(searchPattern)) {
                return mdContact;
            }
        });

    }
    public deleteContact(contact: ContactMasterData): void {
        this.hideConfirmDeleteContactModal();

        if (!contact || !contact.contactsId) {
            return;
        }

        this._contactsMasterDataService.deleteMasterDataContact(contact.contactsId, true).subscribe(() => {
            this._notificationService.notifySuccess({
                title: 'Master Data - Delete Contact',
                message: `Contact ${contact.contactsId} successfully deleted.`
            });
            this._contactsMasterDataService.getMasterDataContactsList(this._userHelper.getUserCustomer());
        });
    }

    public hideConfirmDeleteContactModal(): void {
        this.isConfirmDeleteModalVisible = false;
    }

    public showConfirmDeleteModal(event: Event, contact: ContactMasterData): void {
        event.stopPropagation();

        this.selectedContact = contact;
        this.isConfirmDeleteModalVisible = true;
    }

    public navigateToContactShowPage(contact: ContactMasterData): void {
        if (!contact || !contact.contactsId) {
            this._notificationService.notifyError({ title: 'Navigate Contact Error', message: 'Missing contact information' });
            return;
        }

        this._router.navigate(['masterData/contacts/', contact.contactsId]);
    }

    public navigateToCreateContactPage(): void {
        this._router.navigate(['masterData/contacts']);
    }

}
