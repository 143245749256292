import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ReplaySubject, Subscription} from 'rxjs';
import {map, take} from 'rxjs/operators';
import { unsubscribe } from '../../../helpers/utilities';

import * as Constants from '../../../constants/constants';
import { UserHelper } from '../../../helpers/userHelper';
import {Permissions} from '../../../models/loginSettings';
import { User } from '../../../models/user';
import {CustomerService} from '../../../services/customer.service';
import {RoleService} from '../../../services/role.service';
import {UserService} from '../../../services/user.service';
import {BreadcrumbService} from "../../../services/breadcrumb.service";
import {AdminButtonPanelService} from "../../../services/adminButtonPanel.service";
import {LoginService} from "../../../services/login.service";
import {SignalsService} from "../../../services/signals.service";

@Component({
    selector: 'app-admin-user',
    styleUrls: ['./admin-user.component.scss'],
    templateUrl: './admin-user.component.html',
    standalone: false
})
export class AdminUserComponent implements OnInit, OnDestroy {
    public userId;
    public userForm: UntypedFormGroup;

    public activeUserSection: string;
    public constants = Constants;
    public creditCard: string = Constants.USER_SECTIONS_creditCard;
    public isUserProfileAdminFieldVisible: number;
    public isCreditCardPaymentPermissible: boolean = false;

    public isAdminInternal: boolean;
    public userFormReadySubject: ReplaySubject<UntypedFormGroup> = new ReplaySubject<UntypedFormGroup>();

    private routeSubscription: Subscription;
    private userFormChangeSubscription: Subscription;

    constructor(
        private _userHelper: UserHelper,
        private _breadcrumbService: BreadcrumbService,
        private _customerService: CustomerService,
        private _route: ActivatedRoute,
        private _userService: UserService,
        private _roleService: RoleService,
        private _adminButtonPanelService: AdminButtonPanelService,
        private _signalsService: SignalsService,
    ) {}

    public ngOnInit() {
        this._customerService.getCustomerList().subscribe();
        this.isAdminInternal = this._userHelper.isAdminInternal();
        this.activeUserSection = Constants.USER_SECTIONS_profile;
        this.setupButtonPanel();

        this.routeSubscription = this._route.params.pipe(
            map((params) => {
                this.userId = params['userId'];
                this._userService.getUserByPath(this.userId).pipe(
                    take(1),
                    map(async (user: User) => {
                        this.userForm = this._userService.getUserFormFromLastUser();

                        if (!this.userId) {
                            const userPermissions: Permissions = this._userHelper.getUserPermissions();
                            if (userPermissions && userPermissions.users_profile_adminfields) {
                                this._roleService.getRolesList().subscribe();
                                this._customerService.getCustomerList().pipe(
                                    map(() => this.userFormReadySubject.next(this.userForm))
                                ).subscribe();
                            } else {
                                this.userFormReadySubject.next(this.userForm);
                            }
                        } else {
                            this.userFormReadySubject.next(this.userForm);
                        }

                        this.setBreadcrumb(this.userId);
                        if (this.userFormChangeSubscription) {
                            this.userFormChangeSubscription.unsubscribe();
                        }
                        this.activateButtons();
                        this.userFormChangeSubscription = this.userForm.valueChanges.subscribe(() => {
                            this.activateButtons();
                        })
                    })
                ).subscribe();
            })
        ).subscribe();

        this.isUserProfileAdminFieldVisible = this._signalsService.loginSettingsSignal().permissions[0].users_profile_adminfields;
        this.isCreditCardPaymentPermissible = this._signalsService.loginSettingsSignal().permissions[0].creditCard;
        if (this.isUserProfileAdminFieldVisible) {
            this._roleService.getRolesList().subscribe();
        }
    }

    public ngOnDestroy() {
        unsubscribe(this.routeSubscription);
        unsubscribe(this.userFormChangeSubscription);
        this._adminButtonPanelService.showSaveButton(false);
        this._adminButtonPanelService.showCancelButton(false);
        this.userFormReadySubject.complete();
    }

    public saveChanges(): void {
        const user: User = this._userService.getUserFromForm(this.userForm);

        if (this.isNewUser()) {
            this._userService.createUser(user).pipe(
                map(() => {
                    this._breadcrumbService.popBreadcrumb();
                    this._userService.getUsersList().subscribe();
                })
            ).subscribe();
        } else {
            this._userService.updateUser(user).pipe(
                take(1),
                map((updatedUser: User) => {
                    this._userService.getUsersList().subscribe();
                    this._userService.updateLastUser(updatedUser);
                    this._userService.updateForm(this.userForm);
                    if (!this.userId) {
                        this._signalsService.userSignal.set(updatedUser);
                    }
                })
            ).subscribe();
        }
    }

    public isNewUser() {
        return this.userId === 'new';
    }

    public switchUserTabs(selectedTab: string): void {
        this.activeUserSection = selectedTab;
    }
    private activateButtons() {
        if (this.userForm.controls.userID.value && this._userService.formEqualsLastUser(this.userForm)) {
            this.userForm.markAsPristine();
        } else {
            this.userForm.markAsDirty();
        }

        this._adminButtonPanelService.activateSaveButton(this.userForm.dirty && this.userForm.valid);
    }

    private lastUserToForm() {
        this._userService.updateForm(this.userForm);
        this.userForm.markAsPristine();
    }

    private setupButtonPanel() {
        this._adminButtonPanelService.showSaveCancelButtons(true);
        this._adminButtonPanelService.activateSaveButton(false);

        this._adminButtonPanelService.register({
            saveEvent: () => {
                this.saveChanges();
            },
            cancelEvent: () => {
                this.lastUserToForm();
            }
        });
    }

    private setBreadcrumb(id) {
        let breadcrumbLabel;
        const breadcrumbUrl = (id) ? 'admin/users/' + id : 'userprofile';

        switch (id) {
            case 'new':
                breadcrumbLabel = 'Create User';
                break;
            default:
                breadcrumbLabel = this.userForm.controls.name.value;
        }

        this._breadcrumbService.addOrUpdateBreadcrumb({label: breadcrumbLabel, urlValue: breadcrumbUrl});
    }
}
