import {
    Component,
    OnInit,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PurchaseOrder} from '../../models/purchaseOrder';

@Component({
    selector: 'app-purchase-order-line',
    styleUrls: ['./app-purchase-order-line.component.scss'],
    templateUrl: './app-purchase-order-line.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppPurchaseOrderLineComponent,
            multi: true
        },
    ],
    standalone: false
})
export class AppPurchaseOrderLineComponent implements OnInit, ControlValueAccessor {
    public value = new PurchaseOrder();
    public yesNoList = [{id: 0, label: 'No'}, {id: 1, label: 'Yes'}];
    public pallet;

    constructor() {
    }

    public ngOnInit() {
    }

    public writeValue(value) {
        if (value) {
            this.value = value;
            this.pallet = this.value.Pallet
        }
    }

    public registerOnChange(fn: any): void {
        this.valueChanged = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public valueChanged = (__: any) => {};

    public palletChanged(value) {
        this.value.Pallet = value.id;
        this.pallet = value.id;
        this.valueChanged(this.value);
    }
}
