
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';

import * as SearchConstants from '../../../constants/searchCriteria';
import {unsubscribe} from '../../../helpers/utilities';
import { ContactSearchResult } from '../../../models/contact.searchResult';
import { SearchRequest } from '../../../models/searchRequest'
import {SearchService} from "../../../services/search.service";
import * as Constants from "../../../constants/constants";

@Component({
    selector: 'app-select-contact-modal',
    styleUrls: ['./select-contact-modal.component.scss'],
    templateUrl: './select-contact-modal.component.html',
    standalone: false
})
export class SelectContactModalComponent implements OnInit, OnDestroy {
    @Input() public contactType: string;
    @Input() public contactField: string;
    @Input() public contactInput: string;
    @Input() public modalTitle: string = 'Search Contact';

    @Output() public updateContactInfo = new EventEmitter<ContactSearchResult>();

    public contactResults: Array<ContactSearchResult> = [];
    public isNoResultsPromptDisplayed: boolean = false;
    public contactQuery: string = '';
    private contactSearchSubjectSubscription;

    public selectedContactField: string;
    public dropdownContactFields: Array<{ displayText: string, contactField: string }> = [
        { displayText: 'Name', contactField: SearchConstants.SEARCH_CRITERIA_type.NAME },
        { displayText: 'City', contactField: SearchConstants.SEARCH_CRITERIA_type.CITY },
        { displayText: 'Address', contactField: SearchConstants.SEARCH_CRITERIA_type.ADDRESS1 },
        { displayText: 'Zipcode', contactField: SearchConstants.SEARCH_CRITERIA_type.ZIP },
        { displayText: 'State', contactField: SearchConstants.SEARCH_CRITERIA_type.STATE }
    ];

    public contactSearchSubject: Subject<SearchRequest>;

    constructor(
        private _searchService: SearchService,
    ) { }
    public ngOnInit() {
        this.resetValues();
        this.setupContactSearchSubject();
        if (this.contactField) {
            const matchedContactFieldIndex = this.dropdownContactFields.findIndex(field => field.contactField === this.contactField);
            this.selectedContactField = (matchedContactFieldIndex !== -1 ? this.dropdownContactFields[matchedContactFieldIndex].contactField : this.dropdownContactFields[0].contactField);
        } else {
            this.selectedContactField = this.dropdownContactFields[0].contactField;
        }
        if (this.contactInput) {
            this.contactQuery = this.contactInput;
            this.searchContactsInitial();
        }
    }

    public ngOnDestroy() {
        this.resetValues();
        unsubscribe(this.contactSearchSubjectSubscription);
    }

    // ======================================================================================================
    // ================================= CONTACT SETUP METHODS =============================================
    // ======================================================================================================

    private resetValues() {
        this._searchService.clearRecordContacts();

        this.contactSearchSubject = null;
        this.contactResults = [];
    }

    public setupContactSearchSubject(): void {
        this.contactSearchSubject = new Subject<SearchRequest>();
    }

    // ======================================================================================================
    // ================================= CONTACT SEARCH METHODS =============================================
    // ======================================================================================================

    private searchContactsInitial(): void {
        if (!this.contactQuery) {
            return;
        }

        this.contactSearchSubject.next(this.prepareContactSearchRequest());

        const contactsSearchRequest = this.prepareContactSearchRequest();
        this._searchService.processContactSearch(contactsSearchRequest)
            .subscribe(response => {
                if (response.isValid && response.dto && response.dto.searchResults && response.dto.searchResults.length) {
                    this.contactResults = response.dto.searchResults[0].entities;
                } else {
                    this.contactResults = [];
                }
                this.isNoResultsPromptDisplayed = (this.contactResults.length ? false : true);
            });
    }

    public searchContactsOnChange(): void {
        if (!this.contactQuery) {
            return;
        }
        const contactsSearchRequest = this.prepareContactSearchRequest();
        this.contactSearchSubject.next(contactsSearchRequest);
        if (!this.contactSearchSubjectSubscription) {
            this.contactSearchSubjectSubscription = this.contactSearchSubject.pipe(
                debounceTime(600),
                distinctUntilChanged(),
                switchMap(searchRequest => this._searchService.processContactSearch(searchRequest))
            )
                .subscribe(response => {
                    if (response.isValid && response.dto && response.dto.searchResults && response.dto.searchResults.length) {
                        this.contactResults = response.dto.searchResults[0].entities;
                    } else {
                        this.contactResults = [];
                    }
                    this.isNoResultsPromptDisplayed = (this.contactResults.length ? false : true);
                });
        }
    }

    // ======================================================================================================
    // ================================= CONTACT UI METHODS     =============================================
    // ======================================================================================================

    public cancel() {
        this.closeModal();
    }

    public closeModal() {
        this.updateContactInfo.emit();
    }

    // ======================================================================================================
    // ================================= CONTACT SUBMISSION METHODS==========================================
    // ======================================================================================================

    private prepareContactSearchRequest(): SearchRequest {
        const contactsSearchRequest: SearchRequest = {
            searchCriteria: [
                {
                    type: this.selectedContactField,
                    value: this.contactQuery,
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_CONTACTS,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
                },
                {
                    type: this.contactType,
                    value: '1',
                    entityType: SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_CONTACTS,
                    boolQuery: SearchConstants.SEARCH_CRITERIA_boolQuery.MUST,
                    pattern: SearchConstants.SEARCH_CRITERIA_pattern.MATCH
                }
            ]
        };

        return contactsSearchRequest;
    }

    public selectContactInfo(contact: ContactSearchResult) {
        this.setDefaultContactCountryCode(contact);
        this.updateContactInfo.emit(contact);
    }

    // ======================================================================================================
    // ================================= CONTACT CHANGE METHODS    ==========================================
    // ======================================================================================================

    private setDefaultContactCountryCode(contact: ContactSearchResult): ContactSearchResult {
        return this.setDefaultContactSearchResultCountryCode(contact);
    }

    private setDefaultContactSearchResultCountryCode(contact: ContactSearchResult): ContactSearchResult {
        if (contact.country) {
            return contact;
        }

        contact.zip = contact.zip.toString();
        const zipString: string = contact.zip;
        if (zipString.match(Constants.VALID_AMERICAN_ZIP)) {
            contact.country = Constants.AMERICAN_COUNTRY_CODE;
        } else {
            contact.country = Constants.CANADIAN_COUNTRY_CODE;
        }

        return contact;
    }
}
