import { Component, Input, OnInit } from '@angular/core';

import { ShipmentNote } from '../../models/shipmentNote';
import {SignalsService} from "../../services/signals.service";
import {toObservable} from "@angular/core/rxjs-interop";

@Component({
    selector: 'app-shipment-notes',
    templateUrl: './shipment-notes.component.html',
    styleUrls: ['./shipment-notes.component.scss'],
    standalone: false
})
export class ShipmentNotesComponent implements OnInit {
    @Input()
    public userName: string;
    @Input()
    public shipmentNotes: Array<ShipmentNote>;
    @Input()
    public showIconWhenCollapsed = true;

    public collapsed: boolean;
    private appState;
    private appStateSubscription: any;
    public isClipboardShortCutsFloaterCollapsed: boolean;
    public isShipmentNotesPinned: boolean;
    private appState$;

    constructor(private _signalsService: SignalsService) {
        this.appState$ = toObservable(this._signalsService.appStateSignal);
    }

    ngOnInit() {
        this.appStateSubscription = this.appState$.subscribe((appState) => {
            this.appState = appState;
            this.collapsed = this.appState['floater.shipmentNotesCollapsed'];
            this.isClipboardShortCutsFloaterCollapsed = this.appState['floater.clipboardCollapsed'];
            this.isShipmentNotesPinned = this.appState['floater.shipmentNotesPinned'];
        });

    }

    public toggleShipmentNotes() {
        const isCollapsed = this.collapsed;
        this._signalsService.updateAppState({ 'floater.shipmentNotesCollapsed': !isCollapsed });

        if (!this.collapsed) {
            this._signalsService.updateAppState({ 'floater.clipboardCollapsed': true });
        }
    }

    public togglePinnedState(): void {
        if (!this.isShipmentNotesPinned) {
            this._signalsService.updateAppState({ 'floater.shipmentNotesPinned': true });
        } else {
            this._signalsService.updateAppState({ 'floater.shipmentNotesPinned': false });
        }
    }

}
