import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash-es';
import {EMPTY, of, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {UserHelper} from '../../helpers/userHelper';
import {unsubscribe} from '../../helpers/utilities';
import {LoginSettings} from '../../models/loginSettings';
import {QuotedRate} from '../../models/quotedRate';
import {BookingOption, USO} from '../../models/unifiedShipmentObject';
import {PATH_CHANGE, PATH_ENTRY, PATH_NEW, QuickQuoteService} from '../../services/quickQuote.service';
import {ShipmentService} from '../../services/shipment.service';
import {SignalsService} from "../../services/signals.service";
import {InsuranceService} from "../../services/insurance.service";

@Component({
    selector: 'app-quick-quote-display',
    styleUrls: ['./quick-quote-display.component.scss'],
    templateUrl: './quick-quote-display.component.html',
    standalone: false
})
export class QuickQuoteDisplayComponent implements OnInit, OnDestroy {
    public quickQuoteId;
    public carrierSelected: BookingOption;
    public bookingOptions: Array<BookingOption>;
    public disableSaveQuote = true;
    public quickQuote: USO;
    public quoteNumber = '';
    public quoteName = '';
    public isCarrierKPIShown;
    public isRatedClassShown;
    public PATH_CHANGE = PATH_CHANGE;
    public isOkCancelModalVisible = false;
    public isTerminalDetailsModalVisible = false;
    private routeSubscription: Subscription;
    public isInsuranceModalShown = false;
    private insuranceDirty = false;

    constructor(public _quickQuoteService: QuickQuoteService,
                private _signalsService: SignalsService,
                public _userHelper: UserHelper,
                public _shipmentService: ShipmentService,
                private _insuranceService: InsuranceService,
                private _router: Router,
                private _route: ActivatedRoute) {}

    public ngOnInit(): void {
        const userSettings: LoginSettings = this._signalsService.loginSettingsSignal();

        if (userSettings && userSettings.permissions && userSettings.permissions.length) {
            this.isCarrierKPIShown = userSettings.permissions[0].showCarrierKPI;
            this.isRatedClassShown = userSettings.permissions[0].ratedClass;
        }

        this.routeSubscription = this._route.params.pipe(
            switchMap((params) => {
                this.quickQuoteId = params['quickQuoteId'];
                if ((this.quickQuoteId === PATH_CHANGE) || ((this.quickQuoteId === PATH_ENTRY) &&
                    !this._quickQuoteService.getLastQuickQuote())) {
                    this._router.navigate(['quick-quote', PATH_NEW]);
                    return EMPTY;
                }
                return this._quickQuoteService.getQuickQuoteByPath(this.quickQuoteId);
            }),
            map((quickQuote) => {
                this.quickQuote = quickQuote as USO;
                if (this.quickQuoteId === PATH_CHANGE) {
                    this.quoteName = this._quickQuoteService.changeQuoteName;
                } else {
                    this.quoteName = this.quickQuote.name;
                }
                this.quoteNumber = this.quickQuote.id.toString();

                this.carrierSelected = undefined;
                if (this.quickQuote.bookingSelection) {
                    this.carrierSelected = _.find(this.quickQuote.bookingOptions, (option: BookingOption) => option.bookingStatus === 'selected');
                }
                this.checkDirty();
            }),
        ).subscribe();
    }

    public ngOnDestroy() {
        unsubscribe(this.routeSubscription);
    }

    public showTerminalDetails(bookingOptions: Array<BookingOption|QuotedRate>) {
        this.bookingOptions = bookingOptions as Array<BookingOption>;
        this.isTerminalDetailsModalVisible = true;
    }

    public bookQuote() {
        this._shipmentService.navigateViaQuote(this.quickQuote.id);
    }

    public saveQuote() {
        let doNavigate = false;
        const quickQuoteClone = _.cloneDeep(this.quickQuote);
        quickQuoteClone.name = this.quoteName;

        this._quickQuoteService.saveQuote(quickQuoteClone, this.carrierSelected.rateID  , (this.quickQuoteId === PATH_CHANGE) || this.quickQuote.bookingSelection).pipe(
            switchMap((qq: USO) => {
                if (qq.id.toString() === this.quickQuoteId) {
                    this.quickQuote = qq;
                    this._quickQuoteService.updateLastQuote(qq);
                    this.checkDirty();
                } else {
                    doNavigate = true;
                }

                if (this._quickQuoteService.lastShipmentValue) {
                    return this._insuranceService.createInsurance(null, this.quickQuote.id, this._quickQuoteService.lastInsuranceCharge, this._quickQuoteService.lastShipmentValue).pipe(
                        map(() => {
                            this.insuranceDirty = false;
                            this.checkDirty();
                        })
                    );
                } else {
                    return (of(0));
                }
            }),
            map(() => {
                if (doNavigate) {
                    this._router.navigate(['quick-quote-display', quickQuoteClone.id]);
                }
            })
        ).subscribe();
    }

    public clearQuote(showOkCancelModal = true) {
        if (showOkCancelModal) {
            this.isOkCancelModalVisible = true;
        } else {
            this.isOkCancelModalVisible = false;
            this._router.navigate(['quick-quote', PATH_NEW]);
        }
    }

    public printQuote() {
        window.print();
    }

    public emailQuote() {
    }

    public copyQuote() {
        const quickQuote = _.cloneDeep(this._quickQuoteService.getLastQuickQuote());
        delete quickQuote.id;
        delete quickQuote.bookingSelection;
        delete quickQuote.totalLinearDimensions;
        delete quickQuote.totalWeight;
        delete quickQuote.totalValue;
        delete quickQuote.totalVolume;
        delete quickQuote.totalHandlingUnits;
        quickQuote.name = this.quoteName;
        this._quickQuoteService.updateLastQuote(quickQuote);
        this._router.navigate(['quick-quote', PATH_ENTRY]);
    }

    public checkDirty() {
        setTimeout(() => {
            let showSaveButton;
            switch (this.quickQuoteId) {
                case PATH_CHANGE:
                    showSaveButton = true;
                    break;
                case PATH_ENTRY:
                    showSaveButton = !!this.carrierSelected;
                    break;
                default:
                    showSaveButton = (this.quoteName !== this.quickQuote.name) || this.insuranceDirty;
            }
            this.disableSaveQuote = !showSaveButton;
        }, 0);
    }

    public selected(bookingOption: BookingOption) {
        this.carrierSelected = bookingOption;
        this.checkDirty();
    }

    public changeCarrier() {
        this._quickQuoteService.updateLastQuote(this.quickQuote);
        this._quickQuoteService.changeQuoteName = this.quoteName;
        this._router.navigate(['quick-quote-display', PATH_CHANGE]);
    }

    public async closeInsuranceQuotingModal(insInfo) {
        this.isInsuranceModalShown = false;
        if (insInfo) {
            this._quickQuoteService.lastShipmentValue = insInfo.declaredShipmentValue;
            this._quickQuoteService.lastInsuranceCharge = insInfo.insuranceCharge;
            this.insuranceDirty = true;
            this.checkDirty();
        }
    }
}
