import { Pipe, PipeTransform } from '@angular/core';

import * as SearchConstants from '../constants/searchCriteria';
import { FilteredSearchResultFieldValue } from '../models/filteredSearchResultFieldValue';

@Pipe({
    name: 'searchCheckboxFilter',
    standalone: false
})
export class SearchCheckboxFilterPipe implements PipeTransform {
  transform(fieldValues: Array<FilteredSearchResultFieldValue>, filteredEntityType: string, showCheckboxes?: boolean): Array<FilteredSearchResultFieldValue> {
    if (!filteredEntityType || filteredEntityType !== SearchConstants.SEARCH_CRITERIA_entityType.MASTER_DATA_CONTACTS) {
      return fieldValues;
    }

    if (!showCheckboxes) {
      return fieldValues.filter((field: FilteredSearchResultFieldValue) => !this.isFieldAContactType(field));
    } else {
      return fieldValues.filter((field: FilteredSearchResultFieldValue) => this.isFieldAContactType(field));
    }
  }

  public isFieldAContactType(field: FilteredSearchResultFieldValue): boolean {
    const isContactBillToField: boolean = field.fieldName === SearchConstants.SEARCH_CRITERIA_type.MASTER_DATA_CONTACT_BILL_TO;
    const isContactShipperField: boolean = field.fieldName === SearchConstants.SEARCH_CRITERIA_type.MASTER_DATA_CONTACT_SHIPPER;
    const isContactConsigneeField: boolean = field.fieldName === SearchConstants.SEARCH_CRITERIA_type.MASTER_DATA_CONTACT_CONSIGNEE;
    const isContactThirdPartyField: boolean = field.fieldName === SearchConstants.SEARCH_CRITERIA_type.MASTER_DATA_CONTACT_THIRD_PARTY;

    return isContactBillToField || isContactShipperField || isContactConsigneeField || isContactThirdPartyField;
  }
}
